import * as React from "react";
import { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import {
  getComments as getCommentsApi,
  createComment as createCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
} from "../../../action/CommentAction";
 
const Comments = ({ selectedRowId, currentUserId, currentUserName }) => {
  console.log("Selected Row ID in Comments component:", selectedRowId);
  console.log("currentUserId in Comments component:", currentUserId);
 
  const [text, setText] = useState("");
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = backendComments
    ? backendComments.filter(
        (backendComment) => backendComment.priorusercommentuid === -1
      )
    : [];
 
  // testing prop for comment visibility
  console.log(
    "INSIDE COMMENT rowuidincontext of which comments need to be display",
    selectedRowId
  );
 
  const getReplies = (commentId) =>
    backendComments
      .filter(
        (backendComment) => backendComment.priorusercommentuid === commentId
      )
      .sort(
        (a, b) =>
          new Date(a.createddate).getTime() - new Date(b.createddate).getTime()
      );
 
  const addComment = (text, parentId, selectedRowId) => {
    if (!selectedRowId) {
      // If selectedRowId is null, show a failure message
      alert("Please select a row before adding a comment.");
      return; // Exit the function
    }
    createCommentApi(text, parentId, selectedRowId, currentUserId, currentUserName)
      .then((comment) => {
        // Call getCommentsApi to get the updated comments after adding a new comment
        getCommentsApi(selectedRowId).then((commentsData) => {
          console.log("Updated Comments data from API:", commentsData);
          setBackendComments(commentsData.data);
        });
 
        setActiveComment(null);
      })
      .catch((error) => {
        console.error("Error creating comment:", error);
      });
  };
 
  const updateComment = async (text, usercommentuid, selectedRowId) => {
    try {
      const response = await updateCommentApi(
        text,
        usercommentuid,
        selectedRowId,
        currentUserName
      );
      console.log("Update Comment API Response:", response);
 
      // Wait for the updateCommentApi call to finish before fetching updated comments
      const commentsData = await getCommentsApi(selectedRowId);
 
      console.log("Updated Comments data from API:", commentsData);
      setBackendComments(commentsData.data);
 
      setActiveComment(null);
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };
 
  const deleteComment = (commentId) => {
    if (window.confirm("Are you sure you want to remove the comment?")) {
      // Assuming you have the necessary data for the comment to be deleted
      const commentToDelete = backendComments.find(
        (backendComment) => backendComment.usercommentuid === commentId
      );
 
      if (commentToDelete) {
        deleteCommentApi(commentToDelete.usercommentuid, selectedRowId, currentUserName) // Pass usercommentuid and selectedRowId to deleteCommentApi
          .then(() => {
            // Call getCommentsApi to get the updated comments after deleting a comment
            getCommentsApi(selectedRowId).then((commentsData) => {
              console.log("Updated Comments data from API:", commentsData);
              setBackendComments(commentsData.data);
            });
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);
          });
      }
    }
  };
 
  useEffect(() => {
    if (selectedRowId) {
      getCommentsApi(selectedRowId)
        .then((commentsData) => {
          console.log("Comments data from API:", commentsData);
          setBackendComments(commentsData.data);
        })
        .catch((error) => {
          console.error("Error fetching comments:", error);
          setBackendComments([]); // Handle the error by setting an empty array
        });
    }
  }, [selectedRowId]);
 
 
  const infoMessageStyle = {
    backgroundColor: "#f0f8ff", // Light Blue
    padding: "20px",
    borderRadius: "8px",
    margin: "20px 0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };
 
  const paragraphStyle = {
    margin: "0",
    fontSize: "12px",
    color: "#333",
  };
 
  return (
    <div className="comments">
    <CommentForm
            submitLabel="Submit"
            handleCancel={() => {
              setActiveComment("");
              setText(""); // Clear the text area in the Comments component
            }}
            handleSubmit={(text, parentId) =>
              addComment(text, parentId, selectedRowId)
            }
          />
      {selectedRowId ? (
        <React.Fragment>
          <div className="comment-form-title">Write comment</div>
         
 
          <div className="comments-container">
            {rootComments.map((rootComment) => (
              <Comment
                comment={rootComment}
                key={rootComment.usercommentuid}
                replies={getReplies(rootComment.usercommentuid)}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                addComment={addComment}
                deleteComment={deleteComment}
                updateComment={(text, usercommentuid) =>
                  updateComment(text, usercommentuid, selectedRowId)
                }
                currentUserId={rootComment.usercommentuid}
                repliesGetter={getReplies}
                selectedRowId={selectedRowId}
              />
            ))}
          </div>
        </React.Fragment>
      ) : (
        <div style={infoMessageStyle}>
          {" "}
          <p style={paragraphStyle}>
            Please select a row in any of the panels' tables for which you would
            like to view comments and post new comments. Your selected row will
            be associated with the comments section in the right content area.
            Once you've chosen a row, you can click the 'Show Comment' button to
            reveal the comments section, and you'll be able to post new comments
            associated with the selected row. Thank you!
          </p>
        </div>
      )}
    </div>
  );
};
 
export default Comments;