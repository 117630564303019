
import React, { useRef, useEffect, useState } from "react";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import logo from "../../images/logo.png";
import Card from "@mui/material/Card";
import * as Validate from "./validation";
import { useNavigate } from "react-router-dom";
import { Button, Typography, TextField } from "@mui/material";
import "../App.css";
import * as CryptoJS from "crypto-js";
import { authenticateStdUser } from "../action/user";
import axios from "axios";
import { useSearchParams, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";

export default function CreateLink() {

  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState(" ");


  const CreateLn = () => {
    
            // temp data for link
        //    var datauserid = "arshi@ebrisque.com";
        //    var passdatalink = "raghu@123";
           var encryptEmail = CryptoJS.AES.encrypt(JSON.stringify(userid), "my-secret-key@rcube");
           var encryptPass = CryptoJS.AES.encrypt(JSON.stringify(password), "my-secret-key@rcube");
           const useriddata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptEmail));
           const passdata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptPass));
           const dboperation = "CREATEFIRST";
           const origpassword = undefined;

           
           console.log("useriddata" + useriddata);
           console.log("passdata" + passdata);
           console.log("dboperation : " + dboperation);
           console.log("origpassword : " + origpassword);

     

      authenticateStdUser(useriddata, passdata, dboperation, origpassword)
        .then((response) => {
          console.log(response.data);
          setLoginStatus(response.data);

        
        })
        .catch((error) => {
          console.log(error);
        });

        let urlResourse = "data="+useriddata+"&tempd="+passdata;
         let dynamicURL = "http://localhost:3000/welcome?" + urlResourse;
         console.log("dynamicURL " + dynamicURL)
  };

  return (
    <div>
    <div className="loginContainer">
      <img src={logo} style={{ height: "45px", width: "45px" }} />
    </div>
    <Card
      sx={{
        variant: "outlined",
        width: 250,
        padding: 5,
        alignItems: "center",
        marginLeft: "450px",
        marginTop: "80px",
        marginBottom: "-25px",
      }}
    >
      <Typography component="h2" variant="h5" color="primary" gutterBottom>
        CreateLink
      </Typography>
      <TextField
        id="login-email"
        label="UserId"
        margin="dense"
        type="email"
        fullWidth={true}
        value={userid}
        onChange={(e) => {
          setUserid(e.target.value);
        }}
        onkeypress={(event) => {
          if (event.key == "Enter") {
            this.login();
          }
        }}
      />
      <TextField
        // error={password_error.length > 0}
        id="login-password"
        label="Password"
        margin="dense"
        type="password"
        fullWidth={true}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        onkeypress={(event) => {
          if (event.key == "Enter") {
            this.login();
          }
        }}
      />
  
      <div className="formAction">
        <Button
          variant="contained"
          alignContent="center"
          color="primary"
          justifyContent="center"
          onClick={CreateLn}
        >
          Create Link
        </Button>
      </div>
      &nbsp;
      <Typography variant="caption" display="block" color="red" gutterBottom>
        {loginStatus}
      </Typography>

    
    </Card>
  </div>
  )
}

