import React, { useState } from "react";
import { Accordion, AccordionDetails, Typography, Button } from "@mui/material";
import Comments from "./Component/Comments";
import TodoList from "./Component/TodoList";
import Notes from "./Component/Notes";
import "../style.css";

export default function RightPanel({ selectedRowId, userUid, userName }) {
  const [expanded, setExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0); // Default to first tab
  const [CommentButton, setCommentButton] = useState("CommentButton");
  const [TodoButton, setTodoButton] = useState("TodoButton");
  const [NotesButton, setNotesButton] = useState("NotesButton");

  console.log(CommentButton);
  console.log(TodoButton);
  console.log(NotesButton);

  console.log("selectedRowIdNotesRP" + selectedRowId)

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleTabClick = (index) => {
    setSelectedTab(index);
    if (index === 0) {
      setTodoButton("TodoButton");
      setNotesButton("NotesButton");
      if (CommentButton !== "CommentButton") setCommentButton("CommentButton");
      else setCommentButton("CommentButton1");

      // if(CommentButton !== "CommentButton"){setCommentButton("CommentButton") }
      // else {setCommentButton("CommentButton1");}
    } else if (index === 1) {
      setCommentButton("TodoButton");
      setNotesButton("NotesButton");
      if (TodoButton !== "TodoButton") setTodoButton("TodoButton");
      else setTodoButton("TodoButton1");

      // setTodoButton("TodoButton1")
    } else if (index === 2) {
      setCommentButton("TodoButton");
      setTodoButton("CommentButton");
      if (NotesButton !== "NotesButton") setNotesButton("NotesButton");
      else setNotesButton("NotesButton1");

      // setNotesButton("NotesButton1")
    }
  };

  const tabs = [
    {
      label: "Comments",
      content: <Comments
      selectedRowId={selectedRowId}
      // currentUserId="1" />,
      currentUserId={userUid}
      currentUserName={userName}
       />,
    },
    {
      label: "ToDo",
      content: (
        <div className="todo-app">
          <TodoList
          sessionUserID={userUid}
          currentUserName={userName}
          />
        </div>
      ),
    },
    {
      label: "Notes",
      content: <Notes
      selectedRowIdNotes={selectedRowId}
      currentUserId={userUid}
      currentUserName={userName}
      />,
    },
  ];

  
  return (
    <div>
      <Accordion expanded={expanded} onChange={toggleAccordion}>
        <AccordionDetails>
          <div>
            
            <Button
              className={CommentButton}
              key={1}
              onClick={() => handleTabClick(0)}
              style={{
                padding: "10px 10px",
                borderRadius: "15px 10px 0px 0px",
                border: `2px solid ${
                  selectedTab === 0 ? "#4374aa" : "transparent"
                }`,
                backgroundColor: "#C1E1C1",
                color: selectedTab === 0 ? "#4374aa" : "#333",
                fontWeight: 400,
                right: "0px",
                width: "90px",
                // marginRight:"10px",
                transition: "all 0.3s",
                boxShadow:
                  selectedTab === 0 ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
              }}
            >
              {"Comments"}
            </Button>
            <Button
              className={TodoButton}
              key={2}
              onClick={() => handleTabClick(1)}
              style={{
                padding: "10px 10px",
                borderRadius: "10px 15px 0px 0px",
                border: `2px solid ${
                  selectedTab === 1 ? "#4374aa" : "transparent"
                }`,
                backgroundColor: "#A7C7E7",
                color: selectedTab === 1 ? "#4374aa" : "#333",
                fontWeight: 400,
                right: "30px",
                width: "90px",
                transition: "all 0.3s",
                boxShadow:
                  selectedTab === 1 ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
              }}
            >
              {"TODO"}
            </Button>
            <Button
              className={NotesButton}
              key={3}
              onClick={() => handleTabClick(2)}
              style={{
                padding: "10px 10px",
                borderRadius: "10px 15px 0px 0px",
                border: `2px solid ${
                  selectedTab === 2 ? "#4374aa" : "transparent"
                }`,
                backgroundColor: "#FFFAA0",
                color: selectedTab === 2 ? "#4374aa" : "#333",
                fontWeight: 400,
                right: "60px",
                width: "90px",
                transition: "all 0.3s",
                boxShadow:
                  selectedTab === 2 ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
              }}
            >
              {"Notes"}
            </Button>

           
          </div>

          {/* Display the selected tab content */}
          <div style={{ marginTop: "20px" }}>
            {tabs.map((tab, index) => (
              <div
                key={index}
                style={{ display: selectedTab === index ? "block" : "none" }}
              >
                <Typography variant="body2" gutterBottom>
                  {tab.content}
                </Typography>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
