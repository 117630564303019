import React, { useState } from "react";


const TodoForm = ({
  handleSubmit,
  submitLabel,
  handleCancel,
  initialText = "",
  initialTaskName = "",
  initialExpectedStartDate = "",
  initialExpectedEndDate = "",
}) => {
  const [text, setText] = useState(initialText);
  const [taskName, setTaskName] = useState(initialTaskName);
  const [expectedStartDate, setExpectedStartDate] = useState(
    initialExpectedStartDate
  );
  const [expectedEndDate, setExpectedEndDate] = useState(
    initialExpectedEndDate
  );

  const onSubmit = (event) => {
    event.preventDefault();

    handleSubmit(taskName, text, expectedStartDate, expectedEndDate);
    
    setText(""); // Clear the text area
    setTaskName("");
    setExpectedStartDate("");
    setExpectedEndDate("");
  };

  

  return (
    <form onSubmit={onSubmit} className="todo-form">
      <input
        placeholder="Task Name"
        value={taskName}
        onChange={(e) => setTaskName(e.target.value)}
        className="todo-input"
      />
      <textarea
        placeholder="Task description"
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="todo-description-form"
      />
      <div>
        <div className="comment-form-title">
          Proposed Start Date
        </div>
        <input
          type="date"
          value={expectedStartDate}
          onChange={(e) => setExpectedStartDate(e.target.value)}
          className="todo-date-input"
          min={new Date().toISOString().split("T")[0]} // Set minimum date to today
        />

        <div className="comment-form-title">
          Proposed End Date
        </div>
        <input
          type="date"
          value={expectedEndDate}
          onChange={(e) => setExpectedEndDate(e.target.value)}
          className="todo-date-input"
          min={new Date().toISOString().split("T")[0]} // Set minimum date to today
        />
      </div>
      <button
        type="submit"
        className="todo-button"
        disabled={taskName.length === 0}
      >
        {submitLabel}
      </button>
      <button
        type="button"
        className="todo-button"
        onClick={() => {
          handleCancel();
          setText(""); // Clear the text area
          setTaskName("");
          setExpectedStartDate("");
          setExpectedEndDate("");
        }}
      >
        Cancel
      </button>
    </form>
  );
}

export default TodoForm;

// // TodoForm.js
// import React, { useState, useEffect, useRef } from "react";

// function TodoForm(props) {
//   const [input, setInput] = useState(props.edit ? props.edit.value : "");
//   const [description, setDescription] = useState(
//     props.edit ? props.edit.description : ""
//   );
//   const [dueDate, setDueDate] = useState("");

//   const inputRef = useRef(null);

//   useEffect(() => {
//     inputRef.current.focus();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === "text") {
//       setInput(value);
//     } else if (name === "description") {
//       setDescription(value);
//     } else if (name === "dueDate") {
//       setDueDate(value);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     props.onSubmit({
//       id: Math.floor(Math.random() * 10000),
//       text: input,
//       description: description,
//       dueDate: dueDate,
//     });

//     setInput("");
//     setDescription("");
//     setDueDate("");
//   };

//   return (
//     <form onSubmit={handleSubmit} className="todo-form">
//       <input
//         placeholder="Add a task"
//         value={input}
//         onChange={handleChange}
//         name="text"
//         className="todo-input"
//         ref={inputRef}
//       />
//       <textarea
//         placeholder="Add a description"
//         value={description}
//         onChange={handleChange}
//         name="description"
//         className="todo-description"
//       />
//       <h1
//         style={{
//           marginBottom: "0px",
//           fontSize: "20px",
//         }}
//       >
//         Task fulfilment date
//       </h1>
//       <input
//         type="date"
//         value={dueDate}
//         onChange={handleChange}
//         name="dueDate"
//         className="todo-date-input"
//         min={new Date().toISOString().split("T")[0]} // Set minimum date to today
//       />
//       <button type="submit" className="todo-button">
//         Add Todo
//       </button>
//     </form>
//   );
// }

// export default TodoForm;
