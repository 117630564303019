import React, { useState } from 'react';
import './Table.css';
import newData from '../Shapes/new.json';
import nodeData from '../Shapes/nodeData.json';

function Table() {
  const [combinedData, setCombinedData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false); // State variable to track if data has been fetched

  // Function to fetch and combine data
  const fetchData = () => {
    const updatedNodeData = nodeData.map(node => {
      const newDataItem = newData.find(item => item.brisqueorgprocessdependencyuid === node.brisqueorgprocessdependencyuid);
      if (newDataItem) {
        return { ...node, ...newDataItem };
      }
      return node;
    });
    setCombinedData(updatedNodeData);
    setDataFetched(true); // Update state to indicate data has been fetched
  };

  return (
    <div>
      {/* Button to fetch data */}
      {!dataFetched && <button onClick={fetchData}>Fetch Data</button>}

      {/* Render table if data has been fetched */}
      {dataFetched && (
        <table className="table">
          <thead>
            <tr>
              <th>Steps</th>
              <th>Level</th>
              <th>Step/Label</th>
              <th>Type</th>
              <th>Current Step</th>
              <th>Next Step</th>
              <th>Connectors/comments</th>
              <th>Notes</th>
              <th>Action</th>
              <th>Action 2</th>
            </tr>
          </thead>
          <tbody>
            {combinedData.map((node, index) => (
              <tr key={node.brisqueorgprocessdependencyuid}>
                <td>{index + 1}</td>
                <td>{node.currentprocesslevel}</td>
                <td>{node.data && node.data.value ? node.data.value : ''}</td>
                <td>{node.currentprocessname}</td>
                <td>{node.currentbrisqueorgprocessuid}</td>
                <td>{node.nextbrisqueorgprocessuid}</td>
                <td>{node.connectorlabel}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
            {/* Fill remaining rows with empty cells */}
            {Array(10 - combinedData.length).fill().map((_, i) => (
              <tr key={i + combinedData.length}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Table;
