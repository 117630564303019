
import React, { useState, useEffect } from "react";
import NoteForm from "./NoteForm";
import Note from "./Note";
import {
  getNotes as getNotesApi,
  createNote as createNoteApi,
  updateNote as updateNoteApi,
  deleteNote as deleteNoteApi,
} from "../../../action/NoteAction";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
 
const Notes = ({ selectedRowIdNotes, currentUserId, currentUserName }) => {
  const [text, setText] = useState("");
  const [backendNotes, setBackendNotes] = useState([]);
  const [activeNote, setActiveNote] = useState(null);
  const [value, setValue] = React.useState("");
  const [noteType, setNoteType] = useState("");
 
  // Update handleChange function to set noteType
  const handleChange = (event) => {
    setValue(event.target.value);
    setNoteType(event.target.value);
  };
 
  useEffect(() => {
    if (noteType === "GENERIC" || selectedRowIdNotes) {
      getNotesApi(selectedRowIdNotes, noteType)
        .then((notesData) => {
          console.log("Notes data from API:", notesData);
          if (notesData && notesData.data) {
            setBackendNotes(notesData.data);
          } else {
            setBackendNotes([]); // Set backendNotes to an empty array if data is null
          }
        })
        .catch((error) => {
          console.error("Error fetching notes:", error);
          setBackendNotes([]); // Set backendNotes to an empty array in case of an error
        });
    }
  }, [selectedRowIdNotes, noteType]);
 
  const addNote = (text, selectedRowIdNotes) => {
    createNoteApi(text, selectedRowIdNotes, currentUserId, currentUserName)
      .then((note) => {
        // Call getNotesApi to get the updated notes after adding a new note
        getNotesApi(selectedRowIdNotes).then((notesData) => {
          console.log("Updated Notes data from API:", notesData);
          if (notesData && notesData.data) {
            setBackendNotes(notesData.data);
          } else {
            setBackendNotes([]); // Set backendNotes to an empty array if data is null
          }
        });
 
        setActiveNote(null);
      })
      .catch((error) => {
        console.error("Error creating note:", error);
      });
  };
 
  const updateNote = async (text, usernoteuid, selectedRowIdNotes) => {
    try {
      const response = await updateNoteApi(
        text,
        usernoteuid,
        selectedRowIdNotes,
        currentUserName
      );
      console.log("Update Note API Response:", response);
 
      // Wait for the updateNoteApi call to finish before fetching updated notes
      const notesData = await getNotesApi(selectedRowIdNotes);
 
      console.log("Updated Notes data from API:", notesData);
      setBackendNotes(notesData.data);
 
      setActiveNote(null);
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };
 
  const deleteNote = (noteId) => {
    if (window.confirm("Are you sure you want to remove the note?")) {
      // Assuming you have the necessary data for the note to be deleted
      const noteToDelete = backendNotes.find(
        (backendNote) => backendNote.usernoteuid === noteId
      );
 
      if (noteToDelete) {
        deleteNoteApi(noteToDelete.usernoteuid, selectedRowIdNotes, currentUserName) // Pass usernoteuid and selectedRowIdNotes to deleteNoteApi
          .then(() => {
            // Call getNotesApi to get the updated notes after deleting a note
            getNotesApi(selectedRowIdNotes).then((notesData) => {
              console.log("Updated Notes data from API:", notesData);
              setBackendNotes(notesData.data);
            });
          })
          .catch((error) => {
            console.error("Error deleting note:", error);
          });
      }
    }
  };
 
  const infoMessageStyle = {
    backgroundColor: "#f0f8ff", // Light Blue
    padding: "20px",
    borderRadius: "8px",
    margin: "20px 0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };
 
  const paragraphStyle = {
    margin: "0",
    fontSize: "16px",
    color: "#1a1a1a", // Darker text color
  };
 
  const firstDivStyle = {
    ...infoMessageStyle,
    padding: "10px", // Adjust the padding for the first div
  };
 
  return (
    <div className="notes">
      <FormControl style={{ fontSize: "12px", marginBottom: "20px" }}>
        <FormLabel
          id="demo-controlled-radio-buttons-group"
          style={{ fontSize: "16px", marginBottom: "5px", display: "block", color: "black" }}
        >
          Note Category
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                value="GENERIC"
                control={<Radio style={{ fontSize: "12px" }} />}
                label={<span style={{ fontSize: "12px" }}>Generic</span>}
                labelPlacement="end"
                style={{
                  fontSize: "12px",
                  marginBottom: "0",
                  marginRight: "10px",
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <FormControlLabel
                value="Panel Record"
                control={<Radio style={{ fontSize: "12px" }} />}
                label={<span style={{ fontSize: "12px" }}>Panel Record</span>}
                labelPlacement="end"
                style={{ fontSize: "12px", marginBottom: "0" }}
              />
            </div>
          </div>
        </RadioGroup>
      </FormControl>
 
      {value === "GENERIC" && (
        <React.Fragment>
          <div className="note-form-title">Write note</div>
          <NoteForm
            submitLabel="Submit"
            handleCancel={() => {
              setActiveNote("");
              setText("");
            }}
            handleSubmit={(text) => addNote(text, selectedRowIdNotes)}
          />
 
          <div className="notes-container">
            {backendNotes.map((note) => (
              <Note
                note={note}
                key={note.usernoteuid}
                activeNote={activeNote}
                setActiveNote={setActiveNote}
                updateNote={(text, usernoteuid) =>
                  updateNote(text, usernoteuid, selectedRowIdNotes)
                }
                deleteNote={deleteNote}
                currentUserId={note.usernoteuid}
                selectedRowIdNotes={selectedRowIdNotes}
              />
            ))}
          </div>
        </React.Fragment>
      )}
 
      {value === "Panel Record" && selectedRowIdNotes ? (
        <React.Fragment>
          <div style={firstDivStyle}>
            <p style={paragraphStyle}>
              Notes specific to the selected panel record ({selectedRowIdNotes}
              ).
            </p>
          </div>
          <div className="note-form-title">Write note</div>
          <NoteForm
            submitLabel="Submit"
            handleCancel={() => {
              setActiveNote("");
              setText("");
            }}
            handleSubmit={(text) => addNote(text, selectedRowIdNotes)}
          />
 
          <div className="notes-container">
            {backendNotes.map((note) => (
              <Note
                note={note}
                key={note.usernoteuid}
                activeNote={activeNote}
                setActiveNote={setActiveNote}
                updateNote={(text, usernoteuid) =>
                  updateNote(text, usernoteuid, selectedRowIdNotes)
                }
                deleteNote={deleteNote}
                currentUserId={note.usernoteuid}
                selectedRowId={selectedRowIdNotes}
              />
            ))}
          </div>
        </React.Fragment>
      ) : value === "Panel Record" && !selectedRowIdNotes ? (
        <div style={infoMessageStyle}>
          <p style={paragraphStyle}>
            {""}
            Please select a row in any of the panels' tables to view and post
            notes.
          </p>
        </div>
      ) : null}
    </div>
  );
};
 
export default Notes;
 