// Rectangle.js
import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './Popup.css';
import Gear from '../Gear.svg';
import RegistrationForm from './RegistrationForm';
import DataDisplay from './DataDisplay'; // Import the DataDisplay component
import newsvg from '../newsvg.svg';
import BlueButton from './BlueButton'; // Import BlueButton component
import RedButton from './RedButton'; // Import RedButton component

function Rectangle({ data, onTextChange ,borderColor}) {
  const [isTextModalOpen, setTextModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editedText, setEditedText] = useState(data.text);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDescriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [areBordersVisible, setBordersVisibility] = useState(false);
  const [isRegistrationModalOpen, setRegistrationModalOpen] = useState(false);
  // Add state for color and SVG
  const [color, setColor] = useState('#000000'); // Initial color
  const [svg, setSvg] = useState(Gear); // Initial SVG
  // Add state for box color
  const [boxColor, setBoxColor] = useState('#000000'); // Initial box color

  // Add a function to handle button click for blue border and Gear.svg
  const handleBlueButtonClick = () => {
    setBoxColor('blue'); // Change the border color to blue
    setSvg(Gear); // Show the Gear.svg
  };

  // Add a function to handle button click for red border and red Gear.svg
  const handleRedButtonClick = () => {
    setBoxColor('red'); // Change the border color to red
    setSvg(newsvg); // Show the newsvg.svg
  };

  const handleOpenTextModal = (e) => {
    e.stopPropagation();
    setTextModalOpen(true);
  };

  const handleCloseTextModal = () => {
    setTextModalOpen(false);
  };

  const handleOpenEditModal = (e) => {
    e.stopPropagation();
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setIsSubmitted(true);
    onTextChange(editedText);
  };

  const handleOpenDescriptionModal = () => {
    setDescriptionModalOpen(true);
  };

  const handleCloseDescriptionModal = () => {
    setDescriptionModalOpen(false);
  };

  const handleOpenRegistrationModal = () => {
    setRegistrationModalOpen(true);
  };

  const handleCloseRegistrationModal = () => {
    setRegistrationModalOpen(false);
  };

  const handleClick = () => {
    setBordersVisibility(!areBordersVisible);
  };

  const handleStyles = {
    target: { position: 'absolute', right: '17px', top: '50%', transform: 'translateY(-50%)' },
    source: { position: 'absolute', left: '100px', top: '50%', transform: 'translateY(-50%)' },
    top: { position: 'absolute', top: '-8px', left: '50%', transform: 'translateX(-50%)' },
  };

  return (
    <div className={`rectangle ${areBordersVisible ? 'bordersVisible' : ''}`} onClick={handleClick} onDoubleClick={handleOpenEditModal} style={{ color: color, borderColor: boxColor }}>
      <Handle type="source" position={Position.Right} id="Right" style={handleStyles.source} />
      <Handle type="target" position={Position.Left} id="LEFT" style={handleStyles.target} />
      <Handle type="target" position={Position.Top} style={handleStyles.top} />

      <div>
        {/* SVG Example */}
        <svg width="100" height="60" xmlns="http://www.w3.org/2000/svg">
          <image href={svg} width="100" height="57" />
        </svg>
        <DataDisplay value={data.value} /> {/* Use the DataDisplay component */}
        {isSubmitted ? (
          <p style={{ textAlign: 'center', position: 'absolute', top: '80%', left: '50%', transform: 'translateX(-50%)', color: '#ff0000' }}>
            {editedText}
          </p>
        ) : null}
        <p>{data.description}</p>
        {data.btn}
        {data?.btnArray?.map((item) => (
          <button key={item}>{item}</button>
        ))}
        {/* Render BlueButton and RedButton components */}
        <BlueButton onClick={handleBlueButtonClick} />
        <RedButton onClick={handleRedButtonClick} />
        <button
          onClick={handleOpenRegistrationModal}
          style={{
            position: 'absolute',
            bottom: '50px',
            right: '1px',
            fontSize: '6.5px', // Adjust the font size to make the button smaller
            padding: '1px 1px', // Adjust padding for better appearance
            background: 'white', // Add background color
            color: 'black', // Add text color
            border: '0.5px solid #007bff', // Add border
            borderRadius: '4px', // Add border-radius for rounded corners
            cursor: 'pointer',
          }}
        >
          Register
        </button>

      </div>

      <Handle type="source" position={Position.Bottom} id="b" />

      <Modal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        center
        classNames={{ overlay: 'customOverlay', modal: 'customModal', closeButton: 'closeIcon' }}
      >
        <textarea value={editedText} onChange={(e) => setEditedText(e.target.value)} />
        <button onClick={handleCloseEditModal}>Submit</button>
      </Modal>

      <Modal
        open={isTextModalOpen}
        onClose={handleCloseTextModal}
        center
        classNames={{ overlay: 'customOverlay', modal: 'customModal', closeButton: 'closeIcon' }}
      >
        <p>{data.description}</p>
      </Modal>

      <Modal
        open={isDescriptionModalOpen}
        onClose={handleCloseDescriptionModal}
        center
        classNames={{ overlay: 'customOverlay', modal: 'customModal', closeButton: 'closeIcon' }}
      >
        <p>{data.orgprocessdescription}</p>
      </Modal>

      <Modal
        open={isRegistrationModalOpen}
        onClose={handleCloseRegistrationModal}
        center
        classNames={{ overlay: 'customOverlay', modal: 'customModal', closeButton: 'closeIcon' }}
      >
        <RegistrationForm onClose={handleCloseRegistrationModal} />
      </Modal>
    </div>
  );
}

export default Rectangle;
