
//ToDoAction
import axios from "axios";
import * as q from "q";
 
export function getTodos(userUid) {
  var defer = q.defer();
  var url = process.env.REACT_APP_BASEURL + "getTodo";
  // var url = "http://localhost:3100/getTodo";
  console.log("TodoAction URL: " + url);
  console.log("getTodos RowUUID from TodoAction: ", userUid);
  axios
    .post(url, {
      brisqueuseruid: userUid,
    })
    .then(function (response) {
      defer.resolve(response);
    })
    .catch(function (error) {
      defer.reject(error);
    });
  return defer.promise;
}
 
export const createTodo = async (
  taskName,
  text,
  expectedStartDate,
  expectedEndDate,
  userUid,
  userName
) => {
  try {
    const endpoint = process.env.REACT_APP_BASEURL + "getToputTododo";;
    const tableName = "brisqueussertodo";
 
    const currentDateTime = new Date().toISOString();
    const formattedDateTime = currentDateTime.replace("T", " ").split(".")[0];
 
    const payload = [
      {
        recno: 1,
        dboperation: "INSERT",
        usertodouid: 0,
        brisqueuseruid: userUid,
        todotaskname: taskName,
        todotaskdescription: text,
        todotaskorigination: "SELF",
        todoexpecteddatestart: expectedStartDate,
        todoexpecteddateend: expectedEndDate,
        todoactualdateend: null,
        tododelegatoruseruid: null,
        todostatus: "ACTIVE",
        createdby: userName,
        updatedby: null,
        createddate: formattedDateTime,
        updateddate: null,
      },
    ];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Todo created successfully!");
      console.log("API Response:", result);
      return result.createdTodo;
    } else {
      console.error(`Error creating todo. Error code: ${result.error_code}`);
      return null;
    }
  } catch (error) {
    console.error("Error creating todo:", error);
    return null;
  }
};
 
export const updateTodo = async (
  taskName,
  text,
  expectedStartDate,
  expectedEndDate,  
  usertodouid,
  todostatus,
  userUid,
  username,
  actualEndDate
) => {
  try {
    const endpoint = process.env.REACT_APP_BASEURL + "putTodo";
    const tableName = "brisqueussertodo";
 
    console.log(usertodouid);
    console.log(userUid);
    console.log(actualEndDate);
    console.log(todostatus);
    // Fetch the existing todo details
    const existingTodoResponse = await axios.post(
      "http://localhost:3100/getTodo",
      {
        brisqueuseruid: userUid,
        usertodouid: usertodouid,
      }
    );
 
    // Assuming the response data is stored in the variable named 'response'
    const existingTodoArray = existingTodoResponse.data;
 
    const userTodouidToFind = usertodouid;
 
    const existingTodo = existingTodoArray.find(
      (obj) => obj.usertodouid === userTodouidToFind
    );
 
    // Now 'existingTodo' contains the todo details
    console.log("Existing Todo:", existingTodo);
 
    // Modify this line to format both created and updated dates
    const formattedCreatedDateTime = existingTodo.createddate
      .replace("T", " ")
      .replace(/\.\d+Z$/, "")
      .replace(" ", " ");
    const formattedUpdatedDateTime = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
 
    // Create a copy of the existing todo with updated text and other necessary changes
    const updatedTodo = {
      ...existingTodo,
      dboperation: "UPDATE",
      todotaskname: taskName,
      todotaskdescription: text,
      todoexpecteddatestart: expectedStartDate,
      todoexpecteddateend: expectedEndDate,
      updatedby: username,
      // Set todostatus to existing value if provided and existingTodo exists, otherwise leave it undefined
      todostatus:
        todostatus !== undefined ? todostatus : existingTodo.todostatus,
      todoactualdateend:
        actualEndDate !== undefined
          ? actualEndDate
          : existingTodo.todoactualdateend,
      updateddate: formattedUpdatedDateTime,
      createddate: formattedCreatedDateTime,
    };
 
    const payload = [updatedTodo];
    console.log(payload);
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Todo updated successfully!");
      console.log("API Response:", result);
      return result.updatedTodo || updatedTodo; // Use the API response, fallback to the local update if not provided
    } else {
      console.error(`Error updating todo. Error code: ${result.error_code}`);
      return null;
    }
  } catch (error) {
    console.error("Error updating todo:", error);
    return null;
  }
};
 
export const deleteTodo = async (usertodouid, userUid, username) => {
  try {
    const endpoint = process.env.REACT_APP_BASEURL + "putTodo";
    const tableName = "brisqueussertodo";
 
    // Fetch the existing todo details
    const existingTodoResponse = await axios.post(
      process.env.REACT_APP_BASEURL + "getTodo",
      {
        brisqueuseruid: userUid,
        usertodouid: usertodouid,
      }
    );
 
    // Assuming the response data is stored in the variable named 'response'
    const existingTodoArray = existingTodoResponse.data;
 
    const userTodouidToFind = usertodouid;
 
    const existingTodo = existingTodoArray.find(
      (obj) => obj.usertodouid === userTodouidToFind
    );
 
    // Now 'existingTodo' contains the todo details
    console.log("Existing Todo:", existingTodo);
 
    // Modify this line to format both created and updated dates
    const formattedCreatedDateTime = existingTodo.createddate
      .replace("T", " ")
      .replace(/\.\d+Z$/, "")
      .replace(" ", " ");
    const formattedUpdatedDateTime = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
 
    // Create a copy of the existing todo with necessary changes for DELETE operation
    const deletedTodo = {
      ...existingTodo,
      dboperation: "DELETE",
      updatedby: username,
      updateddate: formattedUpdatedDateTime,
      createddate: formattedCreatedDateTime,
    };
 
    const payload = [deletedTodo];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Todo deleted successfully!");
      console.log("API Response:", result);
      return result.deletedTodo;
    } else {
      console.error(`Error deleting todo. Error code: ${result.error_code}`);
      return null;
    }
  } catch (error) {
    console.error("Error deleting todo:", error);
    return null;
  }
};
 