
// TodoList.js
import React, { useState, useEffect } from "react";
import TodoForm from "./TodoForm";
import Todo from "./Todo";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Input from "@mui/material/Input";
 
import {
  getTodos as getTodosApi,
  createTodo as createTodoApi,
  updateTodo as updateTodoApi,
  deleteTodo as deleteTodoApi,
} from "../../../action/ToDoAction";
 
const TodoList = ({ sessionUserID, currentUserName }) => {
  const [todos, setTodos] = useState([]);
  const [taskName, settaskName] = useState("");
  const [text, setText] = useState("");
  const [expectedEndDate, setexpectedEndDate] = useState("");
  const [expectedStartDate, setexpectedStartDate] = useState("");
  const [activeToDo, setActiveToDo] = useState(null);
 
  const [selectedDays, setSelectedDays] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [days, setDays] = React.useState("");
  const [postCompletedClicked, setPostCompletedClicked] = useState(false);
  const inputRef = React.useRef(null);
 
 
  const handleDaysChange = (event) => {
    // Added this function
    setDays(event.target.value);
  };
 
  const fetchTasks = () => {
    const userUid = 1; // this should be replaced with a way of getting the logged in users uid When Arshi will done with userSession.
    getTodosApi(sessionUserID)
      .then((response) => {
        // Assuming response.data contains the todos
        setTodos(response.data);
        console.log(response.data);
        setLoading(false);
      })
 
      .catch((error) => {
        console.error("Error fetching todos:", error);
        setTodos([]);
        setLoading(false);
      });
  };
 
  useEffect(() => {
    if (sessionUserID) {
      fetchTasks();
    }
  }, [sessionUserID]);
 
  const filterTodos = () => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format
 
    // Filter out completed tasks if "Post" button is clicked
    let filteredTodos = todos;
    if (postCompletedClicked) {
      filteredTodos = filteredTodos.filter(
        (todo) => todo.todostatus !== "COMPLETED"
      );
    }
 
    switch (selectedDays) {
      case "Today":
        return filteredTodos.filter(
          (todo) => todo.todoexpecteddateend === today
        );
      case "Overdue-Tasks":
        return filteredTodos.filter((todo) => todo.todoexpecteddateend < today);
      case "nextXDays":
        if (days > 0) {
          const nextDays = new Date();
          nextDays.setDate(nextDays.getDate() + parseInt(days));
          const nextDaysFormatted = nextDays.toISOString().split("T")[0];
          return filteredTodos.filter(
            (todo) =>
              todo.todoexpecteddateend >= today &&
              todo.todoexpecteddateend <= nextDaysFormatted
          );
        } else {
          return filteredTodos;
        }
      default:
        return filteredTodos;
    }
  };
 
  const postCompletedTasks = async () => {
    // Filter completed tasks
    const completedTasks = todos.filter(
      (todo) => todo.todostatus === "COMPLETE"
    );
 
    // Check if there are completed tasks to post
    if (completedTasks.length === 0) {
      alert("No completed tasks to post.");
      return;
    }
 
    // Update state to trigger filtering of completed tasks
    setPostCompletedClicked(true);
  };
 
  const addTodo = (taskName, text, expectedStartDate, expectedEndDate) => {
    // Assuming you have access to the current user ID or user session
    const userUid = 1;
    console.log("E and S", expectedEndDate, expectedStartDate);
    createTodoApi(
      taskName,
      text,
      expectedStartDate,
      expectedEndDate,
      sessionUserID,
      currentUserName,
    )
      .then((createdTodo) => {
        // Update the todos state with the new todo
        getTodosApi(sessionUserID).then((todosData) => {
          console.log("Updated Comments data from API:", todosData);
          setTodos(todosData.data);
        });
 
        setActiveToDo(null);
      })
      .catch((error) => {
        console.error("Error creating todo:", error);
      });
  };
 
  const updateTodo = async (
    taskName,
    text,
    expectedStartDate,
    expectedEndDate,
    usertodouid,
    // userUid
  ) => {
    // Assuming you have access to the current user ID or user session
    // const userUid = 1;
    const todostatus = "ACTIVE";
    console.log(usertodouid);
    try {
      const response = await updateTodoApi(
        taskName,
        text,
        expectedStartDate,
        expectedEndDate,
        usertodouid,
        todostatus,
        sessionUserID,
        currentUserName
      );
      console.log("Update todo API Response:", response);
 
      // Wait for the updateTodoApi call to finish before fetching updated todos
      const todosData = await getTodosApi(sessionUserID);
 
      console.log("Updated todo data from API:", todosData);
      setTodos(todosData.data);
 
      setActiveToDo(null);
     
    } catch (error) {
      console.error("Error updating todo:", error);
    }
  };
 
  const removeTodo = (todoId) => {
    if (window.confirm("Are you sure you want to remove the ToDo?")) {
      // Assuming you have the necessary data for the todo to be deleted
      const TodoToDelete = todos.find(
        (backendTodo) => backendTodo.usertodouid === todoId
      );
 
      if (TodoToDelete) {
        deleteTodoApi(TodoToDelete.usertodouid, TodoToDelete.brisqueuseruid, currentUserName)
          .then(() => {
            // Call getCommentsApi to get the updated todos after deleting a todo
            // const userUid = TodoToDelete.brisqueuseruid;
            getTodosApi(sessionUserID).then((commentsData) => {
              console.log("Updated Comments data from API:", commentsData);
              setTodos(commentsData.data);
            });
          })
          .catch((error) => {
            console.error("Error deleting todo:", error);
          });
      }
    }
  };
 
  const completeTodo = async (id) => {
    // Find the todo by id
    const todoToUpdate = todos.find((todo) => todo.usertodouid === id);
    console.log("BBB ", todoToUpdate);
    if (!todoToUpdate) return; // If todo not found, exit the function
 
    try {
      // Update the todo status based on its current state
      const currentDateTime = new Date().toISOString();
      const formattedDateTime = currentDateTime.replace("T", " ").split(".")[0];
      const actualEndDate = formattedDateTime;
 
      const updatedTodo = {
        ...todoToUpdate,
        todostatus:
          todoToUpdate.todostatus === "ACTIVE" ? "COMPLETED" : "ACTIVE",
        todoactualdateend: actualEndDate,
      };
 
      // Call the updateTodoApi to update the todo status
      const response = await updateTodoApi(
        updatedTodo.todotaskname,
        updatedTodo.todotaskdescription,
        updatedTodo.todoexpecteddateend,
        updatedTodo.todoexpecteddatestart,
        updatedTodo.usertodouid,
        updatedTodo.todostatus,
        updatedTodo.brisqueuseruid,
        updatedTodo.todoactualdateend
      );
 
      console.log("Update todo API Response:", response);
 
      // Wait for the updateTodoApi call to finish before fetching updated todos
      // Assuming you have access to the current user ID or user session
      // const userUid = todoToUpdate.brisqueuseruid;
      const todosData = await getTodosApi(sessionUserID);
      console.log("Updated todo data from API:", todosData);
      setTodos(todosData.data);
 
      setActiveToDo(null);
    } catch (error) {
      console.error("Error updating todo:", error);
    }
  };
 
  return (
    <div className="comments">
      {/* Radio buttons for filtering */}
 
      <div style={{ fontSize: "12px", marginBottom: "20px" }}>
        <label
          id="demo-controlled-radio-buttons-group"
          style={{ fontSize: "16px", marginBottom: "5px", display: "block" }}
        >
          Filter Category
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <input
              type="radio"
              id="todayRadio"
              name="controlled-radio-buttons-group"
              value="Today"
              checked={selectedDays === "Today"}
              onChange={() => setSelectedDays("Today")}
              style={{ fontSize: "12px", marginRight: "5px" }}
            />
            <label
              htmlFor="todayRadio"
              style={{
                fontSize: "12px",
                marginBottom: "0",
                marginRight: "10px",
              }}
            >
              Today
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <input
              type="radio"
              id="overdueRadio"
              name="controlled-radio-buttons-group"
              value="Overdue-Tasks"
              checked={selectedDays === "Overdue-Tasks"}
              onChange={() => setSelectedDays("Overdue-Tasks")}
              style={{ fontSize: "12px", marginRight: "5px" }}
            />
            <label
              htmlFor="overdueRadio"
              style={{ fontSize: "12px", marginBottom: "0" }}
            >
              Overdue-Tasks
            </label>
          </div>
        </div>
        <div style={{ display: "flex", marginBottom: "5px" }}>
          <input
            type="radio"
            id="nextXDaysRadio"
            name="controlled-radio-buttons-group"
            value="nextXDays"
            checked={selectedDays === "nextXDays"}
            onChange={() => setSelectedDays("nextXDays")}
            style={{ fontSize: "12px", marginRight: "5px" }}
          />
          <label
            htmlFor="nextXDaysRadio"
            style={{ fontSize: "12px", marginBottom: "0" }}
          >
            Next
            <Input
              type="number"
              value={days}
              onChange={handleDaysChange}
              placeholder="X"
              style={{
                width: "35px",
                fontSize: "12px",
                padding: "2px",
                margin: "0",
              }}
              slotProps={{
                input: {
                  ref: inputRef,
                  min: 1,
                  max: 20,
                  step: 1,
                },
              }}
            />
            Days
          </label>
        </div>
      </div>
 
      <div className="comment-form-title">Schedule your task</div>
 
      <TodoForm
        submitLabel="Add Todo"
        handleCancel={() => {
          setActiveToDo("");
          setText(""); // Clear the text area in the Todo component
          settaskName("");
          setexpectedStartDate("");
          setexpectedEndDate("");
        }}
        handleSubmit={(taskName, text, expectedStartDate, expectedEndDate) =>
          addTodo(taskName, text, expectedStartDate, expectedEndDate)
        }
      />
 
      <button onClick={postCompletedTasks} className="todo-button post">
        Post
      </button>
 
      {loading ? (
        <p>Loading...</p>
      ) : (
        (filterTodos() ?? []).map((todo) => (
          <Todo
            todo={todo}
            key={todo.usertodouid}
            activeToDo={activeToDo}
            setActiveToDo={setActiveToDo}
            completeTodo={completeTodo}
            removeTodo={removeTodo}
            updateTodo={(
              taskName,
              text,
              expectedStartDate,
              expectedEndDate,
              usertodouid,
              userUid
            ) =>
              updateTodo(
                taskName,
                text,
                expectedStartDate,
                expectedEndDate,
                usertodouid,
                userUid
              )
            }
            userUid={sessionUserID}
            currentUserId={todo.usertodouid}
          />
        ))
      )}
    </div>
  );
};
 
export default TodoList;
 