import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import Drawer from '@mui/material/Drawer';

function RightPanel() {
  const [expanded, setExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState();

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleButtonClick = (index) => {
    setSelectedTab(index);
  };

  

  return (
    <div >

    </div>
  );
}

export default RightPanel;
