import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Checkbox,
  TableContainer,
  TableRow,
  styled,
  Button,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E8E8E8",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F5F5F5",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const EditPanel = ({
  isContentVisible,
  handleAddRow, //function to add row
  setUpdateEditData, // data upadted in edit panel and send to detail panel
  bodyDataEdit, ///initial data pass to edit panel and edited 
  setbodyDataEdit, //to make changes in body data
  editData, //initial data pass to edit panel as a copy
  seteditData,  //
  sesssionData,
  headerData,
}) => {


  console.log("XXXX"  + JSON.stringify(bodyDataEdit))
  // useEffect(()=>{
  //   let username = sesssionData["username"];
  

  // })
  const [panelVisible, setPanelVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  let username = sesssionData["username"];
  // const user = "Bhagesh2";
  const handleAccordionChange = () => {
    setPanelVisible(!panelVisible);
  };

  const handleCheckboxChange = (rowIndex) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = [...prevSelectedRows];
      const indexToRemove = newSelectedRows.indexOf(rowIndex);

      if (indexToRemove !== -1) {
        newSelectedRows.splice(indexToRemove, 1);
      } else {
        newSelectedRows.push(rowIndex);
      }

      return newSelectedRows;
    });
  };

  const handleDeleteSelectedRows = () => {
    const updatedData = bodyDataEdit.filter(
      (_, index) => !selectedRows.includes(index)
    );
    setbodyDataEdit(updatedData);
    seteditData(updatedData);
    setSelectedRows([]);
  };

  const handleCancelEdit = () => {
    setbodyDataEdit(editData);
  };

  const formatDate = (date) => {
    return date.toISOString().slice(0, 19).replace("T", " ");
  };

  const modifyObject = (index, columnName, value) => {
    const updatedData = bodyDataEdit.map((obj, i) => {
      if (i === index) {
        // Check if the column exists in the object
        if (obj.hasOwnProperty(columnName)) {
          // Modify the value of the specified column
          const modifiedObj = {
            ...obj,
            [columnName]: value,
          };

          // Modify the value of dboperation based on conditions
          if (!modifiedObj.dboperation) {
            modifiedObj.dboperation = "INSERT";
            if (modifiedObj.dboperation === "INSERT") {
              if (modifiedObj.hasOwnProperty("createdby")) {
                modifiedObj.createdby = username; // Replace "currentUser" with the actual user information
              }
              if (modifiedObj.hasOwnProperty("createddate")) {
                modifiedObj.createddate = formatDate(new Date()); // Use the provided formatDate function
              }
            }
          } else if (modifiedObj.dboperation === "RETRIEVE") {
            modifiedObj.dboperation = "UPDATE";
            // Update updatedBy and updatedDate for UPDATE operation
            if (modifiedObj.dboperation === "UPDATE") {
              if (modifiedObj.hasOwnProperty("updatedby")) {
                modifiedObj.updatedby = username; // Replace "currentUser" with the actual user information
              }
              if (modifiedObj.hasOwnProperty("updateddate")) {
                modifiedObj.updateddate = formatDate(new Date()); // Use the provided formatDate function
                modifiedObj.createddate = modifiedObj.createddate.replace(
                  "T",
                  " "
                );
              }
            }
          }

          return modifiedObj;
        }
      }
      return obj;
    });
    setbodyDataEdit(updatedData); // Set the updated data in state
  };

  const handleSaveChanges = () => {
    setUpdateEditData(bodyDataEdit);
    setbodyDataEdit([]);
    seteditData([]);
  };

  return (
    <Accordion expanded={panelVisible} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={panelVisible ? <RemoveIcon /> : <AddIcon />}
      >
        <Typography variant="h7" sx={{ flexGrow: "1", textAlign: "center" }}>
          Edit Panel for the context
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {bodyDataEdit && bodyDataEdit.length > 0 && (
          <div style={{ overflowY: "auto" }}>
            <Paper sx={{ width: "100%", overflow: "auto" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <StyledTableRow>
                      <TableCell>{"Select"}</TableCell>
                      {headerData.map((column) =>
                        column.columndisplayname !== "NODISPLAY" ? (
                          <TableCell key={column.columnname}>
                            {column.columndisplayname}
                          </TableCell>
                        ) : null
                      )}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {bodyDataEdit.map((rowData, rowIndex) => (
                      <StyledTableRow key={`row-${rowIndex}`}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.includes(rowIndex)}
                            onChange={() => handleCheckboxChange(rowIndex)}
                          />
                        </TableCell>
                        {headerData.map((column, colIndex) => {
                          if (column.columndisplayname !== "NODISPLAY") {
                            const cellKey = `cell-${rowIndex}-${colIndex}`;
                            const cellValue = rowData.hasOwnProperty(
                              column.columndisplayshortname
                            )
                              ? rowData[column.columndisplayshortname]
                              : "";
                            return (
                              <TableCell key={cellKey}>
                                <TextField
                                  value={cellValue}
                                  sx={{
                                    backgroundColor: "#ffffff",
                                    minWidth: 200,
                                  }}
                                  onChange={(e) => {
                                    // Here, update the object's value with the corresponding column
                                    modifyObject(
                                      rowIndex,
                                      column.columndisplayshortname,
                                      e.target.value
                                    );
                                  }}
                                />
                              </TableCell>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        )}
      </AccordionDetails>

      <Button
        variant="contained"
        sx={{ margin: "15px", marginTop: "0" }}
        color="primary"
        onClick={handleSaveChanges}
      >
        OK
      </Button>
      <Button
        variant="contained"
        sx={{ margin: "15px", marginTop: "0" }}
        color="primary"
        onClick={handleAddRow}
      >
        Add
      </Button>
      <Button
        variant="contained"
        sx={{ margin: "15px", marginTop: "0" }}
        color="primary"
        onClick={handleCancelEdit}
      >
        Cancel
      </Button>
      {selectedRows.length > 0 && (
        <Button
          variant="contained"
          sx={{ margin: "15px", marginTop: "0" }}
          color="secondary"
          onClick={handleDeleteSelectedRows}
        >
          Delete Selected
        </Button>
      )}
    </Accordion>
  );
};

export default EditPanel;
