
import { useNavigate } from "react-router-dom";

export default function ScreenDesignCheck(screenName) {
    
    switch(screenName) {
        case 'managebrisquescreen':
          console.log('Displaying list of available commands')
          break;
        case 'managebrisquescreenpanel':
            console.log('Displaying list of available commands')
            break;
        default:
          return 'You\'re done! No Screen Found!';
      }
      return screenName;
  }

// export default function ScreenDesignCheck(screenName){


//     const navigate = useNavigate();
  

   
        
  

//         if (screenName === "managebrisquescreen"){
//             navigate('/layouts/managebrisquescreen')
//           } else if(screenName === "managebrisquescreenpanel"){
//             navigate('/layouts/managebrisquescreenpanel')
//           }
    
  

//     // switch(screenName) {
//     //     case 'managebrisquescreen':
//     //       console.log('Displaying list of available commands')
//     //       navigate("/layouts/managebrisquescreen");
//     //       break;
//     //     case 'managebrisquescreenpanel':
//     //         console.log('Displaying list of available commands')
//     //         navigate("/layouts/managebrisquescreenpanel");
//     //         break;
//     //     default:
//     //       return 'You\'re done! No Screen Found!';
//     //   }

      
// }