import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import discussion from '../svg/discussion.svg'; // Import the discussion SVG
import anotherSvg from '../Gear.svg'; // Import another SVG
import datSvg from '../dat.svg'; // Import dat.svg
import apiSvg from '../api.svg'; // Import api.svg
import DataDisplay from './DataDisplay'; // Import the DataDisplay component
import MasterList from '../master.json';

function Trapezoid({ data }) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editedText, setEditedText] = useState(data.value);
  const [selectedDropdown, setSelectedDropdown] = useState('');
  const [selectedSvg, setSelectedSvg] = useState(discussion); // Default SVG

  useEffect(() => {
    setEditedText(data.value);
  }, [data.value]);

  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    if (selectedDropdown) {
      setEditedText(selectedDropdown);
      data.currentprocessname = selectedDropdown;
      const updatedMasterList = MasterList.map((item) => {
        if (item.processname === selectedDropdown) {
          return { ...item, processname: selectedDropdown };
        }
        return item;
      });
      localStorage.setItem('masterList', JSON.stringify(updatedMasterList));
    }
  };

  const handleDropdownChange = (event) => {
    setSelectedDropdown(event.target.value);
  };

  const handleSvgSelection = (svg) => {
    setSelectedSvg(svg);
  };

  const handleDoubleClick = () => {
    handleOpenEditModal();
  };

  return (
    <div className="trapezoid" onDoubleClick={handleDoubleClick}>
      <Handle type="source" position={Position.Right} id="right" style={{ top: '50%', transform: 'translateY(-50%)' }} />
      <Handle type="target" position={Position.Left} id="left" style={{ top: '50%', transform: 'translateY(-50%)' }} />
      <Handle type="target" position={Position.Top} id="top" style={{ left: '50%', transform: 'translateX(-50%)' }} />
      <Handle type="target" position={Position.Bottom} id="bottom" style={{ left: '50%', transform: 'translateX(-50%)' }} />

      <div>
        <svg width="100" height="60" xmlns="http://www.w3.org/2000/svg">
          <image href={selectedSvg} width="100" height="57" x="0" y="-7" />
        </svg>
        <DataDisplay value={editedText} />
      </div>

      {isEditModalOpen && (
        <Modal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          center
          classNames={{ overlay: 'customOverlay', modal: 'customModal', closeButton: 'closeIcon' }}
        >
          <textarea value={editedText} onChange={(e) => setEditedText(e.target.value)} />
          <select value={selectedDropdown} onChange={handleDropdownChange}>
            <option value="">Select Level</option>
            {MasterList.map((item) => (
              <option key={item.brisqueorgprocessuid} value={item.processname}>
                {item.processname}
              </option>
            ))}
          </select>
          <div>
            <select onChange={(e) => handleSvgSelection(e.target.value)}>
              <option value={discussion}>Discussion SVG</option>
              <option value={anotherSvg}>Another SVG</option>
              <option value={datSvg}>Dat SVG</option>
              <option value={apiSvg}>API SVG</option>
            </select>
          </div>
          <button onClick={handleCloseEditModal}>Submit</button>
        </Modal>
      )}
    </div>
  );
}

export default Trapezoid;
