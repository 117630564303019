import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import {
	getbrisquegroupmaster,
	getbrisqueuxactionmaster,
	getbrisquepanelgroupuxactionlisting,
	putbrisquegroupfeatureprivileges,
} from "../../../action/GroupActionPanel"

export default function GroupPanel({ panelData }) {
	const [groupMaster, setGroupMaster] = useState([])
	const [actionMaster, setActionMaster] = useState([])
	const [groupActionListing, setGroupActionListing] = useState([])
	const [groupActionListingRollback, setGroupActionListingRollback] =
		useState([])
	const [selectedGroup, setSelectedGroup] = useState([])
	const [groupNames, setGroupNames] = useState([])
	const [selectedGroupUIDs, setSelectedGroupUIDs] = useState("")
	const [userRoles, setUserRoles] = useState({})
	const [refreshData, setRefreshData] = useState(false)

	const panelName = "brisquepanelgroupuxactionlisting"
	const selectedUid = panelData && panelData[0]?.brisquepaneluid
	const user = "Bhagesh"

	useEffect(() => {
		if (panelData || refreshData) {
			getbrisquegroupmaster(panelName)
				.then((response) => {
					console.log(response)
					setGroupMaster(response.data)
					// setUserRoles({})
					// selectedGroupUIDs.length = 0
					// setSelectedGroupUIDs([])
				})
				.catch((error) => {
					console.log(error)
					setGroupMaster([])
				})
			getbrisqueuxactionmaster(panelName)
				.then((response) => {
					console.log(response)
					setActionMaster(response.data)
				})
				.catch((error) => {
					console.log(error)
					setActionMaster([])
				})
			getbrisquepanelgroupuxactionlisting(selectedUid, panelName)
				.then((response) => {
					console.log(response.data)
					setGroupActionListing(response.data || [])
					// Create a deep copy of response.data for setGroupActionListingRollback
					const dataCopy = JSON.parse(
						JSON.stringify(response.data || [])
					)
					setGroupActionListingRollback(dataCopy)
				})
				.catch((error) => {
					console.log(error)
					setGroupActionListing([])
					setGroupActionListingRollback([])
				})
				.finally(() => {
					setRefreshData(false) // Reset refreshData state
				})
		}
	}, [panelData, refreshData])
	console.log("groupActionListing", groupActionListing)

	useEffect(() => {
		const uniqueGroupUids = [
			...new Set(
				groupActionListing &&
					groupActionListing
						.filter((item) => item && item.dboperation !== "DELETE") // Filter out records with dboperation as "delete"
						.map((item) => item && item.groupuid)
			),
		]

		// Combine groupuids and groupnames into an array of objects
		const combinedArray = uniqueGroupUids.map((uid) => {
			const group = groupMaster.find((item) => item.groupuid === uid)
			return group
		})

		// Update state variables
		setGroupNames(combinedArray)
	}, [groupActionListing, groupMaster])

	const handleCheckboxChange = (event) => {
		setUserRoles({
			...userRoles,
			[event.target.name]: event.target.checked,
		})
	}

	const handleGroupChange = (event) => {
		const selectedGroups = groupMaster.find(
			(group) => group.groupname === event.target.value
		)
		setSelectedGroup(selectedGroups)
	}
	const handleAddGroup = () => {
		if (
			selectedGroup &&
			!groupNames.some(
				(group) => group.groupname === selectedGroup.groupname
			)
		) {
			setGroupNames([...groupNames, selectedGroup])
			setSelectedGroup(null)
		}
	}

	const handleShowAction = () => {
		let selectedUIDs = []
		// Iterate over userRoles object
		for (let groupname in userRoles) {
			// If the checkbox is checked
			if (userRoles[groupname]) {
				// Extract the groupuid
				let groupuid = groupNames.find(
					(group) => group.groupname === groupname
				).groupuid
				selectedUIDs.push(groupuid)
			}
		}
		setSelectedGroupUIDs(selectedUIDs)
	}

	const formatDate = (date) => {
		return date.toISOString().slice(0, 19).replace("T", " ")
	}

	function handleToggle(action, newValue) {
		let newData = {
			recno: null,
			dboperation: null,
			brisquefeatureuid: null,
			brisquescreenuid: null,
			brisquepaneluid: null,
			groupuid: null,
			uxactionuid: null,
			datestart: null,
			dateend: null,
			comments: null,
			createdby: null,
			updatedby: null,
			createddate: null,
			updateddate: null,
		}

		// Create a new array based on groupActionListing
		let newGroupActionListing = [...groupActionListing]

		// If the action is in the group and the new value is 'remove', remove it
		if (newValue === "remove") {
			const foundIndex = newGroupActionListing.findIndex(
				(groupAction) =>
					groupAction.uxactionuid === action.uxactionuid &&
					groupAction.groupuid === selectedGroupUIDs[0]
			)

			if (foundIndex !== -1) {
				if (
					newGroupActionListing[foundIndex].dboperation === "INSERT"
				) {
					// If dboperation is "INSERT", remove the object
					newGroupActionListing.splice(foundIndex, 1)
				} else {
					// Otherwise, update dboperation to "DELETE"
					newGroupActionListing[foundIndex].dboperation = "DELETE"
					newGroupActionListing[foundIndex].updatedby = user
					newGroupActionListing[foundIndex].updateddate = formatDate(
						new Date()
					)
					newGroupActionListing[foundIndex].createddate =
						newGroupActionListing[foundIndex].createddate
							.slice(0, 19)
							.replace("T", " ")
					newGroupActionListing[foundIndex].dateend = formatDate(
						new Date()
					).slice(0, 10)
					newGroupActionListing[foundIndex].comments =
						"DELETING A RECORD"
				}
			}
		}

		// If the action is not in the group and the new value is 'add', add it
		else if (newValue === "add" && selectedGroupUIDs.length > 0) {
			const foundIndex = newGroupActionListing.findIndex(
				(groupAction) =>
					groupAction.uxactionuid === action.uxactionuid &&
					groupAction.groupuid === selectedGroupUIDs[0]
			)
			if (foundIndex !== -1) {
				// Update the dboperation property of the found record
				newGroupActionListing[foundIndex].dboperation = "RETRIEVE"
			} else {
				let maxRecno = 0
				if (groupActionListing !== null) {
					maxRecno = Math.max(
						...groupActionListing.map((item) => item.recno || 0), // Check for null and undefined recno values
						0
					)
				}

				// Update values for groupuid, uxactionuid, and dboperation
				newData.groupuid = selectedGroupUIDs[0] // Set the appropriate value for groupuid
				newData.uxactionuid = action.uxactionuid // Set the appropriate value for uxactionuid
				newData.dboperation = "INSERT" // Change dboperation to "INSERT" for add action
				newData.brisquefeatureuid = panelData[0].brisquefeatureuid
				newData.brisquescreenuid = panelData[0].brisquescreenuid
				newData.brisquepaneluid = panelData[0].brisquepaneluid
				newData.createdby = user
				newData.createddate = formatDate(new Date())
				newData.datestart = formatDate(new Date()).slice(0, 10)
				newData.dateend = "2099-12-31"
				newData.recno = maxRecno + 1
				newGroupActionListing.push(newData)
			}
		}

		// Now you can set the state of the parent component with the new array
		setGroupActionListing(newGroupActionListing)
	}
	const handleRemoveGroup = () => {
		let updatedGroupNames = [...groupNames]
		let updatedGroupActionList = [...groupActionListing]

		// Iterate over userRoles object
		for (let groupname in userRoles) {
			// If the checkbox is checked
			if (userRoles[groupname]) {
				// Find the index of the group in the updatedGroupNames array
				const index = updatedGroupNames.findIndex(
					(group) => group.groupname === groupname
				)

				// If the group is found
				if (index !== -1) {
					// Remove the group from the updatedGroupNames array
					// updatedGroupNames.splice(index, 1)

					// Find the corresponding groupuid
					const groupuid = groupNames[index].groupuid

					// Update the dboperation for records in groupActionList where groupuid matches
					for (
						let i = updatedGroupActionList.length - 1;
						i >= 0;
						i--
					) {
						const record = updatedGroupActionList[i]
						if (
							record.groupuid === groupuid &&
							record.dboperation === "INSERT"
						) {
							// If dboperation is 'INSERT', remove this record
							updatedGroupActionList.splice(i, 1)
						} else if (record.groupuid === groupuid) {
							// Otherwise, perform the existing operation
							updatedGroupActionList[i] = {
								...record,
								dboperation: "DELETE",
								updatedby: user,
								updateddate: formatDate(new Date()),
								dateend: formatDate(new Date()).slice(0, 10),
								createddate: record.createddate
									.slice(0, 19)
									.replace("T", " "),
							}
						}
					}
					setSelectedGroupUIDs([])
					setUserRoles([])
				}
			}
		}

		// Update the groupNames array with the modified array
		setGroupNames(updatedGroupNames)

		// Update the groupActionList array with the modified array
		setGroupActionListing(updatedGroupActionList)

		// Reset userRoles to clear the checked checkboxes
		setUserRoles({})
	}

	console.log("groupActionListing", groupActionListing)

	const handlerollback = () => {
		setGroupActionListing(groupActionListingRollback)
		setSelectedGroupUIDs([])
		setUserRoles([])
	}
	const handlepost = () => {
		// Filter the combinedData to include only records with "UPDATE" or "INSERT" dboperations
		const filteredData = groupActionListing.filter(
			(item) =>
				item.dboperation === "DELETE" || item.dboperation === "INSERT"
		)

		console.log(JSON.stringify(filteredData))
		putbrisquegroupfeatureprivileges(JSON.stringify(filteredData))
			.then(function (message) {
				// Handle success case
				console.log("Success:", message)
				// Display a popup message with the returned message
				window.alert("Success: " + message)
				// Refresh body data by updating a state variable
				setRefreshData(true)
				// Further processing if needed
			})
			.catch(function (error) {
				// Handle error case
				console.error("Error:", error)
				// Display a popup message with the error
				window.alert("Error: " + error.message)
				// Further error handling if needed
			})
	}
	console.log("groupNames", groupNames)

	return (
		<div>
			<Grid container spacing={2}>
				{/* Grid for List of Groups */}
				<Grid item xs={6}>
					<div
						style={{
							border: "1px solid #ccc",
							borderRadius: "4px",
							padding: "10px",
							height: "100%",
						}}>
						<div
							style={{
								backgroundColor: "#2196f3",
								color: "#fff",
								padding: "5px",
								borderRadius: "4px",
								textAlign: "center",
							}}>
							<label>List of Groups</label>
						</div>
						{/* Add your list of groups here */}
						<div
							style={{
								marginTop: "10px",
								textAlign: "left",
							}}>
							{groupNames.length === 0 ? (
								<p style={{ textAlign: "center" }}>
									No groups available
									<br /> Add Groups
								</p>
							) : (
								groupNames.map((group, index) => (
									<div
										key={index}
										style={{
											display: "flex",
											alignItems: "center",
										}}>
										<Checkbox
											checked={
												userRoles[
													group && group.groupname
												] || false
											}
											onChange={handleCheckboxChange}
											name={group && group.groupname}
										/>
										<label>
											{group && group.groupname}
										</label>
									</div>
								))
							)}
						</div>

						<div style={{ textAlign: "center" }}>
							<Button
								variant="contained"
								color="primary"
								style={{ marginRight: "10px" }}
								onClick={handleShowAction}>
								Show Action
							</Button>

							<Button
								variant="contained"
								color="secondary"
								onClick={handleRemoveGroup}>
								Remove
							</Button>
						</div>
						<div
							style={{
								borderTop: "3px solid #ccc",
								marginTop: "10px",
								textAlign: "center",
							}}>
							<h3>Associate New Group</h3>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginLeft: "20px",
									marginRight: "20px",
								}}>
								<div>
									<FormControl fullWidth>
										<InputLabel id="Group">
											Select Group
										</InputLabel>
										<Select
											labelId="Group"
											id="GroupSelect"
											value={
												selectedGroup?.groupname || ""
											}
											onChange={handleGroupChange}
											label="Select Group"
											sx={{ minWidth: "200px" }}>
											{groupMaster.map((group) => (
												<MenuItem
													key={group.groupuid}
													value={group.groupname}>
													{group.groupname}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
								<div>
									<Button
										variant="contained"
										color="primary"
										style={{
											marginRight: "50px",
											marginTop: "10px",
										}}
										onClick={handleAddGroup}>
										OK
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Grid>
				{/* Grid for List of Actions */}
				<Grid item xs={6}>
					<div
						style={{
							border: "1px solid #ccc",
							borderRadius: "4px",
							padding: "10px",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}>
						<div
							style={{
								backgroundColor: "#2196f3",
								color: "#fff",
								padding: "5px",
								borderRadius: "4px",
								textAlign: "center",
							}}>
							<label>List of Actions</label>
						</div>
						{/* Checkboxes for actions */}
						{selectedGroupUIDs.length === 0 ? (
							<div
								style={{
									textAlign: "center",
									flex: 1,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
								No group selected. Please select a group.
							</div>
						) : (
							<div
								style={{
									marginTop: "10px",
									maxHeight: "400px",
									overflow: "auto",
									border: "1px solid #ccc",
								}}>
								{actionMaster.map((action) => {
									const isActionInGroup =
										groupActionListing &&
										groupActionListing.some(
											(groupAction) =>
												groupAction &&
												groupAction.dboperation !==
													"DELETE" && // Ignore records with dboperation as "DELETE"
												groupAction.uxactionuid ===
													action.uxactionuid &&
												groupAction.groupuid ===
													selectedGroupUIDs[0]
										)
									return (
										<div
											key={action.uxactionuid}
											style={{
												display: "flex",
												justifyContent: "space-between",
												marginBottom: "5px",
											}}>
											<div>
												<Checkbox />
												{action.uxactiondisplayname}
											</div>
											<div>
												<ToggleButtonGroup
													color="primary"
													style={{
														marginRight: "50px",
													}}
													exclusive // Set exclusive to true to allow only one selection
													value={
														isActionInGroup
															? "add"
															: ""
													}
													onChange={(
														event,
														newValue
													) =>
														handleToggle(
															action,
															newValue
														)
													}>
													<ToggleButton
														style={{
															border: "1px solid #ccc",
															borderRadius: "5px",
															marginRight: "5px",
														}}
														value="add">
														Add
													</ToggleButton>
													<ToggleButton
														style={{
															border: "1px solid #ccc",
															borderRadius: "5px",
															marginRight: "5px",
														}}
														value="remove">
														Remove
													</ToggleButton>
												</ToggleButtonGroup>
											</div>
										</div>
									)
								})}
							</div>
						)}

						{/* <div style={{ textAlign: "center", marginTop: "10px" }}>
							<Button
								variant="contained"
								color="primary"
								style={{ marginRight: "10px" }}>
								Done
							</Button>
							<Button variant="contained" color="secondary">
								Cancel
							</Button>
						</div> */}
					</div>
				</Grid>

				<div
					style={{
						textAlign: "center",
						width: "100%",
						margin: "20px 0px",
					}}>
					<Button
						z
						style={{ marginRight: "10px" }}
						variant="contained"
						color="primary"
						onClick={() => handlepost()}>
						Post
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => handlerollback()} // Corrected
					>
						Rollback
					</Button>
				</div>
			</Grid>
		</div>
	)
}
