import { useState } from "react";

const CommentForm = ({
  handleSubmit,
  submitLabel,
  handleCancel,
  initialText = "",
}) => {
  const [text, setText] = useState(initialText);

  const onSubmit = (event) => {
    event.preventDefault();

    // Explicitly check if parentId is true and set it to -1
    let parentId = submitLabel === "Submit" ? -1 : null;

    handleSubmit(text, parentId);
    setText(""); // Clear the text area
  };

  return (
    <form onSubmit={onSubmit}>
      <textarea
        className="comment-form-textarea"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Write your comment here..."
      />
      <br />
      <button
        className={`comment-form-button ${
          submitLabel === "Update" || submitLabel === "Reply"
            ? "update-reply-cancel-button"
            : ""
        }`}
        disabled={text.length === 0}
      >
        {submitLabel}
      </button>
      <button
        type="button"
        className={`comment-form-button ${
          submitLabel === "Reply" || submitLabel === "Update"
            ? "update-reply-cancel-button"
            : ""
        }`}
        onClick={() => {
          handleCancel();
          setText(""); // Clear the text area
        }}
      >
        Cancel
      </button>
    </form>
  );
};

export default CommentForm;
