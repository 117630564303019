import React, { useCallback, useState, useEffect } from 'react';
import ReactFlow, { addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import Circle from './Reactflow/TextUpdaterNode.js';
import Triangle from './Reactflow/TringleNode.js';
import PropTypes from 'prop-types';
import Trapezoid from './Reactflow/Trapezoid.js';
import Rectangle from './Reactflow/Rectangle.js';
import Parallelogram from './Reactflow/Parallelogram.js';
import 'reactflow/dist/style.css';
import './text-updater-node.css';
import VerticalBar from './TBar/VerticalBar.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import HorizontalBar from './TBar/Horizontalbar.js';
import nodeData from './nodeData.json';
import newData from './new.json';
import EdgeStyle from './EdgeStyle.js'
import Table from '../Table/TableComponent.js';
import NavigationBar from './Reactflow/navupdate.js';

const rfStyle = {
  backgroundColor: '#B8CEFF',
};

const nodeTypes = {
  circle: Circle,
  triangle: Triangle,
  trapezoid: Trapezoid,
  trapezoid2: Trapezoid,
  rectangle: Rectangle,
  parallelogram: Parallelogram,
};

function ShapeFlow({ initialNodes, initialEdges }) {
  const [nodes, setNodes] = useState(initialNodes || []);
  const [edges, setEdges] = useState(initialEdges || []);
  const [showData, setShowData] = useState(false);

  const [selectedEdge, setSelectedEdge] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [removedNodes, setRemovedNodes] = useState([]);
  const [removedEdges, setRemovedEdges] = useState([]);
  const [connectorStyle, setConnectorStyle] = useState({
    stroke: '#FF0076', // Red color
    strokeWidth: 2, // Bold stroke width
    markerEnd: 'url(#arrowhead)',
  });
  
  const [isTable2Maximized, setIsTable2Maximized] = useState(true);
  const [lastRectangleX, setLastRectangleX] = useState(430); // Initial x-coordinate
  const [lastTriangleX, setLastTriangleX] = useState(300); // Initial x-coordinate
  const [lastTrapezoidX, setLastTrapezoidX] = useState(150); // Initial x-coordinate

  const [lastParallelogramX, setLastParallelogramX] = useState(40); // Initial x-coordinate
  const [lastCircleX, setLastCircleX] = useState(650); // Initial x-coordinate
  const [currentX, setCurrentX] = useState(0); // Introduce currentX state for tracking x-coordinate
  const [tableData, setTableData] = useState(Array(6).fill({})); // Initialize with empty rows
  const [isHorizontal, setIsHorizontal] = useState(true);
  const levels = [
    { label: 'Level 1', buttons: ['Button 1', 'Button 2', 'Button 3'] },
    { label: 'Level 2', buttons: ['Button A', 'Button B', 'Button C'] }
  ];
  const addNode = (type, x, y) => {
    const data = nodeData[type];
    const newNode = {
      id: `${type}-${nodes.length + 1}`,
      type,
      position: { x, y },
      data,
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };
  const onConnect = useCallback(
    (params) => {
      setEdges((prevEdges) => [...prevEdges, params]);
    },
    [setEdges]
  );

  const createTableTemplate = () => {
    // Clear existing nodes and edges
    setNodes([]);
    setEdges([]);
  
    // Access node data from JSON file
    let nodeDataFromJson = Object.values(nodeData);
  
    // Filter out the second triangle node
    nodeDataFromJson = nodeDataFromJson.filter((node) => node.type !== 'triangle2');
  
    // Match data from new.json with nodeData.json based on brisqueorgprocessdependencyuid
    const updatedNodeData = nodeDataFromJson.map((node) => {
      const newDataItem = newData.find((item) => item.brisqueorgprocessdependencyuid === node.brisqueorgprocessdependencyuid);
      if (newDataItem) {
        return { ...node, ...newDataItem };
      }
      return node;
    });
  
    // Add nodes with specified positions and data
    const createdNodes = updatedNodeData.map((nodeData, index) => {
      return {
        id: `${nodeData.type}-${index + 1}`,
        brisqueorgprocessdependencyuid: nodeData.brisqueorgprocessdependencyuid,
        enterpriseuid: nodeData.enterpriseuid,
        parentbrisqueorgprocessuid: nodeData.parentbrisqueorgprocessuid,
        currentprocessstepseq: nodeData.currentprocessstepseq,
        currentbrisqueorgprocessuid: nodeData.currentbrisqueorgprocessuid,
        currentprocessname: nodeData.currentprocessname,
        currentorgprocessdescription: nodeData.currentorgprocessdescription,
        currentprocesslevel: nodeData.currentprocesslevel,
        nextbrisqueorgprocessuid: nodeData.nextbrisqueorgprocessuid,
        nextprocessname: nodeData.nextprocessname,
        nextorgprocessdescription: nodeData.nextorgprocessdescription,
        nextprocesslevel: nodeData.nextprocesslevel,
        editlockflag: nodeData.editlockflag,
        connectorLabel: nodeData.connectorlabel,
        data: nodeData.data,
        type: nodeData.type,
        position: { x: index * 200 + 100, y: 100 }, // Adjust x position as needed
      };
    }
    );
    console.log('createdNodes:', createdNodes);

  
    // Define edges based on node IDs
    const edgesFromJson = [];
    for (let i = 0; i < updatedNodeData.length - 1; i++) {
      edgesFromJson.push({
        id: `reactflow__edge-${updatedNodeData[i].type}-${i + 1}-${updatedNodeData[i + 1].type}-${i + 2}`, // Generate unique ID for the edge
        source: `${updatedNodeData[i].type}-${i + 1}`,
        target: `${updatedNodeData[i + 1].type}-${i + 2}`,
      });
    }
  
    // Add the connection from Triangle to Trapezoid
    const triangleIndex = updatedNodeData.findIndex((node) => node.type === 'triangle');
    const trapezoidIndex = updatedNodeData.findIndex((node) => node.type === 'trapezoid2');
  // Add the connection from Triangle to Trapezoid
if (triangleIndex !== -1 && trapezoidIndex !== -1) {
  edgesFromJson.push({
    id: `reactflow__edge-${updatedNodeData[triangleIndex].type}-${triangleIndex + 1}-${updatedNodeData[trapezoidIndex].type}-${trapezoidIndex + 1}`, // Generate unique ID for the edge
    source: `${updatedNodeData[triangleIndex].type}-${triangleIndex + 1}`,
    sourceHandle: 'no', // Specify the source handle ID
    target: `${updatedNodeData[trapezoidIndex].type}-${trapezoidIndex + 1}`,
    targetHandle: 'bottom', // Specify the target handle ID
  });
}

  
    // Set the nodes and edges state
    setNodes(createdNodes);
    setEdges(edgesFromJson);
  };
  















  // const addRectangle = () => {
  //   const rectangleData = nodeData.rectangle; // Access rectangle data from JSON
  //   const newRectangle = {
  //     id: `${nodes.length + 1}`,
  //     ...rectangleData, // Spread the remaining rectangle data
  //     position: { x: currentX + 100, y: 130 },
  //   };
  //   setNodes((prevNodes) => [...prevNodes, newRectangle]);
  //   setCurrentX((prevX) => prevX + 250); // Update the last x-coordinate
  // };
  const addRectangle = () => {
    // Find the object in the JSON array where type is "rectangle"
    const rectangleData = nodeData.find(node => node.type === "rectangle");
  
    // Check if the rectangleData is found
    if (rectangleData) {
      // Create a new rectangle node object using the data from rectangleData
      const newRectangle = {
        id: `${nodes.length + 1}`,
        ...rectangleData, // Spread the properties of rectangleData
        position: { x: currentX + 100, y: 130 },
      };
  
      // Update the nodes state with the new rectangle node
      setNodes(prevNodes => [...prevNodes, newRectangle]);
      
      // Update the last x-coordinate
      setCurrentX(prevX => prevX + 250);
    } else {
      console.error("Rectangle data not found in nodeData JSON.");
    }
  };
  


const addTriangle = () => {
    const triangleData = nodeData.find(node => node.type === "triangle");
    
    if (triangleData) {
        // Create a new triangle node object using the data from triangleData
        const newTriangle = {
            id: `${nodes.length + 1}`,
            ...triangleData, // Spread the remaining triangle data
            position: { x: currentX + 90, y: 100 },
        };
        
        // Add a row of data for the decision
        const decision1 = { ...newTriangle };
        
        // Update the position of the decision node
        decision1.position = { x: currentX + 120, y: 250 };
        
        // Update the table data with the new decision node
        const tableDataWithDecision = [...tableData, decision1];
        
        // Update the nodes state with the new triangle node
        setNodes(prevNodes => [...prevNodes, decision1]);
        
        // Update the last x-coordinate
        setCurrentX(prevX => prevX + 240);
        
        // Update table data
        setTableData(tableDataWithDecision);
    } else {
        console.error("Triangle data not found in nodeData JSON.");
    }
};










// const addTriangle = () => {
//   const triangleData = nodeData.find(node => node.type === "triangle");
//   const newTriangle = {
//     id: `${nodes.length + 1}`,
//     ...triangleData, // Spread the remaining triangle data
//     position: { x: currentX + 90, y: 100 },
//   };

//   // Add two rows of data for the decisions
//   const decision1 = { ...newTriangle } ;
//   const decision2 = nodeData.triangle2; // Use the data for the second decision node from the JSON
  
//   // Update the positions of the decision nodes
//   decision1.position = { x: currentX, y: 250 };
//   decision2.position = { x: currentX + 150, y: 250 }; 

//   // Update the table data with the new decision nodes
//   const tableDataWithDecisions = [...tableData, decision1, decision2];

//   setNodes((prevNodes) => [...prevNodes, decision1, decision2]);
//   setCurrentX((prevX) => prevX + 300); // Update the last x-coordinate
//   setTableData(tableDataWithDecisions); // Update table data
// };














const addTrapezoid = () => {
  const trapezoidData = nodeData.find(node => node.type === "trapezoid");
  
  if (trapezoidData) {
    const newValue = ""; // Set a default value
    const newTrapezoid = {
      id: `${nodes.length + 1}`,
      ...trapezoidData,
      position: { x: currentX + 50, y: 130 },
      data: {
        ...trapezoidData.data,
        value: newValue
      },
      currentprocessname: "", // Set currentprocessname to an empty string
    };
    
    setNodes(prevNodes => [...prevNodes, newTrapezoid]);
    console.log('Adding trapezoid:', newTrapezoid); // Log the new trapezoid2 node

    setCurrentX(prevX => prevX + 150); // Update the last x-coordinate
  } else {
    console.error("Trapezoid data not found in nodeData JSON.");
  }
};


const addTrapezoid2 = () => {
  const trapezoid2Data = nodeData.find(node => node.type === "trapezoid2");
  
  if (trapezoid2Data) {
      const newTrapezoid2 = {
          id: `${nodes.length + 1}`,
          ...trapezoid2Data,
          position: { x: 150, y: 200 },
      };
      
      setNodes(prevNodes => [...prevNodes, newTrapezoid2]);
  } else {
      console.error("Trapezoid 2 data not found in nodeData JSON.");
  }
};

const addParallelogram = () => {
  const parallelogramData = nodeData.find(node => node.type === "parallelogram");
  const startNodeExists = nodes.some(node => node.type === "parallelogram");

  if (parallelogramData && !startNodeExists) {
      const newParallelogram = {
          id: `${nodes.length + 1}`,
          ...parallelogramData,
          position: { x: currentX + 0, y: 130 },
      };

      setNodes(prevNodes => [...prevNodes, newParallelogram]);
      setCurrentX(prevX => prevX + 150); // Update the last x-coordinate
  } else if (startNodeExists) {
      console.error("Parallelogram node already exists.");
  } else {
      console.error("Parallelogram data not found in nodeData JSON.");
  }
};

const addCircle = () => {
  const circleData = nodeData.find(node => node.type === "circle");
  const endNodeExists = nodes.some(node => node.type === "circle");

  if (circleData && !endNodeExists) {
      const newCircle = {
          id: `${nodes.length + 1}`,
          ...circleData,
          position: { x: currentX + 0, y: 130 },
      };

      console.log('Adding circle:', newCircle); // Log the new circle node
      setNodes(prevNodes => [...prevNodes, newCircle]);
      setCurrentX(prevX => prevX + 100); // Update the last x-coordinate
  } else if (endNodeExists) {
      console.error("Circle node already exists.");
  } else {
      console.error("Circle data not found in nodeData JSON.");
  }
};

  
  const connectAllNodes = () => {
    const sortedNodes = [...nodes].sort((a, b) => a.CurrentProcessLevel - b.CurrentProcessLevel);
  
    // Reset the counter for generating unique edge IDs
    let edgeCounter = 1;
  
    // Connect nodes based on the sorted order
    for (let i = 0; i < sortedNodes.length - 1; i++) {
      const sourceNode = sortedNodes[i];
      const targetNode = sortedNodes[i + 1];
  
      // Generate the new edge ID by concatenating source and target node IDs
      const newEdgeId = `reactflow__edge-Node-${sourceNode.id}-Node-${targetNode.id}`;
  
      // Create the edge with the new ID
      const newEdge = {
        id: newEdgeId,
        source: sourceNode.id,
        target: targetNode.id,
        // style: connectorStyle, // Use the current connector style
      };
  
      // Update the edges state with the new edge
      setEdges(prevEdges => [...prevEdges, newEdge]);
  
      // Increment the edge counter
      edgeCounter++;
    }
  };
  
  // Function to update nodes data
  const updateNodesData = (newNodesData) => {
    setNodes(newNodesData);
    console.log('put that data...');
  };

  // Function to draw diagram
  const drawDiagram = () => {
    // Implement drawing logic using the nodes data
    // You can access the updated nodes data from the 'nodes' state
    // Use the data to draw the diagram
    console.log('Drawing diagram...');
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((prevNodes) => applyNodeChanges(changes, prevNodes)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((prevEdges) => applyEdgeChanges(changes, prevEdges)),
    []
  );

  // const onConnect = useCallback(
  //   (connection) => {
  //     const sourceNode = nodes.find(node => node.id === connection.source);
  //     const targetNode = nodes.find(node => node.id === connection.target);
  
  //     console.log('Source Node:', sourceNode);
  //     console.log('Target Node:', targetNode);
  
  //     // Generate a unique ID for the new edge
  //     const newEdgeId = `reactflow__edge-Node-${sourceNode.id}-Node-${targetNode.id}`;
  
  //     // Create the edge with the unique ID
  //     let newEdge;
  //     if (sourceNode.type === 'triangle' && targetNode.type === 'trapezoid') {
  //       newEdge = {
  //         id: newEdgeId,
  //         source: sourceNode.id,
  //         target: targetNode.id,
  //         connectorLabel: 'NO', // Set connector label to "NO" for triangle to trapezoid connection
  //       };
  //     } else {
  //       newEdge = {
  //         id: newEdgeId,
  //         source: sourceNode.id,
  //         target: targetNode.id,
  //       };
  //     }
  
  //     console.log('New Edge:', newEdge);
  
  //     // Update the edges state with the new edge
  //     setEdges(prevEdges => [...prevEdges, newEdge]);
  //   },
  //   [nodes]
  // );
  

  // Rest of your component...




  const toggleShowData = () => {
    setShowData((prevShowData) => !prevShowData);
  };
  const deleteSelectedNode = () => {
    if (!selectedNode) {
      alert("No node selected to delete.");
      return;
    }

    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== selectedNode.id));
    setSelectedNode(null); // Reset the selected node
  };

  const deleteSelectedEdge = () => {
    if (!selectedEdge) {
      alert("No edge selected to delete.");
      return;
    }

    setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== selectedEdge.id));
    setSelectedEdge(null); // Reset the selected edge
  };

  const removeLastNode = () => {
    if (nodes.length === 0) {
      alert("No nodes to remove.");
      return;
    }

    const lastNode = nodes[nodes.length - 1];
    setNodes((prevNodes) => prevNodes.slice(0, -1));
    setRemovedNodes((prevRemovedNodes) => [...prevRemovedNodes, lastNode]);
  };

  const addLastRemovedNode = () => {
    if (removedNodes.length === 0) {
      alert("No nodes to redo.");
      return;
    }

    const lastRemovedNode = removedNodes[removedNodes.length - 1];
    setNodes((prevNodes) => [...prevNodes, lastRemovedNode]);
    setRemovedNodes((prevRemovedNodes) => prevRemovedNodes.slice(0, -1));
  };

  const removeLastEdge = () => {
    if (edges.length === 0) {
      alert("No edges to remove.");
      return;
    }

    const lastEdge = edges[edges.length - 1];
    setEdges((prevEdges) => prevEdges.slice(0, -1));
    setRemovedEdges((prevRemovedEdges) => [...prevRemovedEdges, lastEdge]);
  };

  const addLastConnector = () => {
    if (removedEdges.length === 0) {
      alert("No connectors to add.");
      return;
    }

    const lastRemovedEdge = removedEdges[removedEdges.length - 1];
    setEdges((prevEdges) => [...prevEdges, lastRemovedEdge]);
    setRemovedEdges((prevRemovedEdges) => prevRemovedEdges.slice(0, -1));
  };
  const autoArrangeHorizontal = () => {
    const gridGap = 100;
    const nodeSize = 100;
    const numColumns = Math.floor(Math.sqrt(nodes.length));
  
    const newNodes = nodes.map((node, index) => {
      const row = isHorizontal ? 0 : Math.floor(index / numColumns);
      const column = isHorizontal ? Math.floor(index / numColumns) : index % numColumns;
  
      return {
        ...node,
        position: {
          x: isHorizontal ? index * (nodeSize + gridGap) : column * (nodeSize + gridGap),
          y: isHorizontal ? row * (nodeSize + gridGap) : index * (nodeSize + gridGap),
        },
      };
    });
  
    setNodes(newNodes);
    setIsHorizontal(prev => !prev); // Toggle the arrangement direction
  };
  

  return (
    <div>
      <svg style={{ position: 'absolute', top: 0, left: 0 }}>
        {/* Define arrowhead marker */}
        <defs>
          <marker
            id="arrowhead"
            viewBox="0 0 10 10"
            refX="3.7"
            refY="2.5"
            markerWidth="2"
            markerHeight="2"
            orient="auto"
          >
            <path d="M 0 0 L 4 2.5 L 0 5 Z" fill="#f6ab6c" />
          </marker>
        </defs>
      </svg>
      <svg style={{ position: 'absolute', top: 0, left: 0 }}>
        <EdgeStyle color="#FF0076" strokeWidth={2} /> {/* Use the EdgeStyle component */}
      </svg>

      <HorizontalBar />
      <VerticalBar
        onAddStartNode={addParallelogram}
        onAddHumanTask={addTrapezoid}
        onAddHumanTask2={addTrapezoid}

        onAddTriangle={addTriangle}
        onAddAutomation={addRectangle}
        onAddEnd={addCircle}
      />
      {/* <button onClick={createTableTemplate}>Table Template</button> */}

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeClick={(_, edge) => setSelectedEdge(edge)}
        onNodeClick={(_, node) => setSelectedNode(node)}
        nodeTypes={nodeTypes}
        style={{ ...rfStyle, ...connectorStyle }} // Here's the style prop

      />
      <Table nodeData={nodeData} newData={newData} />

      <div className="button-container" style={{ position: 'absolute', top: '450px', left: '100px' }}>
        <button onClick={autoArrangeHorizontal}>Auto Arrange</button>
        <button onClick={deleteSelectedNode}>
          <FontAwesomeIcon icon={faTrash} /> Node
        </button>
        <button onClick={removeLastNode}>
          <FontAwesomeIcon icon={faUndo} /> Node
        </button>
        <button onClick={addLastRemovedNode}>
          <FontAwesomeIcon icon={faRedo} /> Node
        </button>
        <button onClick={createTableTemplate}>Table Template</button>

        <button onClick={addParallelogram}>START</button>
        <button onClick={addTrapezoid}>HumanTask</button>
        <button onClick={addTriangle}>Decision</button>
        <button onClick={addTrapezoid2}>HumanTask 2</button>

        <button onClick={addRectangle}>Automation</button>
        <button onClick={addCircle}>END</button>
        <button onClick={drawDiagram}>Draw Diagram</button>
        <button onClick={() => updateNodesData(nodes)}>Update Nodes Data</button>
     
        <button onClick={deleteSelectedEdge}>
          <FontAwesomeIcon icon={faTrash} /> Edge
        </button>
        <button onClick={removeLastEdge}>
          <FontAwesomeIcon icon={faUndo} /> Connector
        </button>
        <button onClick={addLastConnector}>
          <FontAwesomeIcon icon={faRedo} /> REDO Connector
        </button>
        <button onClick={connectAllNodes}>Connect All Boxes</button>
        <button onClick={toggleShowData}>{showData ? 'Hide Data' : 'Show Data'}</button>

      </div>
      {/* <NavigationBar levels={levels} /> */}

      {showData && (
        <textarea
          value={JSON.stringify({ nodes, edges }, null, 2)}
          readOnly
          style={{ position: 'absolute', top: '500px', left: '100px', width: '300px', height: '200px' }}
        />
      )}

    </div>
    
  );
  
}

ShapeFlow.propTypes = {
  initialNodes: PropTypes.array,
  initialEdges: PropTypes.array,
};

export default ShapeFlow;
