
import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import CompanyListing from "./CompanyListing";
import CompanyLocation from "./CompanyLocation";
 
export default function DetailPanel({ searchResults, locationResults, handleAddRow, headerData, setHeaderData, setbodyDataEdit, updateEditData, setEditData}) {
  const [expanded, setExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(); // Default to first tab
  const [currentEnterpriseUid, setCurrentEnterpriseUid] = useState();
  const [currentEnterpriseName, setCurrentEnterpriseName] = useState();
  console.log("currentEnterpriseUid in CompanyDetailPanel", currentEnterpriseUid);
 
  useEffect(() => {
    // Expand Accordion when selectedTab is active
    if (selectedTab !== undefined) {
      setExpanded(true);
    }
    else {
      setExpanded(false);
    }
  }, [selectedTab]);
 
  useEffect(() => {
    // Expand Accordion when searchResults are present
    if (searchResults && searchResults.length > 0) {
      setExpanded(true);
      setSelectedTab(0);
    } else {
      setExpanded(false);
    }
  }, [searchResults]);
 
 
 
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };
 
  const handleTabClick = (index) => {
    setSelectedTab(index);
  };
 
  const tabs = [
    {
      label: "Company Listing",
      content: locationResults && <CompanyListing searchResults={searchResults} setCurrentEnterpriseUid={setCurrentEnterpriseUid} setCurrentEnterpriseName={setCurrentEnterpriseName} />,
    },
    {
      label: "Locations and Departments",
      content: locationResults && <CompanyLocation currentEnterpriseUid={currentEnterpriseUid} currentEnterpriseName={currentEnterpriseName} locationResults={locationResults} handleAddRow={handleAddRow} headerData={headerData} setHeaderData={setHeaderData} setbodyDataEdit={setbodyDataEdit} updateEditData={updateEditData} setEditData={setEditData} />,
    },
  ];
 
  return (
    <div>
      <Accordion expanded={expanded} onChange={toggleAccordion}>
        <AccordionSummary
          sx={{ backgroundColor: "#34B5F4" }}
          expandIcon={expanded ? <Remove /> : <Add />}
        >
          <Typography variant="h7" sx={{ flexGrow: "1", textAlign: "center" }}>
            {tabs.map((tab, index) => (
              <Button
                key={index}
                onClick={() => handleTabClick(index)}
                style={{
                  borderRadius: 0,
                  borderBottom: `4px solid ${
                    selectedTab === index ? "#4374aa" : "transparent"
                  }`,
                  backgroundColor:
                    selectedTab === index ? "#f0f0f0" : "transparent",
                  color: selectedTab === index ? "#4374aa" : "#333",
                  padding: "10px 20px",
                  fontWeight: 600,
                  flex: 1,
                  transition: "all 0.3s",
                  boxShadow:
                    selectedTab === index
                      ? "0 0 10px rgba(0, 0, 0, 0.1)"
                      : "none",
                }}
              >
                {tab.label}
              </Button>
            ))}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: "20px" }}>
            {tabs.map((tab, index) => (
              <div
                key={index}
                style={{ display: selectedTab === index ? "block" : "none" }}
              >
                <Typography variant="body2" gutterBottom>
                  {tab.content}
                </Typography>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
 