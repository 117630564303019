import React, { useRef, useEffect, useState } from "react";
import logo from "../../images/logo.png";
import Card from "@mui/material/Card";
import * as Validate from "./validation";
import { useNavigate } from "react-router-dom";
import { Button, Typography, TextField } from "@mui/material";
import "../App.css";
import * as CryptoJS from "crypto-js";
import { authenticateStdUser } from "../action/user";
import {useLocation } from "react-router-dom";


export default function WelcomeScreeen() {
  const [email, setEmail] = useState(" ");
  const [userid, setUserid] = useState(" ");
  const [newpassword, setNewpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(" ");
  const [passError, setPassError] = useState(" ");
  const [loginStatus, setLoginStatus] = useState(" ");
  const [data, setData] = useState({});
  const [resultspayload, setResultspayload] = useState({});
  const [origpassword, setOrigpassword] = useState(" ");

  

  const navigate = useNavigate();
  const location = useLocation();

  // setUrlemail(queryParameters.get("user"));

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    console.log("queryParameters" + queryParameters);
    const useriddata = queryParameters.get("data");
    const passdata = queryParameters.get("tempd");
    const dboperation = "VALIDATEFIRST";
    const origpassword = undefined;
    
    // console.log("tempd"  + passdata)
    //const decDataUserID = CryptoJS.enc.Base64.parse(queryParameters.get("data")).toString(CryptoJS.enc.Utf8);
    // const decDataPass = CryptoJS.enc.Base64.parse(queryParameters.get("tempd")).toString(CryptoJS.enc.Utf8);
    // console.log("decData"  + decDataUserID + decDataPass)
    //   let bytes = CryptoJS.AES.decrypt(
    //     decData.toString(),
    //     "my-secret-key@rcube"
    //   );
    //   let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    //   let jsonObject = JSON.parse(decryptedData);
    //  let lindata = JSON.parse(jsonObject);
    //   console.log("decryptedData ##########   :: " + JSON.stringify(lindata));

    // let userid = lindata["userId"];
    // let password = lindata["passowrd"];

    // console.log("Userid " + userid + "password"  + password);

    // var UserData = {
    //   userId:  lindata["userId"],
    //   password: lindata["passowrd"],
    // };

    authenticateStdUser(useriddata, passdata, dboperation, origpassword)
      .then((response) => {
        let data = JSON.parse(response.data);
        setData(data);
        let resultspayload = data["resultspayload"][0];
        let origpassword = data["origpassword"];
        setOrigpassword(origpassword);
        setResultspayload(resultspayload);
        console.log(resultspayload);
       

        // resultspayload.forEach((jsonObj, i) => {
        //   let payloadArray = jsonObj["enterpriseorgname"];
        //   console.log( "jsonObj" + payloadArray)
        // })

        // navigate('/layouts/');
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

console.log("origpassword " + origpassword)



  const welcomeIn = () => {
    if (confirmPassword !== newpassword) {
      alert("Passwords does not match");
  
    }else {
      var useridEncrypt = CryptoJS.AES.encrypt(JSON.stringify(resultspayload["userid"]), "my-secret-key@rcube");
      var PassNewEncrypt = CryptoJS.AES.encrypt(JSON.stringify(newpassword), "my-secret-key@rcube");
     

      const useriddata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(useridEncrypt));
      const  passdata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(PassNewEncrypt));
      const dboperation= "VALIDATEUSERRESET";


authenticateStdUser(useriddata, passdata,dboperation, origpassword )
.then((response) => {
  let data = response.data;
  if (data === "Success"){
    navigate('/login/');
  }
  setLoginStatus(response.data);


  // navigate('/layouts/');
})
.catch((error) => {
  console.log(error);
});



    }
    

    
  };

  return (
    <div>
      <div className="loginContainer">
        <img src={logo} style={{ height: "45px", width: "45px" }} />
      </div>
      <Card
        sx={{
          variant: "outlined",
          width: 250,
          padding: 3,
          alignItems: "center",
          marginLeft: "450px",
          marginTop: "30px",
        }}
      >
        <Typography
          sx={{ mb: "30px" }}
          component="h2"
          variant="h5"
          color="primary"
          gutterBottom
        >
          Welcome
        </Typography>
        <TextField
          InputLabelProps={{ shrink: true }}
          helperText={emailError}
          id="company"
          label="Company"
          fullWidth={true}
          // value={queryParameters ? queryParameters.get("user") : { email }}
          value={resultspayload["enterpriseorgname"]}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          id="username"
          label="Username"
          helperText={emailError}
          fullWidth={true}
          value={resultspayload["username"]}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          id="user-Id"
          label="UserId"
          helperText={emailError}
          fullWidth={true}
          value={resultspayload["userid"]}
          onChange={(e) => {
            setUserid(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          id="login-email"
          label="Email"
          helperText={emailError}
          fullWidth={true}
          value={resultspayload["useremailid"]}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          id="login-password"
          label="New Password"
          margin="dense"
          type="password"
          fullWidth={true}
          helperText={passError}
          value={newpassword}
          onChange={(e) => {
            setNewpassword(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          id="reconfirm-password"
          label="Confirm Password"
          margin="dense"
          type="password"
          fullWidth={true}
          helperText={passError}
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <div className="formAction">
          <Button
            variant="contained"
            alignContent="center"
            color="primary"
            justifyContent="center"
            onClick={welcomeIn}
          >
            Login
          </Button>
        </div>
        &nbsp;
        <Typography variant="caption" display="block" color="red" gutterBottom>
          {/* <ReportProblemIcon sx={{ color: "red" }}/> */}
          {loginStatus}
        </Typography>
        {/* </form> */}
        {/* <ReCaptcha
              sitekey="6Ld9QrIUAAAAAB_xfHFvlFrKKlq_-xc-hw7TkKuf"
              action="login"
              verifyCallback={response => {
                if (response) {
                  this.setState({ is_capcha: true });
                }
              }}
            /> */}
        {/* <div>
          <table>
            <tr>
              <th>
                <Link
                  sx={{ fontSize: "10px" }}
                  underline="hover"
                  marginTop="20px"
                  color="red"
                  href="/"
                >
                  forget your username{" "}
                </Link>
              </th>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <th>
                <Link
                  sx={{ fontSize: "10px" }}
                  underline="hover"
                  marginTop="20px"
                  color="red"
                  href="/"
                >
                  forget your password{" "}
                </Link>
              </th>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <th>
                <Link
                  sx={{ fontSize: "10px" }}
                  underline="hover"
                  marginTop="20px"
                  color="red"
                  href="/"
                >
                  Reset your password{" "}
                </Link>
              </th>
            </tr>
       
          </table>
        </div> */}
      </Card>
    </div>
  );
}
