// EdgeStyle.js

import React from 'react';
import PropTypes from 'prop-types';

const EdgeStyle = ({ color, strokeWidth }) => {
  return (
    <defs>
      {/* Define arrowhead marker */}
      <marker
        id="arrowhead"
        viewBox="0 0 10 10"
        refX="3.7"
        refY="2.5"
        markerWidth="2"
        markerHeight="2"
        orient="auto"
      >
        <path d="M 0 0 L 4 2.5 L 0 5 Z" fill={color} />
      </marker>

      {/* Define edge style */}
      <style>
        {`
          .react-flow__edge-path {
            stroke: ${color};
            stroke-width: ${strokeWidth}px;
            fill: none;
          }
        `}
      </style>
    </defs>
  );
};

EdgeStyle.propTypes = {
  color: PropTypes.string.isRequired,
  strokeWidth: PropTypes.number.isRequired,
};

export default EdgeStyle;
