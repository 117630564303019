import { useCallback, useState } from 'react';
import { Handle, Position } from 'reactflow';
import Truefalse from '../Truefalse.svg'; // Import the Truefalse.svg
import './Triangle.css'; // Import the CSS file
import DataDisplay from './DataDisplay'; // Import the DataDisplay component

function Triangle({ data }) {
  const [showPopup, setShowPopup] = useState(false);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const handleClick = () => {
    setShowPopup(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };

  const handleStyles = {
    left: { position: 'absolute', top: '-5px', left: '-2%', transform: 'translateX(-50%)' },
    right: { position: 'absolute', right: '65px', top: '100%', transform: 'translateY(-50%)' },
    top: { position: 'absolute', left: '65px', top: '-2%', transform: 'translateY(-60%)' },
    
  };

  return (
    <div className="triangle-up" onClick={handleClick}>
      <Handle type="source" position={Position.Top} id="top" className="handle-top-unique" style={handleStyles.top}  />
      <Handle type="source" position={Position.Right} id="no" className="handle-right-unique" style={handleStyles.right} />
      <Handle type="target" position={Position.Left} id="YES" className="handle-left-unique" style={handleStyles.left} />

      <div>
      {/* SVG Example */}
<svg width="100" height="60" xmlns="http://www.w3.org/2000/svg">
  <image href={Truefalse} width="98" height="57" transform="translate(-10, 8) rotate(315 50 28.5)" />
</svg>



        {data.btn}
        {data?.btnArray?.map((item) => (
          <button key={item}>{item}</button>
        ))}
      </div>
      <DataDisplay value={data.value}  />

      {showPopup && (
        <div className="popup">
          <button onClick={handleClose}>X</button>
          <h3>{data.discription}</h3>
        </div>
      )}
    </div>
  );
}

export default Triangle;
