import axios from "axios"
import * as q from "q"

export function getbrisquepanellisting(selectedUid, brisquePanelName) {
    var defer = q.defer()
    var url = process.env.REACT_APP_BASEURL + "brisquePanelListing";
    // var url = "http://localhost:3100/brisquePanelListing"
    //console.log("selectedUid:", selectedUid)
    axios
        .post(url, {
            selectedUid: selectedUid,
            brisquePanelName: brisquePanelName,
        })
        .then(function (response) {
            defer.resolve(response)
            //console.log("Response from server:", response)
        })
        .catch(function (error) {
            defer.reject(error)
        })
    return defer.promise
}

export function putbrisquepanel(editedData) {
    var defer = q.defer()
    var url = process.env.REACT_APP_BASEURL + "putbrisquepanel";
    // var url = "http://localhost:3100/putbrisquepanel"
    console.log("editedData:", editedData)
    axios
        .post(url, {
            editedDataBody: editedData,
        })
        .then(function (response) {
            // Check if the response contains a message
            if (response.data && response.data.message) {
                defer.resolve(response.data.message)
            } else {
                // If no message is found, reject with an error
                defer.reject(new Error("No message found in response"))
            }
        })
        .catch(function (error) {
            defer.reject(error)
        })
    return defer.promise
}