import * as React from "react";
import {useEffect} from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import RightPanel from "./RightPanel";
import RightPanelBlank from "./RightPanelBlank";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("span")(({ theme }) => ({
  marginTop: "60px",
  height: "37px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function RightContent({ onQueryRC, selectedRowId, sesssionData}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  // const [sesssionData, setSesssionData] = React.useState({});
  
  // useEffect(() => {
  //    let data  = window.sessionStorage.getItem('userDetails')
  //    let JsonData = JSON.parse(data.toString());
  //    setSesssionData(JsonData);
  // }, []) ;

  let userUid = sesssionData["useruid"];
  let userName = sesssionData["username"];
 
console.log("userUid" + userUid)

  const handleDrawerHandle = () => {
    setOpen(!open);
    onQueryRC(open);
  };


 

  return (
    <div>
      <Drawer variant="permanent" anchor="right" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerHandle}>
            {open ? (
              <KeyboardDoubleArrowRightIcon color="action" />
            ) : (
              <KeyboardDoubleArrowLeftIcon color="action" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box>
          {open ? (
            <RightPanel selectedRowId={selectedRowId}  userUid={userUid} userName={userName}/>
          ) : (
            <RightPanelBlank />
          )}
        </Box>

      </Drawer>
    </div>
  );
}