import { useCallback,useState } from 'react';
import { Handle, Position } from 'reactflow';
import DataDisplay from './DataDisplay'; // Import the DataDisplay component
 
import Cross from '../cross.svg'; // Import the Farrow.svg
const handleStyle = { left: 10 };

function Circle({ data }) {

  const [showPopup, setShowPopup] = useState(false);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const handleClick = () => {
    setShowPopup(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };
  return (
    <div className="circle" onClick={handleClick}>
      <Handle type="target" position={Position.Top} />
      <svg width="100" height="60" xmlns="http://www.w3.org/2000/svg">
          <image href={Cross} width="150" height="50"  />
        </svg>

        <DataDisplay value={data.value} /> {/* Use the DataDisplay component */}

      <div>
  
      <p>{data.text}</p>
        <p>{data.description}</p> {/* Display the description here */}
        {
          data.btn 
        }
        {
          data?.btnArray?.map((item)=>
          <button>{item}</button>
          )
        }
        {/* <label htmlFor="text">Text:</label> */}
        {/* <input id="text" name="text" onChange={onChange} /> */}
      </div>
      {/* <Handle type="source" position={Position.Bottom} id="a" style={handleStyle} /> */}
      {/* <Handle type="source" position={Position.Bottom} id="b" /> */}
      {showPopup && 
        <div className="popup">
          <button onClick={handleClose}>X</button>
          
          <h3>{data.discription}</h3> {/* Display the description in the popup */}
        </div>
      }
    </div>
  );
}

export default Circle;