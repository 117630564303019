import React from 'react';

function NavigationBar({ levels }) {
  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
      {/* First level buttons */}
      {levels.map((level, index) => (
        <div key={index} style={{ display: 'inline-block', marginRight: '20px' }}>
          <button>{level.label}</button>
          {/* Second level buttons */}
          {level.buttons && (
            <div style={{ position: 'absolute', top: '40px', backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
              {level.buttons.map((button, btnIndex) => (
                <button key={btnIndex}>{button}</button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default NavigationBar;
