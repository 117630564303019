import React, { useState, useEffect } from "react"
import { styled, useTheme, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import logo from "../../images/logo.png";
import productLogo from "../../images/productLogo.png";
import IconButton from "@mui/material/IconButton";
import SideBar from "./SideBar";
import AppsIcon from "@mui/icons-material/Apps";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: "105px",
  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function Header({ onQuery, breadCrumbsData, leftNavigationSelectedScreenUid, leftNavigationSelectedScreenName,sesssionData }) {
  const [open, setOpen] = useState(true);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [FirstLetter, setFirstLetter] =useState(null);


  useEffect(() => {
    let data  = window.sessionStorage.getItem('userDetails')
     let sesssionData = JSON.parse(data.toString());
    var name = sesssionData["username"]
    console.log("name" + name);
    if(name){
     let final = name.slice(0, 1);
      setFirstLetter(final);
    }
    else{
      // let name = sesssionData["username"];
      let name = "Brisque";
      let final = name.slice(0, 1);
      setFirstLetter(final);
      }
      
   
}, [])

  

  const toggleDrawer = () => {
    setOpen(!open);
    onQuery(open);
  };
  const handleBreadCrumbs = (event) => {
    breadCrumbsData(event);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    if ("Logout") {
      localStorage.clear();
      window.location.href = "/";
    }
  };

  return (
    <Box sx={{ flexGrow: 1, marginleft: "350px" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          background: "#c44198",
          height: "48px",
        }}
        elevation={1}
      >
        <Toolbar>
          <Typography
            sx={{
              mr: 1,
              display: { xs: "flex", sm: "block", md: "flex" },
              paddingTop: "3px",
              width: "115px",
              height: "65px",
            }}
          >
            <img style={{ height: "45px", width: "45px" }} src={logo} />
          </Typography>

          <AppsIcon
            sx={{
              paddingBottom: "15px",
              marginRight: "280px",
              width: "45px",
              height: "45px",
            }}
            edge="start"
            color="inherit"
            aria-label="menu"
            // onClick={(e) => {
            //   toggleDrawer(true);
            // }}
            onClick={toggleDrawer}
          >
            \
          </AppsIcon>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", sm: "flex" } }}
          >
            Product-Brisque
          </Typography>
          <Search
            sx={{
              marginBottom: "15px",
              marginRight: "115px",
            }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <Typography
            sx={{
              paddingBottom: "-6px",
              marginBottom: "12px",
              marginLeft: "150px",
            }}
          >
            <img style={{ height: "43px", width: "150px" }} src={productLogo} />
          </Typography>

          <Box sx={{ paddingBottom: "15px" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{FirstLetter}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <SideBar openstate={open} onScreenNameSelect={leftNavigationSelectedScreenName} onScreenUidSelect={leftNavigationSelectedScreenUid} onSelectBreaCrumbs={handleBreadCrumbs} />
    </Box>
  );
}
