import axios from "axios"
import * as q from "q"
 
export function getfeaturescreenforsearch(brisquePanelName) {
    var defer = q.defer()
    var url = process.env.REACT_APP_BASEURL + "featureScreenForSearch";
    // var url = "http://localhost:3100/featureScreenForSearch"
 
    //console.log("brisquePanelName:", brisquePanelName)
    axios
        .post(url, {
            brisquePanelName: brisquePanelName,
        })
        .then(function (response) {
            defer.resolve(response)
        })
        .catch(function (error) {
            defer.reject(error)
        })
 
    return defer.promise
}