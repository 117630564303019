
import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import UserListing from "./UserListing";
import SelectLocation from "./PopUp";
 
export default function DetailPanel({ 
  selectedUid,
  handleAddRow,
  setHeaderData,
  headerData,
  setBodyData,
  bodyData,
  seteditData,
  setbodyDataEdit,
  UpdatedEditData,
  //  Faize Added
  onRowSelect,
  onRowSelectNotes,
 readbodysearchType,
 filterCriterea,
 sesssionData,
 searchResults,
 enterpriseData
}) {
  const [expanded, setExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0); // Default to first tab
  const [currentEnterpriseUid, setCurrentEnterpriseUid] = useState();
  const [currentEnterpriseName, setCurrentEnterpriseName] = useState();
  const [CommentButton, setCommentButton] = useState("CommentButton");
  const [TodoButton, setTodoButton] = useState("TodoButton");
  
  // const [departmentLocation, setDepartmentLocation] = useState([]);
  const [selectedLocationID, setSelectedLocationID] = useState();
  console.log("currentEnterpriseUid in CompanyDetailPanel", currentEnterpriseUid);
 
  useEffect(() => {
    // Expand Accordion when selectedTab is active
    if (selectedTab !== undefined) {
      setExpanded(true);
    }
    else {
      setExpanded(false);
    }
  }, [selectedTab]);
 
  useEffect(() => {
    // Expand Accordion when searchResults are present
    if (searchResults && searchResults.length > 0) {
      setExpanded(true);
      setSelectedTab(0);
    } else {
      setExpanded(false);
    }
  }, [searchResults]);
 
 
 
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };
 

  const handleTabClick = (index) => {
    setSelectedTab(index);
    if (index === 0) {
      setTodoButton("TodoButton");
     
      if (CommentButton !== "CommentButton") setCommentButton("CommentButton");
      else setCommentButton("CommentButton1");

      // if(CommentButton !== "CommentButton"){setCommentButton("CommentButton") }
      // else {setCommentButton("CommentButton1");}
    } else if (index === 1) {
      setCommentButton("TodoButton");
    
      if (TodoButton !== "TodoButton") setTodoButton("TodoButton");
      else setTodoButton("TodoButton1");

      // setTodoButton("TodoButton1")
    }
  };

  const tabs = [
    {
      label: "Location",
      content:  <SelectLocation 
        currentEnterpriseUid={enterpriseData} 
        searchResults={searchResults} 
        setSelectedLocationID={setSelectedLocationID}/>,
    },
    {
      label: "User List",
      content:  <UserListing 
                    sesssionData={sesssionData}
                    selectedUid={selectedUid} ///from topbox to details
                    handleAddRow={handleAddRow} //add row to edit panel
                    setHeaderData={setHeaderData} // to set header for edit panel
                    headerData={headerData} //header for  panel
                    setBodyData={setBodyData} //to set body data from api
                    readbodysearchType={readbodysearchType}
                    bodyData={bodyData} //body data from api
                    filterCriterea={filterCriterea} //body data from api
                    seteditData={seteditData} //to set selected data
                    setbodyDataEdit={setbodyDataEdit} //to set the changes in body data of edit panel
                    UpdatedEditData={UpdatedEditData} //updated data from edit to top to detail
                   
                    //faize
                    onRowSelect={onRowSelect}
                    onRowSelectNotes={onRowSelectNotes}
                    selectedLocationID={selectedLocationID}
      />,
    },
   
  ];
 


  return (
    <div>
      <Accordion expanded={expanded} onChange={toggleAccordion}>
        <AccordionSummary
          sx={{ backgroundColor: "#34B5F4" }}
          expandIcon={expanded ? <Remove /> : <Add />}
        >
          <Typography variant="h7" sx={{ flexGrow: "1", textAlign: "left" }}>
          {/* {tabs.map((tab, index) => (
              <Button
                key={index}
                onClick={() => handleTabClick(index)}
                style={{
                  borderRadius: 0,
                  borderBottom: `4px solid ${
                    selectedTab === index ? "#4374aa" : "transparent"
                  }`,
                  backgroundColor:
                    selectedTab === index ? "#f0f0f0" : "transparent",
                  color: selectedTab === index ? "#4374aa" : "#333",
                  padding: "10px 20px",
                  fontWeight: 600,
                  flex: 1,
                  transition: "all 0.3s",
                  boxShadow:
                    selectedTab === index
                      ? "0 0 10px rgba(0, 0, 0, 0.1)"
                      : "none",
                }}
              >
                {tab.label}
              </Button>
            ))} */}

            <Button
              className={CommentButton}
              key={1}
              onClick={() => handleTabClick(0)}
              style={{
                padding: "10px 10px",
                borderRadius: "15px 10px 0px 0px",
                border: `2px solid ${
                  selectedTab === 0 ? "#4374aa" : "transparent"
                }`,
                backgroundColor: "#C1E1C1",
                color: selectedTab === 0 ? "#4374aa" : "#333",
                fontWeight: 400,
                right: "0px",
                width: "90px",
                // marginRight:"10px",
                transition: "all 0.3s",
                boxShadow:
                  selectedTab === 0 ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
              }}
            >
              {"Location"}
            </Button>
            <Button
              className={TodoButton}
              key={2}
              onClick={() => handleTabClick(1)}
              style={{
                padding: "10px 10px",
                borderRadius: "10px 15px 0px 0px",
                border: `2px solid ${
                  selectedTab === 1 ? "#4374aa" : "transparent"
                }`,
                backgroundColor: "#A7C7E7",
                color: selectedTab === 1 ? "#4374aa" : "#333",
                fontWeight: 400,
                right: "13px",
                width: "90px",
                transition: "all 0.3s",
                boxShadow:
                  selectedTab === 1 ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
              }}
            >
              {"UserList"}
            </Button>
           
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ marginTop: "20px" }}>
            {tabs.map((tab, index) => (
              <div
                key={index}
                style={{ display: selectedTab === index ? "block" : "none" }}
              >
                <Typography variant="body2" gutterBottom>
                  {tab.content}
                </Typography>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
 