import React from 'react';
import './Popup.css'

const RegistrationForm = () => {
  return (
    <div className="container">
      <h1>Registration</h1>
      <form>
        <div className="mb-4">
          <label htmlFor="name" className="form-label">
            Name *
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Enter your name"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="messageActions" className="form-label">
            Message Actions
          </label>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={() => {
              // Add message action logic here
            }}
          >
            + Add Message Action
          </button>
        </div>
        <div className="mb-4">
          <label htmlFor="messageActionName" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            id="messageActionName"
            placeholder="Enter message action name"
          />
          <small className="form-text text-muted">No Results</small>
        </div>
        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-secondary me-2">
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
