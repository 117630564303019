// BlueButton.js
import React from 'react';

const BlueButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        position: 'absolute',
        top: '-50px',
        left: '10px',
        fontSize: '14px',
        padding: '8px 16px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      Blue
    </button>
  );
};

export default BlueButton;
