
import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import AddNewCompany from "./AddNewCompany";
import { getBrisqueOrganizationDetails as getBrisqueOrganizationDetailsApi } from "../../../action/OrganizationDetailsAction";
function SearchPanel({ setSearchResults, setLocationResults }) {
  const [expanded, setExpanded] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    contactName: "",
    organizationName: "",
    searchOperator: "", // Added searchOperator field
  });
  const [selectedType, setSelectedType] = useState("");
  const [addFormOpen, setAddFormOpen] = useState(false);
  const tabs = [
    "Organization Name",
    "Search Operator",
    "Main Contact Full Name",
  ]; // Updated tabs
 
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };
 
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
 
  const handleSearch = async () => {
    try {
      const { searchOperator, organizationName, contactName } = searchCriteria;
      console.log("searchCriteria", searchCriteria, selectedType);
      const response = await getBrisqueOrganizationDetailsApi(
        selectedType,
        searchOperator,
        organizationName,
        contactName
      ); // Call API function
     
      if (response.data !== null) {
        setSearchResults(response.data);
      
      } // Update search results state with API response
      else {
        setSearchResults([]);
      }
      handleClearSearch();
    } catch (error) {
      console.error("Error fetching Org data:", error);
    }
  };


 
  useEffect(() => {
    handleSearch(); // Fetch organization data when the component mounts
  }, []);
 
  const handleClearSearch = () => {
    setSearchCriteria({
      contactName: "",
      organizationName: "",
      searchOperator: "",
    });
    setSelectedType("");
  };
 
  const handleAddFormOpen = () => {
    setAddFormOpen(true);
  };
 
  const handleAddFormClose = () => {
    setAddFormOpen(false);
  };
 
  return (
    <div className="SearchOrganization">
      <Accordion expanded={expanded} onChange={toggleAccordion}>
        <AccordionSummary
          sx={{ backgroundColor: "#c4bcbc" }}
          expandIcon={expanded ? <Remove /> : <Add />}
        >
          <Typography variant="h7" sx={{ flexGrow: "1", textAlign: "center" }}>
            Search Organization or Location{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: "100%" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Search Mode</TableCell>
                    {tabs.map((key, index) => (
                      <TableCell key={index}>{key}</TableCell>
                    ))}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <FormControl>
                        <RadioGroup
                          aria-label="searchType"
                          name="searchType"
                          value={selectedType}
                          onChange={handleTypeChange}
                        >
                          <FormControlLabel
                            value="wildcard"
                            control={<Radio />}
                            label="Wildcard"
                          />
                          <FormControlLabel
                            value="exact"
                            control={<Radio />}
                            label="Exact"
                          />
                        </RadioGroup>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ width: "100%" }}
                        id={`organization-name`}
                        label="Organization Name"
                        variant="outlined"
                        size="small"
                        value={searchCriteria.organizationName}
                        onChange={(e) =>
                          setSearchCriteria({
                            ...searchCriteria,
                            organizationName: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        value={searchCriteria.searchOperator}
                        onChange={(e) =>
                          setSearchCriteria({
                            ...searchCriteria,
                            searchOperator: e.target.value,
                          })
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Search Operator" }}
                      >
                        <MenuItem value="" disabled>
                          Search Operator
                        </MenuItem>
                        <MenuItem value="AND">AND</MenuItem>
                        <MenuItem value="OR">OR</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ width: "100%" }}
                        id={`contact-name`}
                        label="Contact Name"
                        variant="outlined"
                        size="small"
                        value={searchCriteria.contactName}
                        onChange={(e) =>
                          setSearchCriteria({
                            ...searchCriteria,
                            contactName: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{ mx: 1 }}
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                variant="contained"
                sx={{ mx: 1 }}
                color="primary"
                onClick={handleClearSearch}
              >
                Clear Search
              </Button>
              <Button
                variant="contained"
                sx={{ mx: 1 }}
                color="primary"
                onClick={handleAddFormOpen}
              >
                Add New
              </Button>
            </Box>
 
            {/* AddNewCompany component */}
            <AddNewCompany open={addFormOpen} onClose={handleAddFormClose} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
 
export default SearchPanel;
 