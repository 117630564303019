import React from 'react';

function DataDisplay({ value }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: '5px'}}>
      <p style={{ margin: '0' }}>{value}</p>
    </div>
  );
}

export default DataDisplay;
