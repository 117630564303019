import React, { useCallback, useState } from 'react';
import { Handle, Position } from 'reactflow';
import Farrow from '../Farrow.svg'; // Import the Farrow.svg
import DataDisplay from './DataDisplay'; // Import the DataDisplay component

const handleStyle = { left: 10 };

function Parallelogram({ data }) {
  const [showPopup, setShowPopup] = useState(false);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const handleClick = () => {
    setShowPopup(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setShowPopup(false);
  };

  return (
    <div className="parallelogram" onClick={handleClick}>
      <div>
        {/* SVG Example */}
        <svg width="100" height="60" xmlns="http://www.w3.org/2000/svg">
          <image href={Farrow} width="160" height="60" />
        </svg>

        <DataDisplay value={data.value} /> {/* Use the DataDisplay component */}

        {data.btn}
        {data?.btnArray?.map((item) => (
          <button key={item}>{item}</button>
        ))}
      </div>
      <Handle type="source" position={Position.Bottom} brisqueorgprocessdependencyuid="b" />

      {showPopup && (
        <div className="popup">
          <button onClick={handleClose}>X</button>
          {/* Conditional rendering for currentorgprocessdescription */}
          {data.nextprocessname && (
            <h3>{data.nextprocessname}</h3>
          )}
        </div>
      )}
    </div>
  );
}

export default Parallelogram;
