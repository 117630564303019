import React, { useState } from "react"
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import ScreenPanel from "./ScreenPanel"
import GroupPanel from "./GroupPanel"

const EditPanel = ({
	isContentVisible,
	handleAddRow, //function to add row
	setUpdateEditData, // state to update edited data
	bodyDataEdit, //initial body data
	setbodyDataEdit, //to make changes in body data
	editData, //initial edit data for cancel
	seteditData, //
	panelData,
	// setHeaderData,
	value,
	//
	headerData,
}) => {
	const [panelVisible, setPanelVisible] = useState(false)

	const handleAccordionChange = () => {
		setPanelVisible(!panelVisible)
	}

	console.log("headerData", panelData)
	// const header = [
	// 	{
	// 		recno: 1,
	// 		dboperation: "RETRIEVE",
	// 		tablename: "displayscreenlisting",
	// 		columnname: "brisquescreenuid",
	// 		columndatatype: "integer",
	// 		columnlength: null,
	// 		columnprecision: 32,
	// 		columnorder: 1,
	// 		mandatoryflag: "Y",
	// 		jsoncolumnname: "brisquescreenuid",
	// 		jsontype: "integer",
	// 		columndisplayname: "NODISPLAY",
	// 		columndisplayshortname: "brisquescreenuid",
	// 		sortallowedflag: "N",
	// 		filterallowedflag: "N",
	// 		editallowedflag: "N",
	// 	},
	// 	{
	// 		recno: 2,
	// 		dboperation: "RETRIEVE",
	// 		tablename: "displayscreenlisting",
	// 		columnname: "brisquescreenname",
	// 		columndatatype: "character varying",
	// 		columnlength: 100,
	// 		columnprecision: null,
	// 		columnorder: 2,
	// 		mandatoryflag: "N",
	// 		jsoncolumnname: "brisquescreenname",
	// 		jsontype: "string",
	// 		columndisplayname: "Screen Name",
	// 		columndisplayshortname: "brisquescreenname",
	// 		sortallowedflag: "Y",
	// 		filterallowedflag: "Y",
	// 		editallowedflag: "Y",
	// 	},
	// 	{
	// 		recno: 3,
	// 		dboperation: "RETRIEVE",
	// 		tablename: "displayscreenlisting",
	// 		columnname: "screendescription",
	// 		columndatatype: "character varying",
	// 		columnlength: 500,
	// 		columnprecision: null,
	// 		columnorder: 4,
	// 		mandatoryflag: "N",
	// 		jsoncolumnname: "screendescription",
	// 		jsontype: "string",
	// 		columndisplayname: "Description",
	// 		columndisplayshortname: "screendescription",
	// 		sortallowedflag: "N",
	// 		filterallowedflag: "N",
	// 		editallowedflag: "Y",
	// 	},
	// 	{
	// 		recno: 4,
	// 		dboperation: "RETRIEVE",
	// 		tablename: "displayscreenlisting",
	// 		columnname: "createdby",
	// 		columndatatype: "character varying",
	// 		columnlength: 50,
	// 		columnprecision: null,
	// 		columnorder: 5,
	// 		mandatoryflag: "N",
	// 		jsoncolumnname: "createdby",
	// 		jsontype: "string",
	// 		columndisplayname: "NODISPLAY",
	// 		columndisplayshortname: "createdby",
	// 		sortallowedflag: "N",
	// 		filterallowedflag: "N",
	// 		editallowedflag: "N",
	// 	},
	// 	{
	// 		recno: 5,
	// 		dboperation: "RETRIEVE",
	// 		tablename: "displayscreenlisting",
	// 		columnname: "updatedby",
	// 		columndatatype: "character varying",
	// 		columnlength: 50,
	// 		columnprecision: null,
	// 		columnorder: 6,
	// 		mandatoryflag: "N",
	// 		jsoncolumnname: "updatedby",
	// 		jsontype: "string",
	// 		columndisplayname: "NODISPLAY",
	// 		columndisplayshortname: "updatedby",
	// 		sortallowedflag: "N",
	// 		filterallowedflag: "N",
	// 		editallowedflag: "N",
	// 	},
	// 	{
	// 		recno: 6,
	// 		dboperation: "RETRIEVE",
	// 		tablename: "displayscreenlisting",
	// 		columnname: "createddate",
	// 		columndatatype: "timestamp without time zone",
	// 		columnlength: null,
	// 		columnprecision: null,
	// 		columnorder: 7,
	// 		mandatoryflag: "N",
	// 		jsoncolumnname: "createddate",
	// 		jsontype: "timestamp",
	// 		columndisplayname: "NODISPLAY",
	// 		columndisplayshortname: "createddate",
	// 		sortallowedflag: "N",
	// 		filterallowedflag: "N",
	// 		editallowedflag: "N",
	// 	},
	// 	{
	// 		recno: 7,
	// 		dboperation: "RETRIEVE",
	// 		tablename: "displayscreenlisting",
	// 		columnname: "updateddate",
	// 		columndatatype: "timestamp without time zone",
	// 		columnlength: null,
	// 		columnprecision: null,
	// 		columnorder: 8,
	// 		mandatoryflag: "N",
	// 		jsoncolumnname: "updateddate",
	// 		jsontype: "timestamp",
	// 		columndisplayname: "NODISPLAY",
	// 		columndisplayshortname: "updateddate",
	// 		sortallowedflag: "N",
	// 		filterallowedflag: "N",
	// 		editallowedflag: "N",
	// 	},
	// ]

	// const bodyData = {
	// 	recno: 8,
	// 	dboperation: "RETRIEVE",
	// 	brisquescreenuid: 25,
	// 	brisquescreenname: "test",
	// 	brisquescreendisplayname: null,
	// 	brisquefeatureuid: 8,
	// 	screendescription: "test ",
	// 	createdby: "Bhagesh",
	// 	updatedby: "Bhagesh2",
	// 	createddate: "2024-03-12T11:18:58",
	// 	updateddate: "2024-04-02T10:40:14",
	// }

	// useEffect(() => {
	// 	// setbodyDataEdit([bodyData])
	// 	// setHeaderData(header)
	// }, [])
	return (
		<Accordion expanded={panelVisible} onChange={handleAccordionChange}>
			<AccordionSummary
				expandIcon={panelVisible ? <RemoveIcon /> : <AddIcon />}>
				<Typography
					variant="h7"
					sx={{ flexGrow: "1", textAlign: "center" }}>
					Edit Panel for the context
				</Typography>
			</AccordionSummary>

			<AccordionDetails>
				{value === "Panel" ? (
					<ScreenPanel
						setUpdateEditData={setUpdateEditData}
						handleAddRow={handleAddRow}
						bodyDataEdit={bodyDataEdit}
						setbodyDataEdit={setbodyDataEdit}
						editData={editData}
						seteditData={seteditData}
						headerData={headerData}
						// setHeaderData={setHeaderData}
					/>
				) : (
					<GroupPanel panelData={panelData} />
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default EditPanel
