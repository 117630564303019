
import * as React from "react";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { Button, Typography, Box } from "@mui/material";
 
import { updateOrganizationDetails as updateOrganizationDetailsApi } from "../../../action/OrganizationDetailsAction";
 
export default function CompanyListing({
  searchResults,
  setCurrentEnterpriseUid,
  setCurrentEnterpriseName,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(1); // Number of rows to display per page
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [expanded, setExpanded] = useState(false); // Set expanded state based on searchResults
 
  useEffect(() => {
    // Update the expanded state when searchResults changes
    setExpanded(searchResults.length > 0);
  }, [searchResults]);
 
  const handleLocation = () => {
    // Ensure searchResults is not empty and currentPage is within bounds
    if (
      searchResults.length > 0 &&
      currentPage >= 0 &&
      currentPage < searchResults.length
    ) {
      const enterpriseUid = searchResults[currentPage].enterpriseuid;
      const enterpriseName = searchResults[currentPage].enterpriseorgname;
      setCurrentEnterpriseUid(enterpriseUid);
      setCurrentEnterpriseName(enterpriseName);
      // Now you can use currentEnterpriseUid for further processing
      console.log("Current page enterpriseuid:", enterpriseUid);
      console.log("Current page enterpriseName:", enterpriseName);
    }
  };
 
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };
 
  const nextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(searchResults.length / rowsPerPage) - 1)
    );
  };
 
  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };
 
  const handleEdit = (index) => {
    setEditMode(true);
    setEditedData({ ...searchResults[index] }); // Clone the object for editing
  };
 
  const handleSave = () => {
    console.log("Edited data:", editedData);
    // Call API to update organization details
    updateOrganizationDetailsApi(editedData)
      .then((response) => {
        if (!response.error) {
          alert("Successfully updated!");
          // window.location.reload();
        } else {
          alert(`Error! ${response.message}`);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred while updating.");
      });
    setEditMode(false);
    setEditedData(null);
  };
 
  const handleCancel = () => {
    setEditMode(false);
    setEditedData(null);
  };
 
  const handleFieldChange = (e, field) => {
    const { value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
 
  const renderLogo = (logoHex) => {
    // Remove '\\x' prefix and convert hexadecimal string to binary string
    const logoBinary = logoHex.replace(/\\x/g, "");
    // Convert binary string to a Uint8Array
    const logoUint8Array = new Uint8Array(
      logoBinary.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );
    // Convert Uint8Array to base64 encoded string
    const logoBase64 = btoa(String.fromCharCode.apply(null, logoUint8Array));
    // Return the base64 encoded string
    console.log("Logo base64:", logoBase64);
    return `data:image/png;base64,${logoBase64}`;
  };
 
  return (
    <div>
      {searchResults.length === 0 ? (
        <h3>First Search for the company </h3>
      ) : (
        <Paper sx={{ width: "100%", overflow: "auto" }}>
          {searchResults.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Search Results</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ color: "#4169e1" }}>
                        Company Name:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.enterpriseorgname}
                            onChange={(e) =>
                              handleFieldChange(e, "enterpriseorgname")
                            }
                          />
                        ) : (
                          searchResults[currentPage].enterpriseorgname
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "#4169e1" }}>
                        Legal Name:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.legalname}
                            onChange={(e) => handleFieldChange(e, "legalname")}
                          />
                        ) : (
                          searchResults[currentPage].legalname
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: "#4169e1" }}>
                        Corporate URL:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.corporateurl}
                            onChange={(e) =>
                              handleFieldChange(e, "corporateurl")
                            }
                          />
                        ) : (
                          searchResults[currentPage].corporateurl
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "#4169e1" }}>Logo:</TableCell>
                      <TableCell>
                        {editMode ? (
                          <input
                            type="file"
                            onChange={(e) => handleFieldChange(e, "logo")}
                          />
                        ) : (
                          searchResults[currentPage].logo && (
                            <img
                              src={renderLogo(searchResults[currentPage].logo)}
                              alt="Company Logo"
                              style={{ maxWidth: 100, maxHeight: 100 }} // Set maximum width and height as per your requirement
                            />
                          )
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: "#4169e1" }}>
                        Postal Address:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.addressline1}
                            onChange={(e) =>
                              handleFieldChange(e, "addressline1")
                            }
                          />
                        ) : (
                          `${searchResults[currentPage].addressline1}, ${searchResults[currentPage].citytown}, ${searchResults[currentPage].stateprovince} ${searchResults[currentPage].zippostcode}, ${searchResults[currentPage].country}`
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "#4169e1" }}>
                        DUNS Number:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.dunsnumber}
                            onChange={(e) => handleFieldChange(e, "dunsnumber")}
                          />
                        ) : (
                          searchResults[currentPage].dunsnumber
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: "#4169e1" }}>
                        Main Contact Full Name:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.maincontactfullname}
                            onChange={(e) =>
                              handleFieldChange(e, "maincontactfullname")
                            }
                          />
                        ) : (
                          searchResults[currentPage].maincontactfullname
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "#4169e1" }}>
                        Main Contact Email:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.maincontactemail}
                            onChange={(e) =>
                              handleFieldChange(e, "maincontactemail")
                            }
                          />
                        ) : (
                          searchResults[currentPage].maincontactemail
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: "#4169e1" }}>
                        Start Date:
                      </TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.datestart}
                            onChange={(e) => handleFieldChange(e, "datestart")}
                          />
                        ) : (
                          searchResults[currentPage].datestart
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "#4169e1" }}>End Date:</TableCell>
                      <TableCell>
                        {editMode ? (
                          <TextField
                            value={editedData.dateend}
                            onChange={(e) => handleFieldChange(e, "dateend")}
                          />
                        ) : (
                          searchResults[currentPage].dateend
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Your buttons and pagination here */}
              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                {!editMode ? (
                  <>
                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleEdit(currentPage)}
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="primary"
                      disabled
                    >
                      Save
                    </Button>
                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="secondary"
                      disabled
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLocation}
                      sx={{ mx: 2 }}
                    >
                      See Locations
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={editMode || currentPage === 0} // Disable Previous button when in edit mode or on the first page
                  onClick={prevPage}
                  sx={{ mx: 2 }}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    editMode ||
                    currentPage ===
                      Math.ceil(searchResults.length / rowsPerPage) - 1
                  } // Disable Next button when in edit mode or on the last page
                  onClick={nextPage}
                  sx={{ mx: 2 }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )}
        </Paper>
      )}
    </div>
  );
}
 