import axios from "axios"
import * as q from "q"
 
export function getBrisqueScreenDetails(
    selectedBrisqueFeatureUid,
    brisquePanelName
) {
    var defer = q.defer()
    var url = process.env.REACT_APP_BASEURL + "brisqueScreenDetails";
    // var url = "http://localhost:3100/brisqueScreenDetails"
    // console.log("selectedBrisqueFeatureUid:", selectedBrisqueFeatureUid)
    axios
        .post(url, {
            selectedBrisqueFeatureUid: selectedBrisqueFeatureUid,
            brisquePanelName: brisquePanelName,
        })
        .then(function (response) {
            defer.resolve(response)
            //console.log("Response from server:", response)
        })
        .catch(function (error) {
            defer.reject(error)
        })
    return defer.promise
}
 
export function putbrisquescreen(editedData) {
    var defer = q.defer()
    var url = process.env.REACT_APP_BASEURL + "putbrisquescreen";
    // var url = "http://localhost:3100/putbrisquescreen"
    console.log("editedData:", editedData)
    axios
        .post(url, {
            editedDataBody: editedData,
        })
        .then(function (response) {
            // Check if the response contains a message
            if (response.data && response.data.message) {
                defer.resolve(response.data.message)
            } else {
                // If no message is found, reject with an error
                defer.reject(new Error("No message found in response"))
            }
        })
        .catch(function (error) {
            defer.reject(error)
        })
    return defer.promise
}