import axios from "axios";
import * as q from "q";
 
export function getLeftNavigationPanel() {
  var defer = q.defer();
  var url = process.env.REACT_APP_BASEURL + "getLeftNavigationPanel";
  // var url = "http://localhost:3100/getLeftNavigationPanel";
  console.log("LeftNavigationPanelAction  " + url);
  axios
    .post(url)
    .then(function (response) {
      defer.resolve(response);
    })
    .catch(function (error) {
      defer.reject(error);
    });
  return defer.promise;
}
 