import axios from "axios";
import * as q from "q";

export function authenticateStdUser(useriddata, passdata,dboperation, origpassword) {
  var defer = q.defer();
  var url = process.env.REACT_APP_BASEURL + "users/authenticateStdUser";
  console.log("url" + url)
  // var url = "http://localhost:3100/users/authenticateStdUser";
  console.log("this is authentication part ");
  axios
    .post(url, {
      userid: useriddata,
      password: passdata,
      dboperation:dboperation,
      origpassword:origpassword

    })
    .then(function (response) {
      defer.resolve(response);
 
      //console.log(response)
    //   var data = response.data.data;
    //   var token = data.token;
    //   console.log("token", response);
    //   window.sessionStorage.setItem("auth_token", token);
      
    })
    .catch(function (error) {
      defer.reject(error);
    });
  return defer.promise;
 
}