
import React, { useRef, useEffect, useState } from "react";
import logo from "../../images/logo.png";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Button, Typography, TextField } from "@mui/material";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { authenticateStdUser } from "../action/user";
import * as CryptoJS from "crypto-js";



export default function ResetPassScreen() {
const [newpassword, setNewpassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [userid, setUserid] = useState("");
const [origalpassword, setOrigalpassword] = useState("");
const [origEncryptpassword, setOrigEncryptpassword] = useState("");


const navigate = useNavigate();


   

 
   
const handleLink = () => {
  navigate('/');
 }
 const handleOldPass = (event) => {
    setOrigalpassword(event)
    var PassNewEncrypt = CryptoJS.AES.encrypt(JSON.stringify(event), "my-secret-key@rcube");
    const  passdata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(PassNewEncrypt));
    setOrigEncryptpassword(passdata)
   }
 

 const handleOldPassword = (event) => {
    var useridEncrypt = CryptoJS.AES.encrypt(JSON.stringify(userid), "my-secret-key@rcube");
   }
  
 

 const ResetIn = () => {
    if (confirmPassword !== newpassword) {
        alert("Passwords does not match");
    
      }else{
        

        var useridEncrypt = CryptoJS.AES.encrypt(JSON.stringify(userid), "my-secret-key@rcube");
        var PassNewEncrypt = CryptoJS.AES.encrypt(JSON.stringify(origalpassword), "my-secret-key@rcube");
     

        const useriddata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(useridEncrypt));
        const  passdata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(PassNewEncrypt));
        setOrigEncryptpassword(passdata);
         const dboperation = "VALIDATE";
         const origpassword = undefined;
        
      console.log("origEncryptpassword" + origEncryptpassword);
        authenticateStdUser(useriddata, passdata, dboperation, origpassword)
          .then((response) => {
            let status = JSON.parse(response.status);
            console.log(status)
            if(status === 200){
                try{
                    var useridEncrypt = CryptoJS.AES.encrypt(JSON.stringify(userid), "my-secret-key@rcube");
                    var PassNewEncrypt = CryptoJS.AES.encrypt(JSON.stringify(newpassword), "my-secret-key@rcube");
                    var PassOldEncrypt = CryptoJS.AES.encrypt(JSON.stringify(origalpassword), "my-secret-key@rcube");
                    const useriddata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(useridEncrypt));
                    const  passdata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(PassNewEncrypt));
                    const  passOlddata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(PassOldEncrypt));
                    const dboperation= "VALIDATEUSERRESET";
                   
              
              authenticateStdUser(useriddata, passdata,dboperation, passOlddata )
              .then((response) => {
                let data = response.data;
                if (data === "Success"){
                  navigate('/login/');
                }
                
              
              
                // navigate('/layouts/');
              })
              .catch((error) => {
                console.log(error);
              });

                }catch{

                }
            }
        
           
          })
          .catch((error) => {
            console.log(error);
          });
    
      }
   }
 
  return (
    <div>
      <div className="loginContainer">
        <img src={logo} style={{ height: "45px", width: "45px" }} />
      </div>
      <Card
        sx={{
          variant: "outlined",
          width: 250,
          padding: 5,
          alignItems: "center",
          marginLeft: "450px",
          marginTop: "80px",
          marginBottom: "-25px",
        }}
      >
        <Typography
          variant="overline"
          textAlign="center"
          display="block"
          gutterBottom
        >
          Reset Password
        </Typography>
        {/* <form noValidate autoComplete="off"> */}

        <TextField
          id="login-email"
          label="UserId"
          margin="dense"
          type="email"
          fullWidth={true}
            value={userid}
          onChange={(e) => {
            setUserid(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          id="old-pass"
          label="Old Password"
          margin="dense"
          type="password"
          fullWidth={true}
          value={origalpassword}
        //   onChange={handleOldPass}
          onChange={(e) => {
            setOrigalpassword(e.target.value);
           }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />

        <TextField
          id="new-pass"
          label="New Password"
          margin="dense"
          type="password"
          fullWidth={true}
          value={newpassword}
          onChange={(e) => {
            setNewpassword(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          id="confirm-pass"
          label="Confirm Password"
          margin="dense"
          type="password"
          fullWidth={true}
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        &nbsp; &nbsp;
        <div style={{ textAlign: "center" }}>
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            color="primary"

            onClick={ResetIn}
          >
            Reset Password
          </Button>
        </div>
        &nbsp;
        <div style={{ textAlign: "center" }}>
          <Link
            sx={{ fontSize: "14px" }}
            href="#"
            color="inherit"
            underline="always"
            onClick={handleLink}
          >
            Back to Login
          </Link>
        </div>
      </Card>
    </div>
  );
}
