// import AppRoutes from "../routes";
//import {Typography} from "antd";
import Typography from "@mui/material/Typography";
import "../style.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Link from '@mui/material/Link';
import RightContent from "../RightContent";

import { useState } from "react";
import CustomSeparator from '../CustomSeparator'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';


function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
  
export default function PageContent({query,onQueryRC, pathshow}) {

  
 console.log("pathshow" + pathshow)
 const [showStatus, setShowStatus] = useState(true);


let PageLeftContentX = !query ? 'PageContent' :'PageContentDynamic';
let PageRightContentX = !onQueryRC ? 'Usermanagement' :'UsermanagementDynamic';


pathshow = ["abc", "cds", "fjjjf"]
const breadcrumbs = [
 
  pathshow.map((item) =>
  <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
    {item}
  </Link>,
)];



 const Container = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "Left",
    color: "black",
    fontWeight: "bold",

    // color: theme.palette.text.secondary,
  }));




  return (

    <div className={`${PageLeftContentX} anotherClass ${PageRightContentX}`}>
    
      <div>
      {/* <Stack spacing={2}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
      showStatus ? "welcome to brisque" : {breadcrumbs}
      </Breadcrumbs>
    </Stack> */}
        <Typography variant="h6">
          Console Home
          <span style={{ verticalAlign: "super", color: "blue" }}>
            {" "}
            <sup>info</sup>
          </span>
        </Typography>
        
      </div>
     
    
      <Box
      sx={{
          mb: 2,
          display: "flex",
          flexDirection: "column",
          // height: 700,
          // overflow: 'hidden',
          marginRight: '8px',
         // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}>
        <Box
        
          display="grid"
          // gridTemplateColumns="repeat(12, 1fr)"
          gap={2}
          spacing={8}
        >
          <Box gridColumn="span 9">
            <Container sx={{ height: "130px"} }>
              {/* <Typography variant="h7" paddingLeft="30px" gutterBottom>
                context
              </Typography>

              <span style={{ verticalAlign: "super", color: "blue" }}>
                {" "}
                <sup>info</sup>
              </span> */}
              <Typography variant="body2" gutterBottom>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                blanditiis tenetur unde suscipit, quam beatae rerum inventore
                consectetur, neque doloribus, cupiditate numquam dignissimos
                laborum fugiat deleniti? Eum quasi quidem quibusdam.
              </Typography>
            </Container>
          </Box>

          <Box gridColumn="span 9">
           
            {/* <Container sx={{ height: "100px" }}>  <PanelFirst /></Container> */}
          </Box>

          <Box gridColumn="span 9">
         
            {/* <Container sx={{ height: "100px" }}>  <PanelFirst /></Container> */}
          </Box>
          {/* <Box gridColumn="span 8">
            <Container sx={{ height: "320px" }}>Brisque Product
            <span style={{ verticalAlign: "super", color:"blue"}}> <sup>info</sup></span>
            </Container>
          </Box>  */}
          {/* <Box gridColumn="span 8">
            <Container sx={{ height: "320px" }}>Details
            <span style={{ verticalAlign: "super", color:"blue"}}> <sup>info</sup></span>
            </Container>
          </Box>  */}
        </Box>
        </Box>
      
      {/* <Container fixed>
        <Box sx={{ bgcolor: 'white', height: '60vh', width:'140vh' }} />
      </Container>
      {' '}
      {' '}
      <Container fixed>
        <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3,  bgcolor: 'red', height: '50vh', width:'50vh' }} />
      </Container>
     
    
      <AppRoutes /> */}

      <div> 
      </div>
    </div>
  
  );
}

