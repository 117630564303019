import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CommentForm from "./CommentForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ReplyPopup({
  comment,
  setActiveComment,
  addComment,
  parentId,
  currentUserId,
  selectedRowId,
  isReplying
}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setActiveComment({
      usercommentuid: comment.usercommentuid,
      type: "replying",
    });
    handleOpen();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div onClick={handleClick}>Reply </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
          {isReplying && (
            <CommentForm
              submitLabel="Reply"
              hasCancelButton={true}
              handleCancel={() => {
                handleClose(); // Close the modal on cancel
                setActiveComment("");
              }}
              handleSubmit={(text) => {
                addComment(text, parentId, selectedRowId); // Ensure correct arguments
                handleClose(); // Close the modal after submitting
              }}
            />
          )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
