import { useState } from "react";
import CommentForm from "./CommentForm";
import ReplyPopup from "./ReplyPopup";
import usericon from "../../../../images/user-icon.png";

const Comment = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  parentId = -1,
  currentUserId,
  selectedRowId,
}) => {
  // console.log("Updated Comment:", comment);
  const [isExpanded, setIsExpanded] = useState(false);
  const maxWords = 20;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    } else {
      return text;
    }
  };

  const isEditing =
    activeComment &&
    activeComment.usercommentuid === comment.usercommentuid &&
    activeComment.type === "editing";
  const isReplying =
    activeComment &&
    activeComment.usercommentuid === comment.usercommentuid &&
    activeComment.type === "replying";
  const fiveMinutes = 300000;
  const timePassed = new Date() - new Date(comment.createddate) > fiveMinutes;
  const canDelete =
    currentUserId === comment.usercommentuid && replies.length === 0;
  // const canDelete =
  //   currentUserId === comment.usercommentuid &&
  //   replies.length === 0 &&
  //   !timePassed;
  const canReply = Boolean(currentUserId);
  //const canEdit = currentUserId === comment.usercommentuid && !timePassed;
  const canEdit = currentUserId === comment.usercommentuid;

  // const replyId = parentId ? parentId : comment.usercommentuid;
  const replyId =
    comment.priorusercommentuid === -1
      ? comment.usercommentuid
      : comment.priorusercommentuid;
  const createdAt = new Date(comment.createddate).toLocaleDateString();

  //   console.log("timePassed:", timePassed);
  // console.log("currentUserId:", currentUserId);
  // console.log("comment.usercommentuid:", comment.usercommentuid);

  return (
    <div key={comment.usercommentuid} className="comment">
      <div className="comment-image-container">
        <img src={usericon} alt="User Icon" />
      </div>
      <div className="comment-right-part">
        <div className="comment-content">
          <div className="comment-author">{comment.createdby}</div>
          <div className="comment-date">{createdAt}</div>
        </div>

        {!isEditing && (
          <pre className="comment-text">
            {isExpanded
              ? comment.usercomment
              : truncateText(comment.usercomment, maxWords)}
            {comment.usercomment.split(" ").length > maxWords && (
              <span className="read-more" onClick={toggleExpand}>
                {isExpanded ? "Read Less" : "Read More"}
              </span>
            )}
          </pre>
        )}
        {isEditing && (
          <CommentForm
            submitLabel="Update"
            hasCancelButton={true}
            initialText={comment.usercomment}
            handleSubmit={(text) => updateComment(text, comment.usercommentuid)}
            handleCancel={() => {
              setActiveComment(null);
            }}
          />
        )}

        <div className="comment-actions">
          {canReply && (
            <div>
              <ReplyPopup
                comment={comment}
                setActiveComment={setActiveComment}
                addComment={addComment}
                parentId={
                  comment.priorusercommentuid !== -1
                    ? comment.priorusercommentuid
                    : comment.usercommentuid
                }
                currentUserId={currentUserId}
                selectedRowId={selectedRowId}
                isReplying={isReplying}
              />
            </div>
          )}
          {canEdit && (
            <div
              className="comment-action"
              onClick={() =>
                setActiveComment({
                  usercommentuid: comment.usercommentuid,
                  type: "editing",
                })
              }
            >
              Edit
            </div>
          )}
          {canDelete && (
            <div
              className="comment-action"
              onClick={() => deleteComment(comment.usercommentuid)}
            >
              Delete
            </div>
          )}
        </div>
        {/* REPLIES */}
        {replies.length > 0 && (
          <div className="replies">
            {replies.map((reply) => (
              <Comment
                comment={reply}
                key={reply.usercommentuid}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                addComment={addComment}
                deleteComment={deleteComment}
                updateComment={(text, usercommentuid) =>
                  updateComment(text, usercommentuid, selectedRowId)
                }
                currentUserId={reply.usercommentuid}
                selectedRowId={comment.rowuidincontext}
                replies={[]}
                parentId={comment.usercommentuid}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
