import React, { useRef, useEffect, useState } from "react";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import logo from "../../images/logo.png";
import Card from "@mui/material/Card";
import * as Validate from "./validation";
import { useNavigate } from "react-router-dom";
import { Button, Typography, TextField } from "@mui/material";
import "../App.css";
import * as CryptoJS from "crypto-js";
import { authenticateStdUser } from "../action/user";
import { useSearchParams, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import Loader from "./loader";

export default function Login() {
  const [email, setEmail] = useState(" ");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(" ");
  const [passError, setPassError] = useState(" ");
  const [loginStatus, setLoginStatus] = useState(" ");
  const [loginCount, setLoginCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const logIn = () => {
    setIsLoading(true)
    const isValid = true;

    if (isValid) {
      // temp data for link
      //  var datauserid = "arshi@ebrisque.com";
      //  var passdatalink = "raghu@123";
      //  var tempData = CryptoJS.AES.encrypt(JSON.stringify(datauserid), "my-secret-key@rcube");
      //  var tempDataPass = CryptoJS.AES.encrypt(JSON.stringify(passdatalink), "my-secret-key@rcube");
      //  const tempData64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(tempData));
      //  const tempDataPass64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(tempDataPass));
      //  console.log("tempData64" + tempData64);
      //  console.log("tempDataPass64" + tempDataPass64);

      var en_email = CryptoJS.AES.encrypt(
        JSON.stringify(email),
        "my-secret-key@rcube"
      );
      const useriddata = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(en_email)
      );
      var en_password = CryptoJS.AES.encrypt(
        JSON.stringify(password),
        "my-secret-key@rcube"
      );
      const passdata = CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(en_password)
      );
      const dboperation = "VALIDATE";
      const origpassword = undefined;

      console.log("useriddata : " + useriddata);
      console.log("passdata : " + passdata);
      console.log("dboperation : " + dboperation);
      console.log("origpassword : " + origpassword);

      if (loginCount <= 2) {
      authenticateStdUser(useriddata, passdata, dboperation, origpassword)
        .then((response) => {
          console.log(response.data);
          console.log(response.data);
          if (response.data.status === "Success") {
            setIsLoading(false);
            sessionStorage.setItem(
              "userDetails",
              JSON.stringify(response.data.useruid.resultspayload[0])
            );
            navigate("/layouts/");
          }
          setLoginStatus(response.data);

          if (response.data !== "Success") {
            setIsLoading(false);
            var count = loginCount;
            setLoginCount((count) => loginCount + 1);
          }
          console.log("VALIDATEloginCount" + loginCount);
        
          
        })
        .catch((error) => {
            console.log(error);
          })};
        if (loginCount == 3) {
          let  dboperation = "LOCK";
           authenticateStdUser(useriddata, passdata, dboperation, origpassword)
           .then((response) => {
             console.log("Lock response" + response.data);
               setLoginStatus(response.data);
               setIsLoading(false);
               var count = loginCount;
            setLoginCount((count) => loginCount + 1);
               console.log("LockloginCount" + loginCount);
              //  setInterval(() => window.location.reload(true), 1000*60*15  );
           })
         }
         if (loginCount >= 4) {
          const dboperation = "VALIDATE";
          const origpassword = undefined;
          authenticateStdUser(useriddata, passdata, dboperation, origpassword)
      
          .then((response) => {
            console.log(response.data);
            if (response.data.status === "Success") {
              setIsLoading(false);
              sessionStorage.setItem(
                "userDetails",
                JSON.stringify(response.data.useruid.resultspayload[0])
              );
              navigate("/layouts/");
            }
            setLoginStatus(response.data);
  
            if (response.data !== "Success") {
              setIsLoading(false);
              var count = loginCount;
              setLoginCount((count) => loginCount + 1);
            }
            console.log("loginCount" + loginCount);
          
            
          })
          .catch((error) => {
              console.log(error);
            })
         }
       
    } else {
      alert("Invalid UserId and Password!!");
    }
  };

  console.log(loginStatus);

  // const formValidation = () => {
  //   let emailErr = {};
  //   let passwordErr = {};
  //   let isValid = true;

  //   if (email == undefined || email == null || email.trim().length == 0) {
  //     emailErr = "Please provide email address.";
  //     isValid = false;
  //   }
  //   if (!Validate.email(email)) {
  //     emailErr = "Please provide a valid email address.";
  //     isValid = false;
  //   }
  //   if (
  //     password == undefined ||
  //     password == null ||
  //     password.trim().length == 0
  //   ) {
  //     passwordErr = "Please provide password.";
  //     isValid = false;
  //   }

  //   setEmailError(emailErr);
  //   setPassError(passwordErr);

  //   return isValid;
  // };

  return (
    <div>
      <div className="loginContainer">
        <img src={logo} style={{ height: "45px", width: "45px" }} />
      </div>
      <Card
        sx={{
          variant: "outlined",
          width: 250,
          padding: 5,
          alignItems: "center",
          marginLeft: "450px",
          marginTop: "80px",
          marginBottom: "-25px",
        }}
      >
        <Typography component="h2" variant="h5" color="primary" gutterBottom>
          Login
        </Typography>
        {/* <form noValidate autoComplete="off"> */}
        <TextField
          // sx={{marginbottom: "-25px"}}
          // error={state.email_error.length > 0}
          id="login-email"
          label="Username"
          margin="dense"
          type="email"
          // margintop="-18px"s
          // marginbottom="-25px"
          // helperText={emailError}
          fullWidth={true}
           value={email}
          // value={queryParameters ? queryParameters.get("user") : { email }}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
        <TextField
          // error={password_error.length > 0}
          id="login-password"
          label="Password"
          margin="dense"
          type="password"
          fullWidth={true}
          // helperText={passError}
        value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            // setLoginStatus(" ")
          }}
          onkeypress={(event) => {
            if (event.key == "Enter") {
              this.login();
            }
          }}
        />
         {isLoading ? (
        <div className="LoaderStyle">
        <Loader />
        </div>
        ) : (
        <div className="formAction">
          <Button
            variant="contained"
            alignContent="center"
            color="primary"
            justifyContent="center"
            onClick={logIn}
          >
            Login
          </Button>
        </div>
        )}
        &nbsp;
        <Typography variant="caption" display="block" color="red" gutterBottom>
          {/* <ReportProblemIcon sx={{ color: "red" }}/> */}
          {loginStatus}
        </Typography>
        {/* </form> */}
        {/* <ReCaptcha
              sitekey="6Ld9QrIUAAAAAB_xfHFvlFrKKlq_-xc-hw7TkKuf"
              action="login"
              verifyCallback={response => {
                if (response) {
                  this.setState({ is_capcha: true });
                }
              }}
            /> */}
        <div>
          <table>
            <tr>
              <th>
                <Link
                  sx={{ fontSize: "10px" }}
                  underline="hover"
                  marginTop="20px"
                  color="red"
                  href="/forgot_usename"
                >
                  forget your username{" "}
                </Link>
              </th>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <th>
                <Link
                  sx={{ fontSize: "10px" }}
                  underline="hover"
                  marginTop="20px"
                  color="red"
                  href="/forgot_pass"
                >
                  forget your password{" "}
                </Link>
              </th>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <th>
                <Link
                  sx={{ fontSize: "10px" }}
                  underline="hover"
                  marginTop="20px"
                  color="red"
                  href="/reset_pass"
                >
                  Reset your password{" "}
                </Link>
              </th>
            </tr>
          </table>
        </div>
      </Card>
    </div>
  );
}
