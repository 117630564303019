import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Radio,
  Button,
  Paper,
  TableContainer,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { styled } from "@mui/system";
import { getfeaturescreenforsearch } from "../../../action/SearchBrisquePanel";
import { getBrisqueHeadingsforScreenPanels } from "../../../action/BrisqueHeadings";

const brisquePanelName = "searchpanelbyscreen";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#E8E8E8",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F5F5F5",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function SearchPanel({ setSelectedUid }) {
  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    if (brisquePanelName) {
      getBrisqueHeadingsforScreenPanels(brisquePanelName)
        .then((DetailData) => {
          console.log("Bhagesh" + JSON.stringify(DetailData.data));
          setHeaderData(DetailData.data);
        })
        .catch((error) => {
          setHeaderData([]); // Handle the error by setting an empty array
        });
      getfeaturescreenforsearch(brisquePanelName)
        .then((DetailData) => {
          setTableData(DetailData.data);
        })
        .catch((error) => {
          setTableData([]); // Handle the error by setting an empty array
        });
    }
  }, []);

  const handleRadioChange = (rowIndex) => {
    setSelectedRow(rowIndex);
  };
  const handleSearchButtonClick = () => {
    const selectedRowData = tableData[selectedRow];
    if (selectedRowData && selectedRowData.hasOwnProperty("brisquescreenuid")) {
      const selectedUid = selectedRowData.brisquescreenuid;
      setSelectedUid(selectedUid);
    }
    console.log("rowdata", selectedRowData);
  };

  return (
    <div>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          sx={{ backgroundColor: "#c4bcbc" }}
          expandIcon={expanded ? <Remove /> : <Add />}
        >
          <Typography variant="h7" sx={{ flexGrow: "1", textAlign: "center" }}>
            Search Panel for the context
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper sx={{ width: "100%", overflow: "auto" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#fff",
                      color: "#fff",
                    }}
                  >
                    <TableCell>{"Select"}</TableCell>
                    {headerData.map((column) =>
                      column.columndisplayname !== "NODISPLAY" ? (
                        <TableCell key={column.columnname}>
                          {column.columndisplayname}
                        </TableCell>
                      ) : null
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.length > 0 ? (
                    tableData.map((row, rowIndex) => (
                      <StyledTableRow key={`row-${rowIndex}`}>
                        <TableCell>
                          <Radio
                            checked={selectedRow === rowIndex}
                            onChange={() => handleRadioChange(rowIndex)}
                          />
                        </TableCell>
                        {headerData.map((column, colIndex) =>
                          column.columndisplayname !== "NODISPLAY" ? (
                            <TableCell key={`cell-${rowIndex}-${colIndex}`}>
                              {(() => {
                                // const columnName =
                                //     column.columnname.toLowerCase()
                                const key = column.columnname;
                                const cellValue = row.hasOwnProperty(key)
                                  ? row[key]
                                  : "N/A";
                                return cellValue;
                              })()}
                            </TableCell>
                          ) : null
                        )}
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow key="no-data-row">
                      <TableCell
                        colSpan={headerData.length + 1} // +1 for the "Select" column
                        align="center"
                        key="no-data-cell"
                      >
                        No data available.
                      </TableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "12px",
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearchButtonClick}
              >
                Search
              </Button>
            </div>
            <div>
              <Button variant="contained" color="secondary">
                Delete
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SearchPanel;
