import React, { useState, useEffect } from "react"
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Checkbox,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Button,
	Popover,
} from "@mui/material"
import { Add, Remove } from "@mui/icons-material"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { styled } from "@mui/system"
import {
	getbrisquepanellisting,
	putbrisquepanel,
} from "../../../action/DisplayBrisquePanel"
import { getBrisqueHeadingsforScreenPanels } from "../../../action/BrisqueHeadings"
import { validateuxactionforuseronpanels } from "../../../action/ButtonsActions"

const DetailsPanel = ({
	selectedUid,
	handleAddRow,
	setHeaderData,
	headerData,
	setBodyData,
	bodyData,
	seteditData,
	setbodyDataEdit,
	UpdatedEditData,
	value,
	setValue,
	setPanelData,
	//  Faize Added
	onRowSelect,
	onRowSelectNotes,
	//
}) => {
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [expanded, setExpanded] = useState(false)
	const [selectedItems, setSelectedItems] = useState([])
	const [filterValues, setFilterValues] = useState({})
	const [anchorEl, setAnchorEl] = useState(null)
	const [orderBy, setOrderBy] = useState("")
	const [order, setOrder] = useState("asc")
	const [isFilterActive, setIsFilterActive] = useState(false)
	const [expandedCells, setExpandedCells] = useState({})
	const [refreshData, setRefreshData] = useState(false)
	const [actions, setActions] = useState([])
	const [combinedData, setCombinedData] = useState([])

	//   Faize Added
	// const [isRowSelectedEdited, setIsRowSelectedEdited] = useState([])
	const [isRowSelected, setIsRowSelected] = useState([]) // Array(bodyData.length).fill(false)
	//

	const brisquePanelName = "displaypanellisting"
	// const user = "Bhagesh"
	const uuid = "6"
	// console.log(combinedData)

	useEffect(() => {
		getBrisqueHeadingsforScreenPanels(brisquePanelName)
			.then((DetailData) => {
				setHeaderData(DetailData.data)
			})
			.catch((error) => {
				setHeaderData([]) // Handle the error by setting an empty array
			})
		validateuxactionforuseronpanels(brisquePanelName, uuid)
			.then((DetailData) => {
				setActions(DetailData.data)
			})
			.catch((error) => {
				setActions([]) // Handle the error by setting an empty array
			})
	}, [brisquePanelName])
	useEffect(() => {
		// Fetch body data from the specified API endpoint
		if (selectedUid || refreshData) {
			getbrisquepanellisting(selectedUid, brisquePanelName)
				.then((DetailData) => {
					//console.log("data from API:", DetailData)
					setBodyData(DetailData.data)
					setCombinedData(DetailData.data)
					setSelectedItems([])
				})
				.catch((error) => {
					setCombinedData([]) // Handle the error by setting an empty array
				})
				.finally(() => {
					setRefreshData(false) // Reset refreshData state
				})
		}
	}, [selectedUid, refreshData])
	useEffect(() => {
		// Calculate the maximum recno from combinedData
		let maxRecno = 0
		if (combinedData !== null) {
			maxRecno = Math.max(
				...combinedData.map((item) => item.recno || 0), // Check for null and undefined recno values
				0
			)
		}
		// Update combinedData when updatedEditData changes
		let updatedCombinedData = []
		if (combinedData !== null && Array.isArray(combinedData)) {
			updatedCombinedData = [...combinedData]
		}
		// Keep track of recnos that are already present in combinedData
		const existingRecnos = new Set(
			updatedCombinedData.map((item) => item.recno)
		)

		// Filter out the new records from UpdatedEditData
		const newRecords = UpdatedEditData.filter(
			(updatedItem) => !existingRecnos.has(updatedItem.recno)
		)

		// Assign recno to each new record based on the maximum recno
		let newIndex = 1
		newRecords.forEach((newRecord) => {
			// If brisquefeatureuid is null, find it from combinedData and set it
			if (
				newRecord.hasOwnProperty("brisquefeatureuid") &&
				newRecord.brisquefeatureuid === null
			) {
				const existingRecord =
					combinedData &&
					combinedData.find(
						(record) => record.recno === newRecord.recno
					)
				if (existingRecord && existingRecord.brisquefeatureuid) {
					newRecord.brisquefeatureuid =
						existingRecord.brisquefeatureuid
				}
			}
			newRecord.recno = maxRecno + newIndex
			newIndex++
		})

		// console.log("newRecords", newRecords)

		// Push all new records at once
		updatedCombinedData.push(...newRecords)

		// Update existing records if any
		UpdatedEditData.forEach((updatedItem) => {
			const index = updatedCombinedData.findIndex(
				(item) => item.recno === updatedItem.recno
			)
			if (index !== -1) {
				// Replace existing record with updated record
				updatedCombinedData[index] = updatedItem
			}
		})

		// Update database operations
		updatedCombinedData.forEach((item) => {
			if (
				item.dboperation === "INSERT" &&
				item.hasOwnProperty("brisquefeatureuid") && // Check if item has the property
				bodyData.length > 0 && // Check if bodyData array has elements
				bodyData[0].hasOwnProperty("brisquefeatureuid") // Check if bodyData[0] has the property
			) {
				const brisquefeatureuid = bodyData[0].brisquefeatureuid
				item.brisquefeatureuid = brisquefeatureuid
			}
		})

		setCombinedData(updatedCombinedData)
	}, [UpdatedEditData])

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "#E8E8E8",
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#F5F5F5",
		},
		"&:last-child td, &:last-child th": {
			border: 0,
		},
	}))

	const EditedTableCell = styled(TableCell)(({ theme, dboperation }) => ({
		color:
			dboperation === "UPDATE"
				? "#E8751A"
				: dboperation === "INSERT"
				? "#13a00d"
				: "#000",
		"&:last-child td, &:last-child th": {
			border: 0,
		},
	}))

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const toggleAccordion = () => {
		setExpanded(!expanded)
	}

	const handleClick = () => {
		const selectedDataArray = [
			...selectedItems.map((index) => combinedData[index]),
		]

		if (selectedDataArray.length > 0) {
			seteditData(selectedDataArray)
			setbodyDataEdit(selectedDataArray)
		}
	}

	const handleCheckboxClick = (rowIndex) => {
		console.log("selectedItems", selectedItems)
		setSelectedItems((prevSelectedItems) => {
			const isSelected = prevSelectedItems.includes(rowIndex)
			console.log("isSelected", isSelected)
			if (isSelected) {
				return prevSelectedItems.filter((item) => item !== rowIndex)
			} else {
				// Update the selection status for bodyData
				const newSelection = [...isRowSelected]
				newSelection[rowIndex] = true
				setIsRowSelected(newSelection)
				return [...prevSelectedItems, rowIndex]
			}
		})
	}
	useEffect(() => {
		if (value === "Group") {
			// Execute this effect whenever selectedItems changes
			const selectedDataArray = selectedItems.map(
				(index) => combinedData[index]
			)
			console.log(selectedDataArray)

			if (
				selectedDataArray.length === 1 ||
				selectedDataArray.length === 0
			) {
				setPanelData(selectedDataArray)
			} else if (selectedDataArray.length > 1) {
				alert("Please select only one panel.")
			}

			console.log("selectedDataArray", selectedDataArray)
		}
	}, [selectedItems, value])

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleSortRequest = (column) => {
		const isAsc = orderBy === column && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(column)

		// Sort the bodyData
		const sortedData = combinedData.slice().sort((a, b) => {
			if (a[column] < b[column]) {
				return isAsc ? -1 : 1
			}
			if (a[column] > b[column]) {
				return isAsc ? 1 : -1
			}
			return 0
		})

		setCombinedData(sortedData)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	const handleClearFilter = () => {
		setFilterValues({})
	}

	const handlerollback = () => {
		setSelectedItems([])
		setCombinedData(bodyData)
	}
	const handlepost = () => {
		// Filter the combinedData to include only records with "UPDATE" or "INSERT" dboperations
		const filteredData = combinedData.filter(
			(item) =>
				item.dboperation === "UPDATE" || item.dboperation === "INSERT"
		)
		console.log(filteredData)

		// Make the POST request with the filtered data
		putbrisquepanel(JSON.stringify(filteredData))
			.then(function (message) {
				// Handle success case
				console.log("Success:", message)
				// Display a popup message with the returned message
				window.alert("Success: " + message)
				// Refresh body data by updating a state variable
				setRefreshData(true)
				// Further processing if needed
			})
			.catch(function (error) {
				// Handle error case
				console.error("Error:", error)
				// Display a popup message with the error
				window.alert("Error: " + error.message)
				// Further error handling if needed
			})
	}

	const toggleCellExpansion = (rowIndex, columnName) => {
		setExpandedCells((prevExpandedCells) => {
			const newExpandedCells = { ...prevExpandedCells }
			const currentExpansionState =
				newExpandedCells[rowIndex]?.[columnName]
			newExpandedCells[rowIndex] = {
				...newExpandedCells[rowIndex],
				[columnName]: !currentExpansionState,
			}
			return newExpandedCells
		})
	}

	// Faize Added
	const handleComment = () => {
		const selectedRowIndex = isRowSelected.findIndex(
			(isSelected) => isSelected
		)

		if (selectedRowIndex !== -1) {
			const selectedRowUid = bodyData[selectedRowIndex].brisquescreenuid
			console.log("rowuidincontext in DetailPanel is ", selectedRowUid)
			onRowSelect(selectedRowUid)

			console.log("Show Clicked")
		} else {
			// selectedRowIndex = null;
			onRowSelect(null)
			alert("Please select a row before commenting.")
		}
	}
	const isActionAvailable = (actionName) => {
		return actions.some((action) => action.uxactionname === actionName)
	}

	const handleValue = (event, newValue) => {
		if (newValue === "Group") {
			const selectedDataArray = [
				...selectedItems.map((index) => combinedData[index]),
			]
			console.log("bhagesh", selectedDataArray)

			if (selectedDataArray.length === 1) {
				setValue(newValue)
				setPanelData(selectedDataArray)
			} else if (selectedDataArray.length > 1) {
				alert("Select only one panel.")
			} else {
				alert("Select a panel.")
			}
		} else if (newValue === "Panel") {
			// Logic for handling "Panel"
			// setSelectedItems([])
			setValue(newValue)
			// Add your "Panel" specific logic here
		}
	}

	return (
		<div>
			<Accordion expanded={expanded} onChange={toggleAccordion}>
				<AccordionSummary
					sx={{ backgroundColor: "#34B5F4" }}
					expandIcon={expanded ? <Remove /> : <Add />}>
					<Typography
						variant="h7"
						sx={{ flexGrow: "1", textAlign: "center" }}>
						Display of Details Panel for the context
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div>
						<ToggleButtonGroup
							color="primary"
							value={value}
							exclusive
							onChange={handleValue}
							aria-label="Toggle buttons">
							<ToggleButton value="Panel" aria-label="Panel">
								Panel
							</ToggleButton>
							<ToggleButton value="Group" aria-label="Group">
								Group
							</ToggleButton>
						</ToggleButtonGroup>

						<Paper sx={{ width: "100%", overflow: "auto" }}>
							<TableContainer sx={{ maxHeight: 440 }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow
											sx={{ backgroundColor: "#4169e1" }}>
											<TableCell>{"Select"}</TableCell>
											{headerData
												.filter(
													(column) =>
														column.columndisplayname !==
														"NODISPLAY"
												)
												.map((column) => (
													<TableCell
														key={
															column.columndisplayshortname
														}>
														<div>
															<TableSortLabel
																active={
																	orderBy ===
																	column.columndisplayshortname
																}
																direction={
																	orderBy ===
																	column.columndisplayshortname
																		? order
																		: "asc"
																}
																onClick={() =>
																	handleSortRequest(
																		column.columndisplayshortname
																	)
																}>
																{
																	column.columndisplayname
																}
															</TableSortLabel>
															{isFilterActive && (
																<input
																	type="text"
																	value={
																		filterValues[
																			column
																				.columndisplayshortname
																		] || ""
																	}
																	onChange={(
																		e
																	) =>
																		setFilterValues(
																			{
																				...filterValues,
																				[column.columndisplayshortname]:
																					e
																						.target
																						.value,
																			}
																		)
																	}
																	placeholder={`Filter ${column.columndisplayname}`}
																/>
															)}
														</div>
													</TableCell>
												))}
										</TableRow>
									</TableHead>
									<TableBody>
										{/* Rendering bodyData */}
										{combinedData &&
											combinedData.length > 0 &&
											combinedData
												.filter((row) =>
													headerData.some(
														(column) =>
															column.columndisplayname !==
																"NODISPLAY" &&
															Object.keys(
																filterValues
															).every(
																(key) =>
																	column.columndisplayshortname ===
																		key &&
																	row[
																		column
																			.columndisplayshortname
																	]

																		.toString()
																		.toLowerCase()
																		.includes(
																			filterValues[
																				key
																			].toLowerCase()
																		)
															)
													)
												)
												.map((row, rowIndex) => (
													<StyledTableRow
														key={`row-${rowIndex}`}
														dboperation={
															row.dboperation
														}>
														<EditedTableCell>
															<Checkbox
																checked={
																	selectedItems.includes(
																		rowIndex
																	) &&
																	isRowSelected[
																		rowIndex
																	]
																}
																onClick={() => {
																	handleCheckboxClick(
																		rowIndex
																	)
																	const newSelection =
																		[
																			...isRowSelected,
																		]

																	newSelection[
																		rowIndex
																	] =
																		!newSelection[
																			rowIndex
																		]
																	setIsRowSelected(
																		newSelection
																	)
																}}
															/>
														</EditedTableCell>
														{headerData
															.filter(
																(column) =>
																	column.columndisplayname !==
																	"NODISPLAY"
															)
															.map(
																(
																	column,
																	colIndex
																) => {
																	const cellKey = `cell-${rowIndex}-${colIndex}`
																	const cellValue =
																		row[
																			column
																				.columndisplayshortname
																		]
																	const isOverLimit =
																		cellValue &&
																		cellValue.length >
																			100
																	const isExpanded =
																		expandedCells[
																			rowIndex
																		]?.[
																			column
																				.columndisplayshortname
																		] ||
																		false

																	const displayedValue =
																		isExpanded
																			? cellValue
																			: `${
																					cellValue &&
																					cellValue.slice(
																						0,
																						100
																					)
																			  }${
																					isOverLimit
																						? ""
																						: ""
																			  }`

																	const handleToggleExpansion =
																		() => {
																			toggleCellExpansion(
																				rowIndex,
																				column.columndisplayshortname
																			)
																		}

																	return (
																		<EditedTableCell
																			key={
																				cellKey
																			}
																			dboperation={
																				row.dboperation
																			}>
																			{
																				displayedValue
																			}
																			{isOverLimit && (
																				<span
																					style={{
																						color: "blue",
																						cursor: "pointer",
																					}}
																					onClick={
																						handleToggleExpansion
																					}>
																					{isExpanded
																						? " Read less"
																						: " ..."}
																				</span>
																			)}
																		</EditedTableCell>
																	)
																}
															)}
													</StyledTableRow>
												))}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={
									combinedData !== null
										? combinedData.length
										: 0
								} // Check if combinedData is not null before accessing its length
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Paper>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "12px",
							}}>
							<div>
								<Button
									variant="contained"
									sx={{ marginLeft: "15px" }}
									color="primary"
									onClick={() => handleClick()}
									disabled={!isActionAvailable("edit")}>
									Edit
								</Button>
								<Button
									variant="contained"
									sx={{ marginLeft: "15px" }}
									disabled={
										!isActionAvailable("add") ||
										selectedItems.length > 0
									}
									onClick={handleAddRow}>
									Add
								</Button>
							</div>
							<div>
								<Button
									variant="contained"
									sx={{ marginLeft: "15px" }}
									color="primary"
									onClick={handlepost}
									disabled={!isActionAvailable("post")}>
									Post
								</Button>
								<Button
									variant="contained"
									sx={{ marginLeft: "15px" }}
									color="primary"
									onClick={handlerollback}
									disabled={!isActionAvailable("rollback")}>
									Rollback
								</Button>
							</div>
							<div>
								<Button
									variant="contained"
									sx={{ marginLeft: "15px" }}
									color="primary"
									onClick={() =>
										setIsFilterActive(!isFilterActive)
									}
									disabled={!isActionAvailable("filter")}>
									{isFilterActive
										? "Hide Filter"
										: "Show Filter"}
								</Button>
								<Button
									variant="contained"
									sx={{
										marginLeft: "15px",
										marginRight: "15px",
									}}
									color="primary"
									onClick={handleClearFilter}
									disabled={
										!isActionAvailable("clearfilter")
									}>
									Clear Filter
								</Button>
								<Button
									variant="contained"
									sx={{ marginRight: "30px" }}
									color="primary"
									onClick={handleComment}
									disabled={
										!isActionAvailable("showcomments") ||
										!isRowSelected
									}>
									Show Comment
								</Button>
							</div>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}>
				<Typography sx={{ p: 2 }}>
					The content of the Popover.
				</Typography>
			</Popover>
		</div>
	)
}

export default DetailsPanel
