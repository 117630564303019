import React from "react";
import { useEffect, createContext  } from "react";

//BrowserRouter
//import { HashRouter as Router, Route } from "react-router-dom";
import Layouts from "./Layouts";
// import Login from "./Login";
import { Routes, Route,BrowserRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AppsIcon from "@mui/icons-material/Apps";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import Login from "./AuthenticateStdUser/Login";
import WelcomeScreeen from './AuthenticateStdUser/WelcomeScreeen';
import ForgotUserScreen from './AuthenticateStdUser/ForgotUserScreen';
import ForgotPassScreen from './AuthenticateStdUser/ForgotPassScreen';
import ResetPassScreen from './AuthenticateStdUser/ResetPassScreen';
import CreateLink from './AuthenticateStdUser/CreateLink';
export default function App() {
  // useEffect(() => {
  //   document.body.style.zoom = "85%";
  // }, []);



  const Container = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "Left",
    color: "black",
    fontWeight: "bold",
    marginTop:"50px"

    // color: theme.palette.text.secondary,
  }));

  const [open, setOpen] = React.useState(true);
  const [displayBreadCrumbs, setDisplayBreadCrumbs] = React.useState([]);
  const toggleDrawer = () => {
    setOpen(!open);
    
  };

  const handledisplayBreadcums = (event) => {
    setDisplayBreadCrumbs(event);
  };
  console.log(process.env.REACT_APP_API_KEY)  
  console.log("environment " + process.env.REACT_APP_BASEURL);
  return (

    <div>
       
      {/* <Routes>
      <Route exact path={"/"} component={Login} />
         <Route path="/layouts/*" element={<Layouts openRC={open} displayBreadCrumbs={handledisplayBreadcums} />} /> 

        <Route path="*" element={<div>Not found </div>} />
      </Routes> */}

       <BrowserRouter>
    <Routes>
     
       <Route exact path="/" element={<Login/>} /> 
       <Route path="/layouts/*" element={<Layouts  displayBreadCrumbs={handledisplayBreadcums} />} /> 
        <Route path="/create_link/*" element={<CreateLink/>} /> 
      <Route path="/forgot_usename/*" element={<ForgotUserScreen/>} /> 
       <Route path="/forgot_pass/*" element={<ForgotPassScreen/>} /> 
       <Route path="/reset_pass/*" element={<ResetPassScreen/>} /> 
      <Route path="/welcome/*" element={<WelcomeScreeen/>} /> 
      
      </Routes>
 
   </BrowserRouter>
    </div>
  
  );
}
