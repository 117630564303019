
import axios from "axios";
import * as q from "q";
 
export function getComments(rowuidincontext) {
  var defer = q.defer();

  var url = process.env.REACT_APP_BASEURL + "getPanelComments";
  // var url = "http://localhost:3100/getPanelComments";
  // console.log("CommentAction  " + url);
  // console.log("getComment RowUUID from CommentAction ", rowuidincontext);
  axios
    .post(url, {
      rowuidincontext: rowuidincontext,
    })
    .then(function (response) {
      defer.resolve(response);
    })
    .catch(function (error) {
      defer.reject(error);
    });
  return defer.promise;
}
 
export const createComment = async (text, parentId, selectedRowId, userId, userName) => {
  try {
    
    const endpoint =  process.env.REACT_APP_BASEURL +"putPanelRecordComments";
    const tableName = "brisqueusercomments";
 
    const currentDateTime = new Date().toISOString();
    const formattedDateTime = currentDateTime.replace("T", " ").split(".")[0];
 
    const payload = [
      {
        recno: 1,
        dboperation: "INSERT",
        usercommentuid: 0,
        priorusercommentuid: parentId, // Set priorusercommentuid to parentId
        enterpriseuid: 1,
        brisqueuseruid: userId,
        commentapiname: "GetPanelRecordComments",
        usercommenttype: "GENERIC",
        usercomment: text,
        usercommentext: " ",
        brisquepaneluid: 3, //Isko bii detailPanel se lana pdega = PanelUID jo rowuidincontext ke sath detailPanel se grabe kiya tha
        tablename: "brisqueusercomments",
        rowuidincontext: selectedRowId, // Set rowuidincontext to selectedRowId
        relatedusergroup: "DEFAUT",
        activeflag: "Y",
        createdby: userName,
        updatedby: null,
        createddate: formattedDateTime,
        updateddate: null,
      },
    ];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Comment posted successfully!");
      console.log("API Response:", result);
      return result.createdComment;
    } else {
      console.error(`Error posting comment. Error code: ${result.error_code}`);
      return null;
    }
  } catch (error) {
    console.error("Error creating comment:", error);
    return null;
  }
};
 
export const updateComment = async (text, usercommentuid, selectedRowId, userName) => {
  try {
    console.log("USERNAME Update" ,userName);
    const endpoint = process.env.REACT_APP_BASEURL + "putPanelRecordComments";
    const tableName = "brisqueusercomments";
 
    // Fetch the existing comment details
    const existingCommentResponse = await axios.post(
      "http://localhost:3100/getPanelComments",
      {
        rowuidincontext: selectedRowId,
        usercommentuid: usercommentuid,
      }
    );
 
    // Assuming the response data is stored in the variable named 'response'
    const existingCommentArray = existingCommentResponse.data;
 
    const usercommentuidToFind = usercommentuid;
 
    const existingComment = existingCommentArray.find(
      (obj) => obj.usercommentuid === usercommentuidToFind
    );
 
    // Now 'existingComment' contains the last object in the array
    console.log("Existing Comment:", existingComment);
 
    // Modify this line to format both created and updated dates
    const formattedCreatedDateTime = existingComment.createddate
      .replace("T", " ")
      .replace(/\.\d+Z$/, "")
      .replace(" ", " ");
    const formattedUpdatedDateTime = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
 
    // Create a copy of the existing comment with updated text and other necessary changes
    const updatedComment = {
      ...existingComment,
      dboperation: "UPDATE",
      usercomment: text,
      updatedby: userName,
      updateddate: formattedUpdatedDateTime,
      createddate: formattedCreatedDateTime,
    };
 
    const payload = [updatedComment];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Comment updated successfully!");
      console.log("API Response:", result);
      return result.updatedComment || updatedComment; // Use the API response, fallback to the local update if not provided
    }
    else {
      console.error(`Error updating comment. Error code: ${result.error_code}`);
      return null;
    }
  }
  catch (error) {
    console.error("Error updating comment:", error);
    return null;
  }
};
 
export const deleteComment = async (usercommentuid, selectedRowId, userName) => {
  try {
    const endpoint = process.env.REACT_APP_BASEURL + "putPanelRecordComments";
    const tableName = "brisqueusercomments";
 
    // Fetch the existing comment details
    const existingCommentResponse = await axios.post(
      process.env.REACT_APP_BASEURL + "getPanelComments",
      {
        rowuidincontext: selectedRowId,
        usercommentuid: usercommentuid,
      }
    );
 
    // Assuming the response data is stored in the variable named 'response'
    const existingCommentArray = existingCommentResponse.data;
 
    const usercommentuidToFind = usercommentuid;
 
    const existingComment = existingCommentArray.find(
      (obj) => obj.usercommentuid === usercommentuidToFind
    );
 
    // Now 'existingComment' contains the last object in the array
    console.log("Existing Comment:", existingComment);
 
    // Modify this line to format both created and updated dates
    const formattedCreatedDateTime = existingComment.createddate
      .replace("T", " ")
      .replace(/\.\d+Z$/, "")
      .replace(" ", " ");
    const formattedUpdatedDateTime = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
    // Create a copy of the existing comment with necessary changes for DELETE operation
    const deletedComment = {
      ...existingComment,
      dboperation: "DELETE",
      updatedby: userName,
      updateddate: formattedUpdatedDateTime,
      createddate: formattedCreatedDateTime,
    };
 
    const payload = [deletedComment];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Comment deleted successfully!");
      console.log("API Response:", result);
      return result.deletedComment;
    }
    else {
      console.error(`Error deleting comment. Error code: ${result.error_code}`);
      return null;
    }
  }
  catch (error) {
    console.error("Error deleting comment:", error);
    return null;
  }
};
 