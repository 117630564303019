
import axios from "axios";
import * as q from "q";
 
export function getBrisqueOrganizationDetails( searchMode, searchOperator, enterpriseOrgName, mainContactFullName ) {
  var defer = q.defer();
  var url = process.env.REACT_APP_BASEURL + "getBrisqueOrganizationDetails";
  // var url = "http://localhost:3100/getBrisqueOrganizationDetails";
 
  // Check if searchOperator is empty, then assign "OR"
  if (searchOperator === "") {
    searchOperator = "OR";
  }
 
  console.log("BrisqueOrganizationDetailsAction " + searchMode, searchOperator, enterpriseOrgName, mainContactFullName);
 
  axios
    .post(url, {
      searchMode: searchMode,
      searchOperator: searchOperator,
      enterpriseOrgName: enterpriseOrgName,
      mainContactFullName: mainContactFullName
    })
    .then(function (response) {
      defer.resolve(response);
    })
    .catch(function (error) {
      defer.reject(error);
    });
  return defer.promise;
}
 
export const createOrganizationDetails = async (
  companyName,
  legalName,
  corporateUrl,
  logo,
  dunsNumber,
  mainContactName,
  mainContactEmail,
  addressLine1,
  addressLine2,
  addressLine3,
  city,
  state,
  countryDistrict,
  country,
  zipCode,
  startDate,
  endDate,
  billableFlag
) => {
  try {
    const endpoint =  process.env.REACT_APP_BASEURL + "putBrisqueOrganizationDetails";
    const tableName = "brisqueenterpriseorganization";
 
    const currentDateTime = new Date().toISOString();
    const formattedDateTime = currentDateTime.replace("T", " ").split(".")[0];
 
    const payload = [
      {
        recno: 1,
        dboperation: "INSERT",
        enterpriseuid: 1,
        enterpriseorgname: companyName,
        logo: logo,
        legalname: legalName,
        dunsnumber: dunsNumber,
        ultimatedunsnumber: null,
        corporateurl: corporateUrl,
        addressline1: addressLine1,
        addressline2: addressLine2,
        addressline3: addressLine3,
        citytown: city,
        stateprovince: state,
        countydistrict: countryDistrict,
        country: country,
        zippostcode: zipCode,
        maincontactfullname: mainContactName,
        maincontactemail: mainContactEmail,
        billableflag: billableFlag,
        datestart: startDate,
        dateend: endDate,
        createdby: "Faize",
        updatedby: null,
        createddate: formattedDateTime,
        updateddate: null,
      },
    ];
    console.log("payload ADD COM ", payload);
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code > 0 ) {
      console.log("Organization details posted successfully!");
      console.log("API Response:", result);
      return result.createdDetails;
    } else {
      console.error(
        `Error posting organization details. Error code: ${result.error_code}`
      );
      return null;
    }
  } catch (error) {
    console.error("Error creating organization details:", error);
    return null;
  }
};
 
export const updateOrganizationDetails = async (
  editedData
) => {
  try {
    const endpoint =  process.env.REACT_APP_BASEURL + "putBrisqueOrganizationDetails";
    const tableName = "brisqueenterpriseorganization";
 
    const currentDateTime = new Date().toISOString();
    const formattedDateTime = currentDateTime.replace("T", " ").split(".")[0];
    const formattedCreatedDateTime = editedData.createddate
      .replace("T", " ")
      .replace(/\.\d+Z$/, "")
      .replace(" ", " ");
    const updatedPayload = {...editedData, dboperation: "UPDATE", updatedby: "Faize", updateddate: formattedDateTime, createddate: formattedCreatedDateTime}
 
    const payload = [
      updatedPayload,
     
    ];
    console.log("payload ADD COM ", payload);
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code > 0 ) {
      console.log("Organization details posted successfully!");
      console.log("API Response:", result);
      return result.createdDetails;
    } else {
      console.error(
        `Error posting organization details. Error code: ${result.error_code}`
      );
      return null;
    }
  } catch (error) {
    console.error("Error creating organization details:", error);
    return null;
  }
};