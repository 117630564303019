import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '../src/Components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./Components/AuthenticateStdUser/Login"
import WelcomeScreeen from './Components/AuthenticateStdUser/WelcomeScreeen';
import ForgotUserScreen from './Components/AuthenticateStdUser/ForgotUserScreen';
import ForgotPassScreen from './Components/AuthenticateStdUser/ForgotPassScreen';
import ResetPassScreen from './Components/AuthenticateStdUser/ResetPassScreen';
import CreateLink from './Components/AuthenticateStdUser/CreateLink';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <App />
  // <BrowserRouter>
  //   <Routes>
    
  //       <Route path="/login/*" element={<Login/>} /> 
  //       <Route path="/create_link/*" element={<CreateLink/>} /> 
  //       <Route path="/forgot_usename/*" element={<ForgotUserScreen/>} /> 
  //       <Route path="/forgot_pass/*" element={<ForgotPassScreen/>} /> 
  //       <Route path="/reset_pass/*" element={<ResetPassScreen/>} /> 
  //       <Route path="/welcome/*" element={<WelcomeScreeen/>} /> 
  //        <Route path="/*" element={<App/>} /> 
  //       <Route path="*" element={<div>Not found </div>} />
  //     </Routes>
 
  // </BrowserRouter>
   
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
