
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { createOrganizationDetails as createOrganizationDetailsApi } from "../../../action/OrganizationDetailsAction";
 
function AddNewCompany({ open, onClose }) {
  const [formData, setFormData] = useState({
    recno: 1,
    dboperation: "",
    enterpriseuid: 1,
    enterpriseorgname: "",
    logo: "",
    legalname: "",
    dunsnumber: "",
    ultimatedunsnumber: "",
    corporateurl: "",
    addressline1: "",
    addressline2: "",
    addressline3: "",
    citytown: "",
    stateprovince: "",
    countydistrict: "",
    country: "",
    zippostcode: "",
    maincontactfullname: "",
    maincontactemail: "",
    billableflag: "",
    datestart: "",
    dateend: "",
    createdby: "",
    updatedby: "",
    createddate: "",
    updateddate: "",
  });
 
  // console.log("formData", formData);
 
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      logo: file,
    }));
  };
 
  // const handleAdd = () => {
  //   createOrganizationDetailsApi(
  //     formData.enterpriseorgname,
  //     formData.legalname,
  //     formData.corporateurl,
  //     formData.logo,
  //     formData.dunsnumber,
  //     formData.maincontactfullname,
  //     formData.maincontactemail,
  //     formData.addressline1,
  //     formData.addressline2,
  //     formData.addressline3,
  //     formData.citytown,
  //     formData.stateprovince,
  //     formData.countydistrict,
  //     formData.country,
  //     formData.zippostcode,
  //     formData.datestart,
  //     formData.dateend,
  //     formData.billableflag
  //   )
  //     .then((createdDetails) => {
  //       // Handle success
  //       console.log("Organization details posted successfully!");
  //       console.log("Created Details:", createdDetails);
  //       // Optionally, you can update state or perform any other actions here
  //       onClose(); // Close the dialog
  //     })
  //     .catch((error) => {
  //       // Handle error
  //       console.error("Error creating organization details:", error);
  //       // Optionally, you can show an error message to the user
  //     });
  // };
 
  const handleAdd = () => {
    // Check if any mandatory field is empty
    const mandatoryFields = [
      'enterpriseorgname',
      'legalname',
      'maincontactfullname',
      'maincontactemail',
      'addressline1',
      'citytown',
      'stateprovince',
      'country',
      'zippostcode',
      'datestart',
      'dateend',
      'billableflag'
    ];
 
    const isAnyFieldEmpty = mandatoryFields.some(field => !formData[field]);
 
    if (isAnyFieldEmpty) {
      // Display error message or handle empty fields as required
      alert("Please fill in all mandatory fields.");
      return;
    }
 
    // Proceed with form submission
    createOrganizationDetailsApi(
          formData.enterpriseorgname,
          formData.legalname,
          formData.corporateurl,
          formData.logo,
          formData.dunsnumber,
          formData.maincontactfullname,
          formData.maincontactemail,
          formData.addressline1,
          formData.addressline2,
          formData.addressline3,
          formData.citytown,
          formData.stateprovince,
          formData.countydistrict,
          formData.country,
          formData.zippostcode,
          formData.datestart,
          formData.dateend,
          formData.billableflag
    )
      .then((createdDetails) => {
        // Handle success
        console.log("Organization details posted successfully!");
        console.log("Created Details:", createdDetails);
        // Optionally, you can update state or perform any other actions here
        onClose(); // Close the dialog
      })
      .catch((error) => {
        // Handle error
        console.error("Error creating organization details:", error);
        // Optionally, you can show an error message to the user
      });
      setFormData({
        recno: 1,
        dboperation: "",
        enterpriseuid: 1,
        enterpriseorgname: "",
        logo: "",
        legalname: "",
        dunsnumber: "",
        ultimatedunsnumber: "",
        corporateurl: "",
        addressline1: "",
        addressline2: "",
        addressline3: "",
        citytown: "",
        stateprovince: "",
        countydistrict: "",
        country: "",
        zippostcode: "",
        maincontactfullname: "",
        maincontactemail: "",
        billableflag: "",
        datestart: "",
        dateend: "",
        createdby: "",
        updatedby: "",
        createddate: "",
        updateddate: "",
      })
  };
 
 
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{ color: "#4169e1", flexGrow: "1", textAlign: "center" }}
      >
        Add New Record
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Company Name"
              name="enterpriseorgname"
              value={formData.enterpriseorgname}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Legal Name"
              name="legalname"
              value={formData.legalname}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Corporate URL"
              name="corporateurl"
              value={formData.corporateurl}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Logo"
              type="file"
              name="logo"
              onChange={(e)=>handleFileChange(e)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { accept: "image/*" },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="D-U-N-S Number"
              name="dunsnumber"
              value={formData.dunsnumber}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" sx={{ color: "#4169e1" }}>
              Primary Contact Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Name"
              name="maincontactfullname"
              value={formData.maincontactfullname}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="email"
              label="Email"
              name="maincontactemail"
              value={formData.maincontactemail}
              onChange={handleChange}
              fullWidth
              margin="normal"
              inputProps={{ pattern: "[^s@]+@[^s@]+.[^s@]+" }} // Specify a regex pattern for email validation
              required // Mark the field as required
            />
          </Grid>
          {/* Postal Address */}
          <Grid item xs={12}>
            <Typography variant="h6" align="center" sx={{ color: "#4169e1" }}>
              Postal Address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Street Address1"
              name="addressline1"
              value={formData.addressline1}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Street Address2"
              name="addressline2"
              value={formData.addressline2}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Street Address3"
              name="addressline3"
              value={formData.addressline3}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="City"
              name="citytown"
              value={formData.citytown}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="State"
              name="stateprovince"
              value={formData.stateprovince}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="County District"
              name="countydistrict"
              value={formData.countydistrict}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Zip Code/Pin Code"
              name="zippostcode"
              value={formData.zippostcode}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              label="Start Date"
              name="datestart"
              value={formData.datestart}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              label="End Date"
              name="dateend"
              value={formData.dateend}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
          </Grid>
 
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.billableflag}
                  onChange={(event) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      billableflag: event.target.checked,
                    }))
                  }
                  name="billableflag"
                />
              }
              label="Billable Flag"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
 
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
 
export default AddNewCompany;
 