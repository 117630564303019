
import React from "react";
import "../../style.css";
import Box from "@mui/material/Box";
import { useState } from "react";
import SearchPanel from "./SearchPanel";
import DetailsPanel from "./DetailsPanel";
import EditPanel from "./EditPanel";
 
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
 
export default function EnterpriseOnboarding({
  onQueryRC,
  query,
  pathshow,
  onRowSelect,
  onRowSelectNotes,
  leftNavigationSelectedScreenName,
  leftNavigationSelectedScreenUid,
}) {
 
  console.log("ScreenName AND ScreenNameUID from topBox " + leftNavigationSelectedScreenName, leftNavigationSelectedScreenUid);
 



  const [isChecked, setIsChecked] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [locationResults, setLocationResults] = useState([]);
  console.log("searchResults in TopBox", searchResults);
  const [headerData, setHeaderData] = useState([]);
  const [bodyDataEdit, setbodyDataEdit] = useState([]);
  const [updateEditData, setUpdateEditData] = useState([]);
  const [editData, setEditData] = useState([]);
 
 
  const ToggleCheckBox = () => {
    setIsChecked(!isChecked);
  };
 
  const handleAddRow = () => {
    const emptyRow = {}
 
    // Add default keys
    emptyRow["recno"] = null // Default value for integer
    emptyRow["dboperation"] = "" // Default value for string
 
    headerData.forEach((item) => {
        let defaultValue
 
        switch (item.jsontype) {
            case "integer":
                defaultValue = null
                break
            case "string":
                defaultValue = ""
                break
            case "timestamp":
                defaultValue = null // or new Date() if you want current timestamp
                break
            default:
                defaultValue = null
        }
 
        emptyRow[item.columndisplayshortname] = defaultValue
    })
 
    setbodyDataEdit((prevbodyDataEdit) => {
        return [...prevbodyDataEdit, emptyRow]
    })
}
 
  return (
    <div>
 
      <Box sx={{ p: 1,paddingTop: "70px"  }} gridColumn="span 9">
        <SearchPanel setSearchResults={setSearchResults} setLocationResults={setLocationResults} />
      </Box>
      <Box sx={{ p: 1 }} gridColumn="span 9">
        <DetailsPanel searchResults={searchResults} locationResults={locationResults} handleAddRow={handleAddRow} headerData={headerData} setHeaderData={setHeaderData} setbodyDataEdit={setbodyDataEdit} updateEditData={updateEditData} setEditData={setEditData}/>
      </Box>
      <Box sx={{ p: 1 }} gridColumn="span 9">
        <EditPanel headerData={headerData} bodyDataEdit={bodyDataEdit} setbodyDataEdit={setbodyDataEdit} setUpdateEditData={setUpdateEditData} editData={editData} setEditData={setEditData}/>
      </Box>
 
 
    </div>
  );
}
 