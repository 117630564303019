import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getLeftNavigationPanel as getLeftNavigationPanelApi } from "../action/NavigationAction";
import { useNavigate } from "react-router-dom";
import ScreenDesignCheck from "./ScreenDesignCheck"



const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const ResizableHandle = styled("div")({
  width: "5px",
  height: "100%",
  position: "absolute",
  top: 0,
  right: 0,
  cursor: "ew-resize", // Change cursor on hover
  zIndex: 1000,
});

const NavItem = styled(ListItemButton)(({ theme }) => ({
  "& .MuiListItemText-root": {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const SubNavItem = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  "& .MuiListItemText-root": {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const GrandSubNavItem = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  "& .MuiListItemText-root": {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export default function SideBar({
  openstate,
  onSelectBreaCrumbs,
  onScreenNameSelect,
  onScreenUidSelect,
}) {
  const [openMenus, setOpenMenus] = useState({});
  const [openMenuSecond, setOpenMenuSecond] = useState({});
  const [menubardata, setMenubarData] = useState([]);
  const [isResizing, setIsResizing] = useState(false);
  const [initialX, setInitialX] = useState(0);
  const [drawerWidth, setDrawerWidth] = useState(240);
  const [breadcrumbs, setBreadcrumbs] = useState([]); // New state for breadcrumbs
  const MAX_DRAWER_WIDTH = 400;

  useEffect(() => {
    fetchMenuData();
  }, []);

  const navigate = useNavigate();
 
  const fetchMenuData = () => {
    getLeftNavigationPanelApi()
      .then((response) => {
        setMenubarData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  };

  const handleMouseDown = (e) => {
    if (e.nativeEvent.which === 1) {
      setIsResizing(true);
      setInitialX(e.clientX);
    }
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = Math.max(
        100,
        Math.min(drawerWidth + e.clientX - initialX, MAX_DRAWER_WIDTH)
      );
      setDrawerWidth(newWidth);
      setInitialX(e.clientX);
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleDoubleClick = () => {
    setDrawerWidth((prevWidth) => (prevWidth === 240 ? MAX_DRAWER_WIDTH : 240));
  };

  const handleMenuItemClick = (index,level1Key ) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [index]: !prevOpenMenus[index],
      
    }));
    setBreadcrumbs([level1Key]); // Set level 1 key as initial breadcrumb
  
  };
  onSelectBreaCrumbs(breadcrumbs);

  const handleSubMenuItemClick = async(level2uid, subIndex) => {
    setOpenMenuSecond((prevOpenMenuSecond) => ({
      ...prevOpenMenuSecond,[subIndex]: !prevOpenMenuSecond[subIndex],
    }));

    let prevBreadcrumbs = [...breadcrumbs]
    let previous = prevBreadcrumbs;
    previous[1] = level2uid;
    setBreadcrumbs(previous)
    onSelectBreaCrumbs(previous);
    console.log("Breadcrums" +  " " + breadcrumbs )

    // await setBreadcrumbs((prevBreadcrumbs) => {
    //   let previous = prevBreadcrumbs;
    //   previous[1] = level2uid;
    //   setBreadcrumbs(previous)
    //   onSelectBreaCrumbs(previous);
    //   console.log("Breadcrums" +  " " + breadcrumbs )
    //   // if (prevBreadcrumbs[1] !== level2uid) {
    //   //   // Clear the first element if it's different from the new value
    //   //   // setBreadcrumbs.push(level2uid)
    //   //   return [...prevBreadcrumbs, level2uid];
    //   // } 
    // });
   
 
    // setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, level2uid]);
    // Add level 2 key to breadcrumbs
  }
//  onSelectBreaCrumbs(breadcrumbs);
  const leftNavigationSelectedScreenNameHandler = (screenName) => {
    onScreenNameSelect(screenName);
     ScreenDesignCheck(screenName)
     navigate("/layouts/" + screenName);

  };
 
  

  const leftNavigationSelectedScreenUidHandler = (screenUid) => {
    onScreenUidSelect(screenUid);
  };

  const breadCrumbsHandlerThirdLevel = (thirdLevelItem) => {
    let prevBreadcrumbs = [...breadcrumbs]
    let previous = prevBreadcrumbs;
    previous[2] = thirdLevelItem;
    setBreadcrumbs(previous)
    onSelectBreaCrumbs(previous);
    console.log("Breadcrums" +  " " + breadcrumbs )
    // let data = JSON.stringify(thirdLevelItem)
    // console.log("thirdLevelItem" +  " " + data )
    // setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, thirdLevelItem]);
    // onSelectBreaCrumbs(breadcrumbs);
  };

 

  const groupedMenuItems = menubardata.reduce((acc, item) => {
    const level1Key = item.level1brisquefeaturedisplayname;
    const level2Key = item.level2brisquefeaturedisplayname;

    if (!acc.has(level1Key)) {
      acc.set(level1Key, new Map());
    }

    const level1Map = acc.get(level1Key);

    if (!level1Map.has(level2Key)) {
      level1Map.set(level2Key, []);
    }

    const level2Array = level1Map.get(level2Key);

    level2Array.push(item);

    return acc;
  }, new Map());

  const handleResize = (event, { size }) => {
    setDrawerWidth(size.width);
  };

  return (
    <div className="leftSidebar">
      <CssBaseline />
 
        <Drawer
          PaperProps={{
            sx: { width: drawerWidth },
          }}
          open={openstate}
          variant={"persistent"}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <DrawerHeader>
            <ResizableHandle
              onMouseDown={handleMouseDown}
              onDoubleClick={handleDoubleClick}
            />
          </DrawerHeader>
          <Divider />
          <List disablePadding dense component="nav">
            <ListSubheader component="div" id="nested-list-subheader">
              Menu
            </ListSubheader>
            {[...groupedMenuItems].map(([level1Key, level1Map], index) => (
              <div key={index}>
                <NavItem onClick={() => handleMenuItemClick(index, level1Key)}>
                  <ListItemText primary={level1Key} style={{ color: "red" }} />
                  {openMenus[index] ? (
                    <IconButton size="small">
                      <ExpandLessIcon />
                    </IconButton>
                  ) : (
                    <IconButton size="small">
                      <ExpandMoreIcon />
                    </IconButton>
                  )}
                </NavItem>
                {openMenus[index] && (
                  <List>
                    {[...level1Map].map(
                      ([level2Key, level2Array], subIndex) => (
                        <div key={subIndex}>
                          <SubNavItem
                            onClick={() =>
                              handleSubMenuItemClick(level2Key, subIndex)
                            }
                          >
                            <ListItemText
                              primary={level2Key}
                              style={{ color: "green" }}
                            />
                            {openMenuSecond[subIndex] ? (
                              <IconButton size="small">
                                <ExpandLessIcon />
                              </IconButton>
                            ) : (
                              <IconButton size="small">
                                <ExpandMoreIcon />
                              </IconButton>
                            )}
                          </SubNavItem>
                          {openMenuSecond[subIndex] && (
                            <List>
                              {level2Array.map((thirdLevelItem, thirdIndex) => (
                                <GrandSubNavItem
                                  key={thirdIndex}
                                  onClick={() => {
                                    leftNavigationSelectedScreenNameHandler(
                                      
                                      thirdLevelItem.brisquescreenname
                                    );
                                    leftNavigationSelectedScreenUidHandler(
                                      thirdLevelItem.brisquescreenuid
                                    );
                                    breadCrumbsHandlerThirdLevel(
                                      thirdLevelItem.brisquescreendisplayname
                                    );
                                  }}
                                >
                                  <ListItemText
                                    primary={
                                      thirdLevelItem.brisquescreendisplayname
                                    }
                                  />
                                </GrandSubNavItem>
                              ))}
                            </List>
                          )}
                        </div>
                      )
                    )}
                  </List>
                )}
              </div>
            ))}
          </List>
          {isResizing && <ResizableHandle />}
        </Drawer>
     
    </div>
  );
}
