
// Note.jsx
 
import React, { useState } from "react";
import NoteForm from "./NoteForm";
import usericon from "../../../../images/user-icon.png";
 
const Note = ({
  note,
  setActiveNote,
  activeNote,
  updateNote,
  deleteNote,
  currentUserId,
  selectedRowId,
}) => {
  console.log(note);
  const [isEditing, setIsEditing] = useState(false);
 
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };
 
  return (
    <div key={note.usernoteuid} className="note">
      <div className="note-image-container">
        <img src={usericon} alt="User Icon" />
      </div>
      <div className="note-right-part">
        <div className="note-content">
          <div className="note-author">{note.createdby}</div>
          <div className="note-date">
            {new Date(note.createddate).toLocaleDateString()}
          </div>
        </div>
        {!isEditing && <pre className="note-text">{note.usernotes}</pre>}
        {isEditing && (
          <NoteForm
            submitLabel="Update"
            handleCancel={() => {
              setActiveNote(null);
              toggleEditing();
            }}
            initialText={note.usernotes}
            handleSubmit={(text) => {
              updateNote(text, note.usernoteuid, selectedRowId);
              toggleEditing();
            }}
          />
        )}
 
        <div className="note-actions">
          {currentUserId === note.usernoteuid && (
            <div className="note-action" onClick={toggleEditing}>
              Edit
            </div>
          )}
          {currentUserId === note.usernoteuid && (
            <div
              className="note-action"
              onClick={() => deleteNote(note.usernoteuid)}
            >
              Delete
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
 
export default Note;
 