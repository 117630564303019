import axios from "axios"
import * as q from "q"
 
export function getbrisqueuserdetails(bodyData, readbodysearchType) {
    var defer = q.defer()
    var url = process.env.REACT_APP_BASEURL + "getbrisqueuserdetails";
    // var url = "http://localhost:3100/getbrisqueuserdetails"
    if(bodyData.searchOperator === ""){
       let searchOperator = "OR"
    
    console.log("bodyData:", bodyData)
    axios
        .post(url, {
            selectedType:readbodysearchType,
            searchOperator:searchOperator,
            organizationName:bodyData.organizationName,
            contactName:bodyData.contactName
        })
        .then(function (response) {
            defer.resolve(response)
        })
        .catch(function (error) {
            defer.reject(error)
        })
 
    return defer.promise
} else{
    axios
        .post(url, {
            selectedType:readbodysearchType,
            searchOperator:bodyData.searchOperator,
            organizationName:bodyData.organizationName,
            contactName:bodyData.contactName
        })
        .then(function (response) {
            defer.resolve(response)
        })
        .catch(function (error) {
            defer.reject(error)
        })
 
    return defer.promise
}
}

export function putbrisqueuserdetails(editedData) {
    var defer = q.defer()
    var url =  process.env.REACT_APP_BASEURL + "putbrisqueuserdetails"
    console.log("editedData:", editedData)
    axios
        .post(url, {
            editedDataBody: editedData,
        })
        .then(function (response) {
            // Check if the response contains a message
            if (response.data && response.data.message) {
                defer.resolve(response.data.message)
            } else {
                // If no message is found, reject with an error
                defer.reject(new Error("No message found in response"))
            }
        })
        .catch(function (error) {
            defer.reject(error)
        })
    return defer.promise
}