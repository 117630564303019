import React, { useState } from "react";
import TodoForm from "./TodoForm";
import { RiCloseCircleLine } from "react-icons/ri";
import { TiEdit } from "react-icons/ti";
import { BsCheckCircle } from "react-icons/bs";
import { FaUndoAlt } from "react-icons/fa";

const Todo = ({
  todo,
  completeTodo,
  removeTodo,
  updateTodo,
  setActiveToDo, // Corrected prop name
  activeToDo, // Corrected prop name
  userUid, // Assuming this prop is passed but not destructured
  currentUserId,
}) => {
  const [isExpandedState, setIsExpandedState] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const maxWords = 20;

  const toggleExpand = () => {
    setIsExpandedState(!isExpandedState);
  };

  const truncateText = (text, maxWords) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    } else {
      return text;
    }
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const isExpanded =
    isExpandedState &&
    activeToDo &&
    activeToDo.usertodouid === todo.usertodouid &&
    activeToDo.type === "expanded";
  const canDelete = currentUserId === todo.usertodouid;
  const canEdit = currentUserId === todo.usertodouid;

  const dueOn = new Date(todo.todoexpecteddateend).toLocaleDateString();

  // Determine the color based on the due date and today's date
  const today = new Date().toLocaleDateString();
  let statusColor = " #4caf50"; // Default color is green
  if (dueOn === today) {
    statusColor = "orange"; // Due today, color is orange
  } else if (dueOn < today) {
    statusColor = "red"; // Overdue, color is red
  }

  return (
    <div className="todo-row" >
      <div className="todo-content">
        {!isEditing && (
          <div className="todo-info">
            <h3 className={`todo-name ${todo.isComplete ? "complete" : ""}`} style={{ color: statusColor }}>
              {todo.todotaskname}
            </h3>
            <div className="todo-date">Due On {dueOn}</div>
          </div>
        )}
        {todo.todotaskdescription && !isEditing && ( // Conditionally render description box if not editing
          <div className="todo-description ">
            <p className="todo-description-text">
              {isExpanded
                ? todo.todotaskdescription
                : truncateText(todo.todotaskdescription, maxWords)}
              {todo.todotaskdescription &&
                todo.todotaskdescription.split(" ").length > maxWords && (
                  <span className="read-more" onClick={toggleExpand}>
                    {isExpanded ? "Read Less" : "Read More"}
                  </span>
                )}
            </p>
          </div>
        )}
        {isEditing ? ( // Conditionally render TodoForm if editing
          <TodoForm
            submitLabel="Update"
            handleCancel={() => {
              setIsEditing(false);
              setActiveToDo(null);
            }}
            initialTaskName={todo.todotaskname}
            initialText={todo.todotaskdescription}
            initialExpectedEndDate={todo.todoexpecteddateend}
            initialExpectedStartDate={todo.todoexpecteddatestart}
            handleSubmit={(taskName, text, expectedEndDate,expectedStartDate) =>
              updateTodo(
                taskName,
                text,
                expectedEndDate,
                expectedStartDate,
                todo.usertodouid,
                userUid
              )
            }
          />
        ) : (
          <div className="todo-actions">
            {canEdit && (
              <div
                className="todo-action edit-icon"
                onClick={() => {
                  setActiveToDo({
                    usertodouid: todo.usertodouid,
                    type: "editing",
                  });
                  setIsEditing(true); // Set isEditing to true when editing starts
                }}
              >
                <TiEdit />
              </div>
            )}
            {canDelete && (
              <div
                className="todo-action delete-icon"
                onClick={() => removeTodo(todo.usertodouid)}
              >
                <RiCloseCircleLine />
              </div>
            )}
            <div
              className="todo-action tick-icon"
              onClick={() => completeTodo(todo.usertodouid)}
            >
              {todo.isComplete ? <FaUndoAlt /> : <BsCheckCircle />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Todo;
