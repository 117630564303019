// src/App.js
import React from 'react';
import PropTypes from 'prop-types';
import TransparentBar from './components/Shapes/TBar/TransparentBar';
import ShapeFlow from './components/Shapes/';
import HorizontalBar from './components/Shapes/TBar/Horizontalbar';
import HorizontalMain from './components/Shapes/TBar/Horizontalmain';
import Bar from './components/Shapes/TBar/ACcordian';
import SVGButton from './components/Shapes/Reactflow/SVGButton';
function App({ initialNodes, initialEdges }) {
  return (
    <div>
      {/* <HorizontalMain/> */}

      <TransparentBar />
      {/* <HorizontalBar/> */}
      {/* <Bar/> */}
      {/* Pass the initialNodes and initialEdges as props */}
      <ShapeFlow initialNodes={initialNodes} initialEdges={initialEdges} />
      {/* <SVGButton/> */}

    </div>
  );
}

App.propTypes = {
  initialNodes: PropTypes.array,
  initialEdges: PropTypes.array,
};

export default App;
