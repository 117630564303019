import React from "react";
import { useEffect, useState } from "react";
//BrowserRouter
import Header from "./Header";
import SideBar from "./SideBar";
import PageContent from "../Application/PageContent";
import Footer from "./Footer";
import RightContent from "../Application/RightContent";
import ReactFlow from "../Application/ReactFlow";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TopBox from "../Application/TopBox";
import ManageBrisqueScreenPanel from "../Application/ScreenOperation/ManageBrisqueScreenPanel";
import ManageBrisqueScreen from "../Application/ScreenOperation/ManageBrisqueScreen";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AppsIcon from "@mui/icons-material/Apps";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

export default function Layouts() {
 
  const [query, setQuery] = useState();
  const [onQueryRC, setQueryRC] = useState(true);
  const [pathshow, setPathshow] = useState();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowIdNotes, setSelectedRowIdNotes] = useState(null)
  const [leftNavigationSelectedScreenName,setleftNavigationSelectedScreenName,] = useState(null);
  const [leftNavigationSelectedScreenUid, setleftNavigationSelectedScreenUid] =useState(null);
  const [sesssionData, setSesssionData] = React.useState({});
  
  const [open, setOpen] = React.useState(true);
  const [displayBreadCrumbs, setDisplayBreadCrumbs] = React.useState([]);
  useEffect(() => {
     let data  = window.sessionStorage.getItem('userDetails')
     let JsonData = JSON.parse(data.toString());
     setSesssionData(JsonData);
  }, []) ;

 

  const handleBreadCrumbsUpdate = (newBreadCrumbs) => {
    setDisplayBreadCrumbs(newBreadCrumbs)
    // displayBreadCrumbs(newBreadCrumbs); // Pass updated breadcrumbs to parent
  };

  const handleRowSelection = (rowId) => {
    setSelectedRowId(rowId);
    console.log("rowuidincontext detecting in layout is ", rowId);
  };
 
  const Container = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "Left",
    color: "black",
    fontWeight: "bold",
    marginTop:"50px"

    // color: theme.palette.text.secondary,
  }));

  const toggleDrawer = () => {
    setOpen(!open);
    
  };

  return (
    <div className="layout">
    <Box sx={{ p: 0}} gridColumn="span 9" >
        <Container fixed sx={{ height: "50px",width:"100%", position:"fixed" , elevation:"0",boxShadow: 0, zIndex:"1"}}>
           {/* Display Breadcrumbs */}
          <Typography
            variant="body2"
            gutterBottom
            sx={{ color: "red", flexGrow: "1", textAlign: "left", marginLeft: "248px" }}
          >
             {displayBreadCrumbs.join(">") || 'No data available yet'}
          </Typography>
          
          {open ? (
            <KeyboardDoubleArrowRightIcon
              sx={{
                paddingBottom: "20px",
                marginLeft: "1180px",
                width: "45px",
                height: "45px",
                marginTop: "-20px",
              }}
              edge="start"
              color="inherit"
              aria-label="menu"
              // onClick={(e) => {
              //   toggleDrawer(true);
              // }}
              onClick={toggleDrawer}
            ></KeyboardDoubleArrowRightIcon>
          ) : (
            <KeyboardDoubleArrowLeftIcon
              sx={{
                paddingBottom: "20px",
                marginLeft: "1180px",
                width: "45px",
                height: "45px",
                marginTop: "-20px",
              }}
              edge="start"
              color="inherit"
              aria-label="menu"
              // onClick={(e) => {
              //   toggleDrawer(true);
              // }}
              onClick={toggleDrawer}
            ></KeyboardDoubleArrowLeftIcon>
          )}
        </Container>
      </Box>
       

      <Header onQuery={setQuery} upadtePath={setPathshow}  leftNavigationSelectedScreenName={setleftNavigationSelectedScreenName}  leftNavigationSelectedScreenUid={setleftNavigationSelectedScreenUid}   breadCrumbsData={handleBreadCrumbsUpdate} sesssionData={sesssionData}/>
      <RightContent onQueryRC={open} selectedRowId={selectedRowId}  sesssionData={sesssionData}/>
      <TopBox onQueryRC={open} query={query} screename={leftNavigationSelectedScreenName} onRowSelect={handleRowSelection}  sesssionData={sesssionData}/> 
      
      <Routes>
      <Route index element={<PageContent query={query} onQueryRC={open} pathshow={pathshow} />}/>
        {/* <Route  exact path="managebrisquescreen" index element={ (leftNavigationSelectedScreenName === "managebrisquescreen") ? <ManageBrisqueScreen  onRowSelect={selectedRowId} onRowSelectNotes={selectedRowIdNotes}/>: "No Panel found"}/>
         <Route  path="managebrisquescreenpanel" index element={leftNavigationSelectedScreenName === "managebrisquescreenpanel" ? <ManageBrisqueScreenPanel  onRowSelect={selectedRowId} onRowSelectNotes={selectedRowIdNotes}/> : "No Panel found"}/>  */}
         
         <Route path="reactflow/*" index element={<ReactFlow onQueryRC={open} query={query} />}/> 
      </Routes>

      <Footer />
    </div>
  );
}
