// HorizontalBar.js
import React from 'react';
import { Paper, Button } from '@mui/material';

const HorizontalBar = () => {
  return (
    <Paper
      sx={{
        position: 'fixed',
        top: 50, // Change from 'bottom' to 'top'
        left: 0,
        width: '100%',
        height: '50px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 90px',
        color: 'white',
        borderRadius: 0,
      }}
    >
      <div>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{ borderColor: '#ff0000', color: '#ff0000' }}
        >
          Level 1
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{ marginLeft: '20px', borderColor: '#00ff00', color: '#00ff00' }}
        >
          Level 2
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{ marginLeft: '20px', borderColor: '#0000ff', color: '#0000ff' }}
        >
          Level 3
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{ marginLeft: '20px', borderColor: '#ff00ff', color: '#ff00ff' }}
        >
          Level 4
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{ marginLeft: '20px', borderColor: '#00FFF2', color: '#00FFF2' }}
        >
          Level 5
        </Button>
      </div>
    </Paper>
  );
};

export default HorizontalBar;
