import axios from "axios"
import * as q from "q"

export function getbrisquepanelgroupuxactionlisting(
	selectedUid,
	brisquePanelName
) {
	var defer = q.defer()
	var url = process.env.REACT_APP_BASEURL + "brisquepanelgroupuxactionlisting";
	// var url = "http://localhost:3100/brisquepanelgroupuxactionlisting"

	axios
		.post(url, {
			selectedUid: selectedUid,
			brisquePanelName: brisquePanelName,
		})
		.then(function (response) {
			defer.resolve(response)
		})
		.catch(function (error) {
			defer.reject(error)
		})

	return defer.promise
}
export function getbrisqueuxactionmaster(brisquePanelName) {
	var defer = q.defer()
	var url = process.env.REACT_APP_BASEURL + "brisqueuxactionmaster";
	// var url = "http://localhost:3100/brisqueuxactionmaster"

	axios
		.post(url, {
			brisquePanelName: brisquePanelName,
		})
		.then(function (response) {
			defer.resolve(response)
		})
		.catch(function (error) {
			defer.reject(error)
		})

	return defer.promise
}
export function getbrisquegroupmaster(brisquePanelName) {
	var defer = q.defer()
	// var url = "http://localhost:3100/brisquegroupmaster"
	var url = process.env.REACT_APP_BASEURL + "brisquegroupmaster";
	axios
		.post(url, {
			brisquePanelName: brisquePanelName,
		})
		.then(function (response) {
			defer.resolve(response)
		})
		.catch(function (error) {
			defer.reject(error)
		})

	return defer.promise
}
export function putbrisquegroupfeatureprivileges(editedData) {
	var defer = q.defer()
	var url = process.env.REACT_APP_BASEURL + "putbrisquegroupfeatureprivileges";
	// var url = "http://localhost:3100/putbrisquegroupfeatureprivileges"
	console.log("editedData:", editedData)
	axios
		.post(url, {
			editedDataBody: editedData,
		})
		.then(function (response) {
			// Check if the response contains a message
			if (response.data && response.data.message) {
				defer.resolve(response.data.message)
			} else {
				// If no message is found, reject with an error
				defer.reject(new Error("No message found in response"))
			}
		})
		.catch(function (error) {
			defer.reject(error)
		})
	return defer.promise
}
