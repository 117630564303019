import { TailSpin } from "react-loader-spinner";
import { ColorRing } from "react-loader-spinner";
const LoaderComp = () => {
    return (
        // <TailSpin
        //     height="80"
        //     width="80"
        //     color="#4fa94d"
        //     ariaLabel="tail-spin-loading"
        //     radius="1"
        //     wrapperStyle={{}}
        //     wrapperClass=""
        //     visible={true}
        // />
        <ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="color-ring-loading"
  wrapperStyle={{
    "align":"center"
  }}
  wrapperClass="color-ring-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
  />
    );
};  
export default LoaderComp;