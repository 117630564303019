import React from 'react';
import { Paper, Button } from '@mui/material';

const VerticalBar = ({ onAddStartNode,onAddHumanTask,onAddTriangle,onAddAutomation,onAddEnd }) => {
  return (
    <Paper
      sx={{
        position: 'absolute',
        top: '10%',
        left: 0,
        height: '65vh', // Adjust the height as a percentage of the viewport height
        width: '100px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        zIndex: 1,
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh', // Adjust the height as a percentage of the viewport height

          paddingLeft: '15px',
          paddingRight: '15px', // Add padding to the right to make space for the scrollbar
          color: 'white',
          borderRadius: 0,
          overflowY: 'auto',
        }}
        >
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            borderColor: '#ff0000',
            left: 5,
            color: '#ff0000',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            padding: 0,
          }}
          onClick={onAddStartNode}
        >
          Start
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            left: 5,
            marginTop: '20px',
            borderColor: '#00ff00',
            color: '#00ff00',
            borderRadius: '0',
          }}
          onClick={onAddHumanTask}  // Add this line for the "B" button
        >
          HuTask
        </Button>
      
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            marginTop: '20px',
            padding: 0, // Remove padding
          }}
          onClick={onAddTriangle}  // Add this line for the "B" button

        >
          <svg width="55" height="60" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
            <polygon points="40 0, 80 40, 40 80, 0 40" fill="white" stroke="blue" strokeWidth="2" />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="20" fill="blue">
              T/F
            </text>
          </svg>
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            left: 5,
            marginTop: '20px',
            borderColor: '#ff00ff',
            color: '#ff00ff',
            
            borderRadius: '0', // Keep it as a rectangle
          }}
          onClick={onAddAutomation}  // Add this line for the "B" button

        >
        AUTO
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            left: 5,
            marginTop: '20px',
            borderColor: '#00FFF2',
            color: '#00FFF2',
            borderRadius: '50%', // Make button E circular
            width: '40px', // Set width to make it circular
            height: '40px', // Set height to make it circular
            padding: 0, // Remove padding
          }}
          onClick={onAddEnd}  // Add this line for the "B" button

        >
          END
        </Button>

        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            left: 5,
            marginTop: '20px',
            borderColor: '#ff00ff',
            color: '#ff00ff',
            borderRadius: '0', // Keep it as a rectangle
          }}
        >
          F
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            left: 5,
            marginTop: '20px',
            borderColor: '#ff00ff',
            color: '#ff00ff',
            borderRadius: '0', // Keep it as a rectangle
          }}
        >
          G
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          style={{
            left: 5,
            marginTop: '20px',
            borderColor: '#ff00ff',
            color: '#ff00ff',
            borderRadius: '0', // Keep it as a rectangle
          }}
        >
          H
        </Button>
      </div>
    </Paper>
  );
};

export default VerticalBar;
