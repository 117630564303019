// import AppRoutes from "../routes";
//import {Typography} from "antd";
import Typography from "@mui/material/Typography";
import "../style.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import ManageBrisqueScreenPanel from "../ScreenOperation/ManageBrisqueScreenPanel";
import ManageBrisqueScreen from "../ScreenOperation/ManageBrisqueScreen";
import ManageUseronBoarding from "../ScreenOperation/ManageUseronBoarding";
import EnterpriseOnboarding from "../ScreenOperation/EnterpriseOnboarding"

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function TopBox({
  onQueryRC,
  query,
  pathshow,
  screename,
  onRowSelect,
  sesssionData
}) {
  // const onQueryRC = useContext(rightContext);

  console.log("screename" + screename);
  const [showStatus, setShowStatus] = useState(true);
  const [selectedBrisqueFeatureUid, setSelectedBrisqueFeatureUid] = useState()

  let PageLeftContentX = !query ? "PageContent" : "PageContentDynamic";
  let PageRightContentX = onQueryRC
    ? "Usermanagement"
    : "UsermanagementDynamic";

  // pathshow = ["abc", "cds", "fjjjf"]
  // const breadcrumbs = [

  //   pathshow.map((item) =>
  //   <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
  //     {item}
  //   </Link>,
  // )];

  const Container = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "Left",
    color: "black",
    fontWeight: "bold",

  }));



 

  return (
    <div className={`${PageLeftContentX} anotherClass ${PageRightContentX}`}>
    <Routes>
    {/* <Route  exact path="managebrisquescreen" index element={screename === "managebrisquescreen" ? <ManageBrisqueScreen  onRowSelect={onRowSelect} onRowSelectNotes={onRowSelectNotes}/> : "No Panel found"}/>
        <Route  path="managebrisquescreenpanel" index element={screename === "managebrisquescreenpanel" ? <ManageBrisqueScreenPanel  onRowSelect={onRowSelect} onRowSelectNotes={onRowSelectNotes}/> : "No Panel found"}/> */}
        <Route  exact path="managebrisquescreen/*" index element={ <ManageBrisqueScreen  onRowSelect={onRowSelect} sesssionData={sesssionData}/>} />
        <Route  exact path="managebrisquescreenpanel/*" index element={ <ManageBrisqueScreenPanel  onRowSelect={onRowSelect} sesssionData={sesssionData}/>} />
        <Route  exact path="manageuseronboarding/*" index element={ <ManageUseronBoarding  onRowSelect={onRowSelect} sesssionData={sesssionData}/>} />
        <Route  exact path="manageorganization/*" index element={ <EnterpriseOnboarding  onRowSelect={onRowSelect} sesssionData={sesssionData}/>} />
      </Routes>
    
    </div>
  );
}

