import React, { useState } from "react";
import logo from "../../images/logo.png";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Button, Typography, TextField } from "@mui/material";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { authenticateStdUser } from "../action/user";
import * as CryptoJS from "crypto-js";


export default function ForgotUserScreen() {
const navigate = useNavigate();
 
const [email,setEmail] = useState("")
const [loginStatus, setLoginStatus] = useState(" ");
   
const handleLink = () => {
  navigate('/');
 }


 const AccessLink = () => {
  var useridEncrypt = CryptoJS.AES.encrypt(JSON.stringify(email), "my-secret-key@rcube");
  const useriddata = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(useridEncrypt));
  const dboperation = "FORGOTUSER";
  const origpassword = undefined;
  const passdata = undefined;

  authenticateStdUser(useriddata, passdata, dboperation, origpassword)
          .then((response) => {
            console.log(response)
            setLoginStatus(response.data);
          })
 }


  return (
    <div>
      <div className="loginContainer">
        <img src={logo} style={{ height: "45px", width: "45px" }} />
      </div>
      <Card
        sx={{
          variant: "outlined",
          width: 250,
          padding: 5,
          alignItems: "center",
          marginLeft: "450px",
          marginTop: "80px",
          marginBottom: "-25px",
        }}
      >
        <Typography
          variant="overline"
          textAlign="center"
          display="block"
          gutterBottom
        >
         Forgot Username
         
        </Typography>
        {/* <form noValidate autoComplete="off"> */}
        <TextField
          id="login-email"
          label="Email"
          margin="dense"
          type="email"
          fullWidth={true}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onkeypress={(event) => {
            if (event.key === "Enter") {
              this.login();
            }
          }}
        />
        &nbsp; &nbsp;
        <div style={{ textAlign: "center" }}>
          <Button
            sx={{ textTransform: "none" }}
            variant="contained"
            color="primary"

            onClick={AccessLink}
          >
           Access Link
          </Button>
        </div>
        &nbsp;
        <div style={{ textAlign: "center" }}>
          <Link
            sx={{ fontSize: "14px" }}
            href="#"
            color="inherit"
            underline="always"
            onClick={handleLink}
          >
            Back to Login
          </Link>
        </div>
        &nbsp;
        <Typography variant="caption" display="block" color="red" gutterBottom>
          {/* <ReportProblemIcon sx={{ color: "red" }}/> */}
          {loginStatus}
        </Typography>
      </Card>
    </div>
  );
}
