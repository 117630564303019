// NoteForm.jsx

import React, { useState } from "react";

const NoteForm = ({ handleSubmit, submitLabel, handleCancel, initialText = "" }) => {
  const [text, setText] = useState(initialText);

  const onSubmit = (event) => {
    event.preventDefault();

    handleSubmit(text);
    setText(""); // Clear the text area
  };

  return (
    <form onSubmit={onSubmit}>
      <textarea
        className="note-form-textarea"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Write your note here..."
      />
      <br />
      <button
        className="note-form-button"
        disabled={text.length === 0}
      >
        {submitLabel}
      </button>
      <button
        type="button"
        className="note-form-button"
        onClick={() => {
          handleCancel();
          setText(""); // Clear the text area
        }}
      >
        Cancel
      </button>
    </form>
  );
};

export default NoteForm;
