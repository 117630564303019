import React, { useState } from "react"
import {
	Paper,
	Table,
	TableHead,
	TableBody,
	TableCell,
	Checkbox,
	TableContainer,
	TableRow,
	styled,
	Button,
	TextField,
} from "@mui/material"

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "#E8E8E8",
	},
	"&:nth-of-type(even)": {
		backgroundColor: "#F5F5F5",
	},
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}))

const ScreenPanel = ({
	handleAddRow, //function to add row
	setUpdateEditData, // state to update edited data
	bodyDataEdit, //initial body data
	setbodyDataEdit, //to make changes in body data
	editData, //initial edit data for cancel
	seteditData, //
	//
	headerData,
}) => {
	const [selectedRows, setSelectedRows] = useState([])

	const user = "Bhagesh2"

	const handleCheckboxChange = (rowIndex) => {
		setSelectedRows((prevSelectedRows) => {
			const newSelectedRows = [...prevSelectedRows]
			const indexToRemove = newSelectedRows.indexOf(rowIndex)

			if (indexToRemove !== -1) {
				newSelectedRows.splice(indexToRemove, 1)
			} else {
				newSelectedRows.push(rowIndex)
			}

			return newSelectedRows
		})
	}

	const handleDeleteSelectedRows = () => {
		const updatedData = bodyDataEdit.filter(
			(_, index) => !selectedRows.includes(index)
		)
		setbodyDataEdit(updatedData)
		seteditData(updatedData)
		setSelectedRows([])
	}

	const handleCancelEdit = () => {
		setbodyDataEdit(editData)
	}

	const formatDate = (date) => {
		return date.toISOString().slice(0, 19).replace("T", " ")
	}

	const modifyObject = (index, columnName, value) => {
		const updatedData = bodyDataEdit.map((obj, i) => {
			if (i === index) {
				// Check if the column exists in the object
				if (obj.hasOwnProperty(columnName)) {
					// Modify the value of the specified column
					const modifiedObj = {
						...obj,
						[columnName]: value,
					}

					// Modify the value of dboperation based on conditions
					if (!modifiedObj.dboperation) {
						modifiedObj.dboperation = "INSERT"
						if (modifiedObj.dboperation === "INSERT") {
							if (modifiedObj.hasOwnProperty("createdby")) {
								modifiedObj.createdby = user // Replace "currentUser" with the actual user information
							}
							if (modifiedObj.hasOwnProperty("createddate")) {
								modifiedObj.createddate = formatDate(new Date()) // Use the provided formatDate function
							}
						}
					} else if (modifiedObj.dboperation === "RETRIEVE") {
						modifiedObj.dboperation = "UPDATE"
						// Update updatedBy and updatedDate for UPDATE operation
						if (modifiedObj.dboperation === "UPDATE") {
							if (modifiedObj.hasOwnProperty("updatedby")) {
								modifiedObj.updatedby = user // Replace "currentUser" with the actual user information
							}
							if (modifiedObj.hasOwnProperty("updateddate")) {
								modifiedObj.updateddate = formatDate(new Date()) // Use the provided formatDate function
								modifiedObj.createddate =
									modifiedObj.createddate.replace("T", " ")
							}
						}
					}

					return modifiedObj
				}
			}
			return obj
		})
		setbodyDataEdit(updatedData) // Set the updated data in state
	}

	const handleSaveChanges = () => {
		setUpdateEditData(bodyDataEdit)
		setbodyDataEdit([])
		seteditData([])
	}

	return (
		<div>
			{bodyDataEdit && bodyDataEdit.length > 0 && (
				<div style={{ overflowY: "auto" }}>
					<Paper sx={{ width: "100%", overflow: "auto" }}>
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<StyledTableRow>
										<TableCell>{"Select"}</TableCell>
										{headerData.map((column) =>
											column.columndisplayname !==
											"NODISPLAY" ? (
												<TableCell
													key={column.columnname}>
													{column.columndisplayname}
												</TableCell>
											) : null
										)}
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{bodyDataEdit.map((rowData, rowIndex) => (
										<StyledTableRow key={`row-${rowIndex}`}>
											<TableCell>
												<Checkbox
													checked={selectedRows.includes(
														rowIndex
													)}
													onChange={() =>
														handleCheckboxChange(
															rowIndex
														)
													}
												/>
											</TableCell>
											{headerData.map(
												(column, colIndex) => {
													if (
														column.columndisplayname !==
														"NODISPLAY"
													) {
														const cellKey = `cell-${rowIndex}-${colIndex}`
														const cellValue =
															rowData.hasOwnProperty(
																column.columndisplayshortname
															)
																? rowData[
																		column
																			.columndisplayshortname
																  ]
																: ""
														return (
															<TableCell
																key={cellKey}>
																<TextField
																	value={
																		cellValue
																	}
																	sx={{
																		backgroundColor:
																			"#ffffff",
																	}}
																	onChange={(
																		e
																	) => {
																		// Here, update the object's value with the corresponding column
																		modifyObject(
																			rowIndex,
																			column.columndisplayshortname,
																			e
																				.target
																				.value
																		)
																	}}
																/>
															</TableCell>
														)
													} else {
														return null
													}
												}
											)}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>

					<Button
						variant="contained"
						sx={{ margin: "15px", marginTop: "10" }}
						color="primary"
						onClick={handleSaveChanges}>
						OK
					</Button>
					<Button
						variant="contained"
						sx={{ margin: "15px", marginTop: "10" }}
						color="primary"
						onClick={handleAddRow}>
						Add
					</Button>
					<Button
						variant="contained"
						sx={{ margin: "15px", marginTop: "10" }}
						color="primary"
						onClick={handleCancelEdit}>
						Cancel
					</Button>
					{selectedRows.length > 0 && (
						<Button
							variant="contained"
							sx={{ margin: "15px", marginTop: "10" }}
							color="secondary"
							onClick={handleDeleteSelectedRows}>
							Delete Selected
						</Button>
					)}
				</div>
			)}
		</div>
	)
}
export default ScreenPanel
