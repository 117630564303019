
import axios from "axios";
import * as q from "q";
 
export function getOrgLocation(enterpriseUid) {
  var defer = q.defer();
  var url = process.env.REACT_APP_BASEURL + "getOrgLocationListing";
  // var url = "http://localhost:3100/getOrgLocationListing";
  console.log("Org Location Listing " + url);
  axios
    .post(url , {
      enterpriseUid: enterpriseUid
   
    })
    .then(function (response) {
      defer.resolve(response);
    })
    .catch(function (error) {
      defer.reject(error);
    });
  return defer.promise;
}
 
 
export const createOrgLocation = async (
  initialpayload
) => {
  try {
    console.log("initialpayload for Org Location Listing:", initialpayload.enterpriseUid);
      const endpoint = "http://localhost:3100/putOrgLocationListing";
      const tableName = "brisqueorgregiondepartment";
 
      const currentDateTime = new Date().toISOString();
      const formattedDateTime = currentDateTime.replace("T", " ").split(".")[0];
      const updatedPayload = {
        ...initialpayload,
        createdby: "Faize",
        createddate: formattedDateTime,
      };
 
      const payload = [
        updatedPayload[0]
      ];
 
      console.log("Payload for Org Location Listing:", payload);
 
      const response = await axios.post(endpoint, { tableName, payload });
 
      const result = response.data;
 
      if (result.error_code > 0) {
          console.log("Organization location listing posted successfully!");
          console.log("API Response:", result);
          return "Successfully Posted";
      } else {
          console.error(`Error posting organization location listing. Error code: ${result.error_code}`);
          return "Failed";
      }
  } catch (error) {
      console.error("Error creating organization location listing:", error);
      return "Failed";
  }
};