
// NoteAction.js
 
import axios from "axios";
import * as q from "q";

export function getNotes(rowuidincontext, noteType) {
  var defer = q.defer();
  var url = process.env.REACT_APP_BASEURL + "getPanelRecordNotes";
  // var url = "http://localhost:3100/getPanelRecordNotes"; // Assuming this endpoint handles both types of notes
 
  let payload = {};
  console.log("rowuidincontext inside NA",rowuidincontext);
  console.log("noteType inside NA",noteType);
  if (rowuidincontext === null) {
    payload = {
      noteType: noteType // Add a parameter to indicate the type of note
    };
  } else {
    payload = {
      rowuidincontext: rowuidincontext,
      noteType: noteType // Add a parameter to indicate the type of note
    };
  }
 
  console.log("getNote payload from NoteAction ", payload);
  axios
    .post(url, payload)
    .then(function (response) {
      defer.resolve(response);
    })
    .catch(function (error) {
      defer.reject(error);
    });
  return defer.promise;
}
 
 
export const createNote = async (text, selectedRowId, userId, userName) => {
  try {
    
    const endpoint = process.env.REACT_APP_BASEURL + "putPanelRecordNotes";
    const tableName = "brisqueusernotes";
 
    const currentDateTime = new Date().toISOString();
    const formattedDateTime = currentDateTime.replace("T", " ").split(".")[0];
 
    const payload = [
      {
        recno: 1,
        dboperation: "INSERT",
        usernoteuid: 0,
        priorusernoteuid: 0,
        enterpriseuid: 1,
        brisqueuseruid: userId,
        noteapiname: "putpanelrecordnotes",
        usernotetype: "PANELRECORD",
        usernotes: text,
        brisquepaneluid: 4, // PanelUID obtained from the detailPanel
        tablename: "brisqueusernotes",
        rowuidincontext: selectedRowId,
        relatedusergroup: " ",
        shareflag: "Y",
        activeflag: "Y",
        createdby: userName,
        updatedby: "",
        createddate: formattedDateTime,
        updateddate: null,
      },
    ];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Note posted successfully!");
      console.log("API Response:", result);
      return result.createdNote;
    } else {
      console.error(`Error posting note. Error code: ${result.error_code}`);
      return null;
    }
  } catch (error) {
    console.error("Error creating note:", error);
    return null;
  }
};
 
export const updateNote = async (text, usernoteuid, selectedRowId, userName) => {
  try {
    const endpoint = process.env.REACT_APP_BASEURL + "putPanelRecordNotes";
    const tableName = "brisqueusernotes";
 
    // Fetch the existing note details
    const existingNoteResponse = await axios.post(
      "http://localhost:3100/getPanelRecordNotes",
      {
        rowuidincontext: selectedRowId,
        usernoteuid: usernoteuid,
      }
    );
 
    // Assuming the response data is stored in the variable named 'response'
    const existingNoteArray = existingNoteResponse.data;
 
    const usernoteuidToFind = usernoteuid;
 
    const existingNote = existingNoteArray.find(
      (obj) => obj.usernoteuid === usernoteuidToFind
    );
 
    // Now 'existingNote' contains the last object in the array
    console.log("Existing Note:", existingNote);
 
    // Modify this line to format both created and updated dates
    const formattedCreatedDateTime = existingNote.createddate
      .replace("T", " ")
      .replace(/\.\d+Z$/, "")
      .replace(" ", " ");
    const formattedUpdatedDateTime = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
 
    // Create a copy of the existing note with updated text and other necessary changes
    const updatedNote = {
      ...existingNote,
      dboperation: "UPDATE",
      usernotes: text,
      updatedby: userName,
      updateddate: formattedUpdatedDateTime,
      createddate: formattedCreatedDateTime,
    };
 
    const payload = [updatedNote];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Note updated successfully!");
      console.log("API Response:", result);
      return result.updatedNote || updatedNote; // Use the API response, fallback to the local update if not provided
    } else {
      console.error(`Error updating note. Error code: ${result.error_code}`);
      return null;
    }
  }
  catch (error) {
    console.error("Error updating note:", error);
    return null;
  }
};
 
export const deleteNote = async (usernoteuid, selectedRowId, userName) => {
  try {
    const endpoint = process.env.REACT_APP_BASEURL + "putPanelRecordNotes";
    const tableName = "brisqueusernotes";
 
    // Fetch the existing note details
    const existingNoteResponse = await axios.post(
      "http://localhost:3100/getPanelRecordNotes",
      {
        rowuidincontext: selectedRowId,
        usernoteuid: usernoteuid,
      }
    );
 
    // Assuming the response data is stored in the variable named 'response'
    const existingNoteArray = existingNoteResponse.data;
 
    const usernoteuidToFind = usernoteuid;
 
    const existingNote = existingNoteArray.find(
      (obj) => obj.usernoteuid === usernoteuidToFind
    );
 
    // Now 'existingNote' contains the last object in the array
    console.log("Existing Note:", existingNote);
 
    // Modify this line to format both created and updated dates
    const formattedCreatedDateTime = existingNote.createddate
      .replace("T", " ")
      .replace(/\.\d+Z$/, "")
      .replace(" ", " ");
    const formattedUpdatedDateTime = new Date()
      .toISOString()
      .replace("T", " ")
      .split(".")[0];
 
    // Create a copy of the existing note with necessary changes for DELETE operation
    const deletedNote = {
      ...existingNote,
      dboperation: "DELETE",
      updatedby: userName,
      updateddate: formattedUpdatedDateTime,
      createddate: formattedCreatedDateTime,
    };
 
    const payload = [deletedNote];
 
    const response = await axios.post(endpoint, { tableName, payload });
 
    const result = response.data;
 
    if (result.error_code === "SUCCESS") {
      console.log("Note deleted successfully!");
      console.log("API Response:", result);
      return result.deletedNote;
    } else {
      console.error(`Error deleting note. Error code: ${result.error_code}`);
      return null;
    }
  } catch (error) {
    console.error("Error deleting note:", error);
    return null;
  }
};
 
 
 