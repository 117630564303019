import React, { useState } from 'react';
import { Paper, Button, Drawer } from '@mui/material';

const TransparentBar = () => {
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Paper
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '50px',
          backgroundColor: '#808080', // Change to grey color (adjust the color code as needed)
          zIndex: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 90px',
          color: 'white',
          borderRadius: 0, // Remove border radius to make corners sharp
        }}
      >
        <div>
          <Button onClick={toggleDrawer} style={{ fontSize: '30px', marginRight: 'auto', right: '80px' }}>
            {open ? '-' : '+'}
          </Button>
          <span style={{ fontSize: '20px', marginLeft: '-50px' }}>Brisque Canvas</span>
          {/* Rest of your buttons */}
        </div>
      </Paper>
    </>
  );
};

export default TransparentBar;
