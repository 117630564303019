import React from "react";
import "../../style.css";
import Box from "@mui/material/Box";
import { useState } from "react";
import SearchPanel from "./SearchPanel";
import DetailsPanel from "./DetailsPanel";
import EditPanel from "./EditPanel";

export default function ManageBrisqueScreen({ onRowSelectNotes, onRowSelect,sesssionData }) {
  const [isSubPro11Open, setIsSubPro11Open] = useState(false);
  const [selectedUid, setSelectedUid] = useState([]); // State to hold selectedBrisqueFeatureUid
  const [UpdatedEditData, setUpdateEditData] = useState([]);
  const [bodyDataEdit, setbodyDataEdit] = useState([]);
  const [editData, seteditData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [bodyData, setBodyData] = useState([]);

  const handleAddRow = () => {
    const emptyRow = {};
    const keys = Object.keys(bodyData[0]);

    keys.forEach((key) => {
      // Assuming you want to initialize with null for numeric values
      if (typeof bodyData[0][key] === "number") {
        emptyRow[key] = null;
      } else {
        emptyRow[key] = ""; // Default for non-numeric values
      }
    });

    setbodyDataEdit((prevbodyDataEdit) => {
      return [...prevbodyDataEdit, emptyRow];
    });
  };
  return (
    <div>
      <Box sx={{ p: 1, paddingTop: "70px" }} gridColumn="span 9">
      {/* from edit to topbox  */}
        <SearchPanel
          setSelectedUid={setSelectedUid}   />
      </Box>

      <Box sx={{ p: 1 }} gridColumn="span 9">
        <DetailsPanel
        sesssionData={sesssionData}
          isContentVisible={isSubPro11Open}
          onToggleDetailsPanel={() => setIsSubPro11Open(!isSubPro11Open)}
          selectedUid={selectedUid} ///from topbox to details
          handleAddRow={handleAddRow} //add row to edit panel
          setHeaderData={setHeaderData} // to set header for edit panel
          headerData={headerData} //header for  panel
          setBodyData={setBodyData} //to set body data from api
          bodyData={bodyData} //body data from api
          seteditData={seteditData} //to set selected data
          setbodyDataEdit={setbodyDataEdit} //to set the changes in body data of edit panel
          UpdatedEditData={UpdatedEditData} //updated data from edit to top to detail
          setUpdateEditData={setUpdateEditData}
          //faize
          onRowSelect={onRowSelect}
          onRowSelectNotes={onRowSelectNotes}
          //
        />
      </Box>

      <Box sx={{ p: 1 }} gridColumn="span 9">
        <EditPanel
          isContentVisible={true}
          setUpdateEditData={setUpdateEditData} // to set the body data
          handleAddRow={handleAddRow} //add row to edit panel
          bodyDataEdit={bodyDataEdit} //selected data from detail to top to edit panel
          setbodyDataEdit={setbodyDataEdit} //to set the changes in body data of edit panel
          editData={editData} //original copy of selected data from detail to top to edit panel
          seteditData={seteditData} // to set original data on cancel
          headerData={headerData} //header for edit panel
        />
      </Box>
    </div>
  );
}
