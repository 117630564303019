import { Box, Container } from "@mui/material";
import { Link } from "@mui/material";
import { Typography } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <div>
      <Box
        sx={{
          zIndex: "1251",
          position: "fixed",
          height: "20px",
          bottom: 0,
          width: "100%",
          bgcolor: "#c44198", // Add a background color
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
        }}
      >
        {/* <hr
          style={{
            border: 0,
            height: 1,
            background: "#e0e0e0", // Change the color of the hr element
            margin: 0,
          }}
        /> */}
        <Container
          maxWidth="xl"
          height="30px"
          // sx={{
          //   px: 4,
          //   py: 2,
          //   // overflow: "hidden",
          //   "& .MuiTypography-root": {
          //     color: "white",
          //     "&:hover": { color: "white" },
          //   },
          // }}
        >
          

           <Box sx={{display:"flex",alignItems:"center",  gap: "4rem",color:"white" }}>
        <Typography variant="body2"  noWrap>
        Privacy Policy
        </Typography>
        <Typography variant="body2" noWrap>
         © 2023 brique, Inc.
        </Typography>
      </Box> 
        </Container>
      </Box>
    </div>
  );
}

export default Footer;
